import Axios from "axios";
import React, { useState, useEffect } from "react";

import ConfigPromotedRaffles from "./includes/ConfigPromotedRaffles";
import ConfigHowItWorks from "./includes/ConfigHowItWorks";
//import ConfigTestimonials from "./includes/ConfigTestimonials";
import ConfigWinners from "./includes/ConfigWinners";
import LoadingSpinner from "../../includes/LoadingSpinner";
import ConfigFaq from "./includes/ConfigFaq";
import ConfigJustText from "./includes/ConfigJustText";
import ConfigPromoPopup from "./includes/ConfigPromoPopup";
import ConfigDate from "./includes/ConfigDate";
import ConfigReferrals from "./includes/ConfigReferrals";
import ConfigStatistics from "./includes/ConfigStatistics";
import ConfigLanding from "./includes/ConfigLanding";

const ConfigPage = () => {
  const [loading, setLoading] = useState(true);

  const [nextDrawDate, setNextDrawDate] = useState(false);
  const [landing, setLanding] = useState(false);
  const [statistics, setStatistics] = useState(false);
  const [howItWorks, setHowItWorks] = useState(false);
  //const [testimonials, setTestimonials] = useState(false);
  const [faq, setFaq] = useState(false);
  const [referrals, setReferrals] = useState(false);
  const [support, setSupport] = useState(false);
  const [globalPromoPopup, setGlobalPromoPopup] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/config`).then(({ data }) => {
      setNextDrawDate(data.config.find(({ type }) => type === "next_draw"));
      setLanding(data.config.find(({ type }) => type === "landing"));
      setStatistics(data.config.find(({ type }) => type === "statistics"));
      setHowItWorks(data.config.find(({ type }) => type === "how_it_works"));
      //setTestimonials(data.config.find(({ type }) => type === "testimonials"));
      setFaq(data.config.find(({ type }) => type === "faq"));
      setReferrals(data.config.find(({ type }) => type === "referrals"));
      setSupport(data.config.find(({ type }) => type === "support"));
      setGlobalPromoPopup(data.config.find(({ type }) => type === "global_promotional_popup"));
      setLoading(false);
    });
  }, []);

  return (
    <div className="row">
      <div className="col">
        <h1 className="admin-title">Site Configuration</h1>

        {loading ? (
          <LoadingSpinner dark={true} className="center" />
        ) : (
          <div>
            <ConfigPromotedRaffles />

            <ConfigLanding data={landing} />

            <ConfigStatistics data={statistics} />

            <ConfigDate title="Next Draw Date" data={nextDrawDate} />

            <ConfigHowItWorks howItWorks={howItWorks} />

            {/*<ConfigTestimonials testimonials={testimonials} />*/}

            <ConfigWinners />

            <ConfigFaq faq={faq} />
            
            <ConfigReferrals data={referrals} />

            <ConfigJustText title="Support" data={support} />

            <ConfigPromoPopup data={globalPromoPopup} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfigPage;
