import React, { useState } from "react";
import { Plus, X } from "react-feather";
import { useEffect } from "react";
import Axios from "axios";

const RaffleModifyCharitySearch = ({ charity, charityPercentage, update }) => {
  const [showAddCharityPopover, setShowAddCharityPopover] = useState(false);
  const [allCharities, setAllCharities] = useState([]);
  const [allCharitiesFiltered, setAllCharitiesFiltered] = useState([]);
  const [filterCharitiesQuery, setFilterCharitiesQuery] = useState("");
  const [selectedCharity, setSelectedCharity] = useState(charity);

  const [currentCharityPercentage, setCurrentCharityPercentage] = useState(charityPercentage);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/charities`).then(({ data }) => {
      setAllCharities(data.charities);
    });
  }, []);

  // Filter categories for search popover
  useEffect(() => {
    setAllCharitiesFiltered(
      allCharities.filter(
        ({ name }) =>
          name.toLowerCase().includes(filterCharitiesQuery.toLowerCase())
      )
    );
  }, [allCharities, charity, filterCharitiesQuery]);

  const removeCharity = () => {
    update("charity", {});
    setSelectedCharity({});
  };

  const setCharity = (charity) => {
    update("charity", charity);
    
    setSelectedCharity(charity);
    setShowAddCharityPopover(false);
  };

  return (
    <div className="row no-padding gutter">
      <div className="col-6">
        <div className="admin-slate">
          <h2>Charity</h2>
          <div className="tags">
            {selectedCharity && selectedCharity.name ? (
              <div key={selectedCharity.name} className="tag">
                {selectedCharity.name}{" "}
                <button onClick={removeCharity}>
                  <X />
                </button>
              </div>
            ) : (
              <div className="new-tag-container">
                <div className="tag new" onClick={() => setShowAddCharityPopover(!showAddCharityPopover)}>
                  <Plus />
                </div>

                {showAddCharityPopover && (
                  <div className="new-tag-search">
                    <input
                      type="search"
                      className="admin-input"
                      placeholder="Search for charities"
                      autoFocus={true}
                      value={filterCharitiesQuery}
                      onChange={(e) => setFilterCharitiesQuery(e.target.value)}
                    />
                    <div className="category-results">
                      {allCharitiesFiltered.length ? (
                        allCharitiesFiltered.map((charity) => (
                          <div key={charity.name} onClick={() => setCharity(charity)}>
                            {charity.name}
                          </div>
                        ))
                      ) : (
                        <div className="no-cats">No charities to show</div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="admin-slate">
          <h2>Charity Percentage</h2>
          <input
            type="number"
            className="admin-input"
            value={currentCharityPercentage}
            onChange={(e) => {
              if (e.target.value > 100)
                e.target.value = 100;
              else if (e.target.value < 0)
                e.target.value = 0;

              setCurrentCharityPercentage(e.target.value);
              update("charityPercentage", e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RaffleModifyCharitySearch;
