import Axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../includes/LoadingSpinner";

const DrawsPage = () => {
  const [limit] = useState(5);
  const [load] = useState(2);

  const [livestreams, setLivestreams] = useState(false);

  const [currentDraw, setCurrentDraw] = useState(false);
  const [currentDrawLink, setCurrentDrawLink] = useState("/draws");

  const [live, setLive] = useState(false);
  const [liveDraws, setLiveDraws] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const [totalDraws, setTotalDraws] = useState(0);

  const onRefChange = useCallback(node => setLivestreams(node), []);

  useEffect(() => {
    const fetchDrawLink = (url) => {
      const urlParts = url.split("?");

      if (urlParts.length > 1) {
        const split = urlParts[1].split("&");

        for (let i = 0; i < urlParts.length; i++) {
          if (split[i].toLowerCase().startsWith("href=")) {
            return decodeURIComponent(split[i].substring(5));
          }
        }
      }

      return url;
    };

    Axios.get(`${process.env.REACT_APP_API}/api/liveDraws/limit/${limit}/skip/0`).then(({ data }) => {
      const liveDraws = data.liveDraws;

      if (liveDraws && liveDraws.length > 0) {
        setLiveDraws(liveDraws);
        setTotalDraws(data.total);

        Axios.get(`${process.env.REACT_APP_API}/api/liveDraws/live`).then(({ data }) => {
          setCurrentDraw(data.liveDraw);
          setCurrentDrawLink(fetchDrawLink(data.liveDraw.url));
          setLive(true);

          setLoading(false);
        }).catch(() => {
          setCurrentDraw(liveDraws[0]);
          setCurrentDrawLink(fetchDrawLink(liveDraws[0].url));

          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  }, [limit]);

  useEffect(() => {
    if (!loadingMore)
      return;

    Axios.get(`${process.env.REACT_APP_API}/api/liveDraws/limit/${load}/skip/${liveDraws.length}`).then(({ data }) => {
      setLoadingMore(false);
      
      if (data.liveDraws && data.liveDraws.length > 0) {
        setLiveDraws([...liveDraws, ...data.liveDraws]);
      }
    }).catch(() => setLoadingMore(false));
  }, [liveDraws, load, loadingMore]);

  useEffect(() => {
    if (loadingMore || !livestreams)
      return;

    const onScroll = () => {
      const maxScroll = livestreams.scrollWidth - livestreams.clientWidth;

      if (livestreams.scrollLeft + 100 >= maxScroll && liveDraws.length < totalDraws) {
        setLoadingMore(true);
      }
    };

    livestreams.addEventListener("scroll", onScroll);
    return () => livestreams.removeEventListener("scroll", onScroll);
  }, [liveDraws, livestreams, loadingMore, totalDraws]);

  return (
    <>
      <Helmet>
        <title>Pryze | Live Draws</title>
      </Helmet>

      <div className="cta-slab curve">
        <div className="row">
          <div className="col">
            <h1 className="title">Live Draws</h1>

            {live && (
              <div className="subtitle bottom">
                <button className="btn btn-blue live">
                  <div className="live-dot"></div>
                  <div>
                    <strong>LIVE NOW!</strong>
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="row no-padding">
          <div className="col">
            {loading ? (
              <LoadingSpinner dark={true} className="center" />
            ) : (
              currentDraw ? (
                <>
                  <iframe
                    title="livestream"
                    className="live-draw-stream"
                    src={currentDraw.url}
                    frameBorder="0"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen={true}
                    scrolling="no"
                    style={{ border: "none", overflow: "hidden" }}
                  />

                  <a target="_blank" rel="noreferrer" href={currentDrawLink} className="btn btn-blue live" style={{ margin: "20px auto" }}>
                    Watch on Facebook
                  </a>
                </>
              ) : (
                <h2 className="no-winners">No live draws have been added to this section yet!</h2>
              )
            )}
          </div>
        </div>
      </div>

      {liveDraws && liveDraws.length > 1 && (
        <div className="cta-slab white">
          <div className="row">
            <div className="col">
              <h1 className="title">Previous Draws</h1>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="previous-draws">
                <div className="livestreams" ref={onRefChange}>
                  {liveDraws.map((liveDraw, i) => {
                    if (i === 0)
                      return null;

                    return (
                      <iframe
                        key={i}
                        title="livestream"
                        className="live-draw-stream"
                        src={liveDraw.url}
                        frameBorder="0"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        allowFullScreen={true}
                        scrolling="no"
                        style={{ border: "none", overflow: "hidden" }}
                      />
                    );
                  })}
                </div>
              </div>

              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/PryzeUK/videos" className="btn btn-blue live" style={{ margin: "20px auto" }}>
                Watch Previous Draws on Facebook
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  )
};

export default DrawsPage;