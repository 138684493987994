import React, { useCallback, useContext, useEffect, useState } from "react";
import Axios from "axios";
import { X } from "react-feather";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../../includes/LoadingSpinner";
import { Helmet } from "react-helmet";
import { AppContext } from "../../../context/AppContext";

const CartCheckoutSummary = ({ subTotal = 0 }) => {
  const history = useHistory();
  const { user } = useContext(AppContext);

  const [applying, setApplying] = useState(false);
  const [userPromotionalBalance, setUserPromotionalBalance] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeError, setCouponCodeError] = useState(false);
  const [appliedCouponCode, setAppliedCouponCode] = useState(false);
  const [usePromotionalBal, setUsePromotionalBal] = useState(
    localStorage.getItem("usePromoBal") === "true"
  );
  const [newsletter, setNewsletter] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/auth/getPromotionalBalance`).then(({ data }) => {
      setUserPromotionalBalance(data.promotionalBalance);
    });
  }, []);

  const applyCoupon = useCallback(
    async (code = false) => {
      setApplying(true);
      setCouponCodeError(false);
      setAppliedCouponCode(false);

      let couponCodeToCheck = code || couponCode;

      // make request for coupon code here!
      try {
        const { data: couponCodeData } = await Axios.post(
          `${process.env.REACT_APP_API}/api/couponCodes/checkCouponCode`,
          {
            code: couponCodeToCheck,
          }
        );

        localStorage.setItem("appliedCouponCode", couponCodeData.code);
        setAppliedCouponCode(couponCodeData);
      } catch (e) {
        if (e.response && e.response.data && e.response.data.valid === false) {
          setCouponCodeError(e.response.data.msg);
        }

        localStorage.removeItem("appliedCouponCode");
      }

      setApplying(false);
    },
    [couponCode]
  );

  const removeCoupon = () => {
    setAppliedCouponCode(false);
    setCouponCode("");

    localStorage.removeItem("appliedCouponCode");
  };

  // Whenever cart changes + whenever page loads
  useEffect(() => {
    const couponCodeInSession = localStorage.getItem("appliedCouponCode");

    if (couponCodeInSession) {
      applyCoupon(couponCodeInSession);
    }
  }, [applyCoupon, subTotal]);

  // Whenever promotional balance preference changes store it in localstorage
  useEffect(() => {
    localStorage.setItem("usePromoBal", usePromotionalBal);
  }, [usePromotionalBal]);

  // Whenever newsletter signup changes store in localstorage
  useEffect(() => {
    localStorage.setItem("cartSignupNewsletter", newsletter);
  }, [newsletter]);

  return (
    <div className="cart-checkout row gutter no-padding-top">
      <Helmet>
        <title>Pryze | My Basket</title>
      </Helmet>
      
      {/* Coupon codes */}
      <div className="col-6 no-margin">
        <div className="slate cart-checkout-coupon">
          {!appliedCouponCode ? (
            <>
              <h3 className="coupon-header">Coupon Code</h3>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  applyCoupon();
                }}
                className="form-row checkout-coupon-form"
              >
                <input
                  type="text"
                  id="couponCode"
                  autoComplete="couponCode"
                  placeholder="Enter code"
                  className="input checkout-coupon-input"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
                <button
                  className="btn btn-blue btn-small checkout-coupon-submit"
                  disabled={applying || !couponCode.length}
                >
                  {!applying ? "Apply" : <LoadingSpinner />}
                </button>
              </form>

              {couponCodeError && (
                <small className="coupon-code-error text-red">{couponCodeError}</small>
              )}
            </>
          ) : (
            <>
              <h3 className="coupon-header">Applied Discount</h3>
              <div className="row no-padding">
                <div className="col">
                  <span className="coupon-info">
                    {appliedCouponCode.code} —
                    {appliedCouponCode.percentDiscount
                      ? ` ${appliedCouponCode.percentDiscount * 100}% OFF`
                      : ` ${appliedCouponCode.fixedDiscount.toLocaleString("en-GB", {
                        style: "currency", currency: "GBP"
                        })} OFF`}
                  </span>
                  <button className="cart-discount-remove-btn" onClick={removeCoupon}>
                    <X />
                  </button>
                </div>
                <div className="col right subtotal-price">
                  -
                  {appliedCouponCode.totalDiscounts.toLocaleString("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="col-6">
        {/* Total price & options */}
        <div className="slate cart-checkout-coupon">
          <div className="row gutter no-padding">
            <div className="cart-checkout-total">
              Total
              <span className="cart-checkout-total-amount">
                {(appliedCouponCode ? appliedCouponCode.newCartTotal : subTotal).toLocaleString(
                  "en-GB",
                  {
                    style: "currency",
                    currency: "GBP",
                  }
                )}
              </span>
            </div>
            <div className="cart-checkout-checkboxes">
              {/* Use Promotional balance yes/no */}
              {!!userPromotionalBalance &&
                (appliedCouponCode ? appliedCouponCode.newCartTotal : subTotal) > 0 && (
                  <div className="row no-margin edit-details">
                    <div className="col">
                      <input
                        type="checkbox"
                        id="promo-bal"
                        checked={usePromotionalBal}
                        onChange={(e) => setUsePromotionalBal(e.target.checked)}
                      />
                      <label htmlFor="promo-bal">
                        <span>Use my promotional balance of </span>
                        {userPromotionalBalance.toLocaleString("en-GB", {
                          style: "currency",
                          currency: "GBP",
                        })}
                      </label>
                    </div>
                  </div>
              )}

              {/* Sign up to newsletter */}
              {!user.state.newsletter && (
                <div className="row no-margin edit-details">
                  <div className="col">
                    <input
                      type="checkbox"
                      id="newsletter"
                      checked={newsletter}
                      onChange={(e) => setNewsletter(e.target.checked)}
                    />
                    <label htmlFor="newsletter">
                      Sign up for offers, news and more
                    </label>
                  </div>
                </div>
              )}

              {/* Accept terms (checkout btn won't be clickable until this is selected yes) */}
              <div className="row no-margin edit-details">
                <div className="col">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={termsChecked}
                    onChange={(e) => setTermsChecked(e.target.checked)}
                  />
                  <label htmlFor="terms">
                    <span>I accept the </span>
                    <a href="/p/terms-and-conditions" target="_blank">
                      terms
                    </a>
                    <span> and </span>
                    <a href="/p/privacy-policy" target="_blank">
                      privacy policy
                    </a>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          className="btn form-btn btn-blue cart-checkout-btn"
          disabled={!termsChecked}
          onClick={() => history.push(`order-payment`)}
        >
          Checkout
        </button>
      </div>
    </div>
  );
};

export default CartCheckoutSummary;
