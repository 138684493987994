import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import Axios from "axios";
import moment from "moment";
import ReactGA from "react-ga4";

import { AppContext } from "../../../context/AppContext";
import { Helmet } from "react-helmet";

import LoadingSpinner from "../../../components/includes/LoadingSpinner";
import OtherRaffles from "../raffles/includes/OtherRaffles";

const OrderConfirmation = () => {
  const { messages } = useContext(AppContext);
  const { orderId } = useParams();

  const history = useHistory();
  const location = useLocation();

  const [excluded, setExcluded] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // If referred from "orderComplete" clear stored cart session details
    if (queryParams.get("ref") === "orderComplete") {
      localStorage.removeItem("usePromoBal");
      localStorage.removeItem("appliedCouponCode");

      if (orderId) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);

        Axios.get(`${process.env.REACT_APP_API}/api/orders/my/${orderId}`).then(({ data }) => {
          const order = data;
          const eventData = { transaction_id: order.orderId, currency: "GBP", value: order.totalPricePaid };

          if (order.couponCodeUsed)
            eventData.coupon = order.couponCodeUsed;

          const items = [];

          for (var i in order.ticketsPurchased) {
            var ticket = order.ticketsPurchased[i];
            items.push({ item_name: ticket.raffle.name, currency: "GBP", item_variant: ticket.ticketNum, quantity: 1 });
          }

          if (items.length > 0) {
            eventData.items = items;
            ReactGA.gtag("event", "purchase", eventData);
          }
        });
      }
    }
  }, [location, orderId]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/orders/my/${orderId}`)
      .then(({ data }) => {
        let canReveal = false;

        const order = data;
        const raffles = [];
        const ticketsPurchased = new Map();

        order.ticketsPurchased.forEach((ticket) => {
          const key = ticket.raffle._id;
          const tickets = ticketsPurchased.get(key);

          if (tickets) {
            ticketsPurchased.set(key, [...tickets, ticket]);
          } else {
            if (ticket.raffle.instantWins && ticket.raffle.instantWins.length > 0) {
              if ("revealed" in ticket && !ticket.revealed)
                canReveal = true;
            }

            raffles.push(key);
            ticketsPurchased.set(key, [ticket]);
          }
        });

        order.canReveal = canReveal;

        for (let [key, value] of ticketsPurchased) {
          value.sort((a, b) => a.ticketNum.localeCompare(b.ticketNum, undefined, { numeric: true }));
          
          ticketsPurchased.set(key, value);
        }

        setExcluded(raffles);

        order.raffles = raffles;
        order.ticketsPurchased = ticketsPurchased;

        Axios.get(`${process.env.REACT_APP_API}/api/contributions/order/${order._id}`)
          .then(({ data }) => {
            const charities = new Set();

            order.allContributions = data.total;
            order.contributions = data.contributions;

            data.contributions.forEach(({ contribution }) => {
              charities.add(contribution.charity.name);
              
              if (order.contributionsTotal)
                order.contributionsTotal += contribution.amount;
              else
                order.contributionsTotal = contribution.amount;
            });

            if (!order.contributionsTotal)
              order.contributionsTotal = 0;

            order.charities = [...charities];

            setOrder(order);
            setLoading(false);
          }).catch(() => {
            messages.dispatch({
              type: "send",
              payload: { type: "error", msg: `Unable to fetch contributions for order ${orderId}!` }
            });

            setOrder(order);
            setLoading(false);
          });
      }).catch(() => {
        history.push("/account/tickets");
        messages.dispatch({
          type: "send",
          payload: { type: "error", msg: `Unable to locate order ${orderId}!` }
        });
      });
  }, [orderId]); // eslint-disable-line

  return (
    <>
      <Helmet>
        <title>Pryze | Order Confirmation</title>
      </Helmet>

      <div className="cta-slab curve">
        {loading ? (
          <div className="row">
            <div className="col">
              <LoadingSpinner className="center" dark={true} />
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col">
                <h1 className="title no-bottom">Nice one! You're in 🤩</h1>
                <h2 className="subtitle">Your order has been confirmed!</h2>
                
                <p className="order-text">Thank you for your purchase. Details of your order can be found below.</p>
                
                {order.charities && order.charities.length > 0 && (
                  <p className="order-text no-top">By entering, you've supported {order.charities.join(", ")}. Well done!</p>
                )}

                <p className="order-text">You can also leave us a review on Trustpilot to rate your experience with us!</p>
                <div className="trustpilot" onClick={() => window.open("https://uk.trustpilot.com/evaluate/pryze.co.uk", "_blank")}></div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="instant-wins-prizes header">
                  <div className="row no-padding">
                    <div className="col">
                      <h1 className="header">Order Summary</h1>
                    </div>
                  </div>
                </div>

                <div className="instant-wins-prizes body">
                  <div className="instant-wins-table">
                    <div className="record">
                      <div className="cell full">
                        <p className="order-text no-top">Order ID: {order.orderId} • Date Placed: {moment(order.createdAt).format("DD/MM/YYYY HH:mm")}</p>
                      </div>
                    </div>
                    
                    {order.canReveal && (
                      <div className="record">
                        <div className="cell full">
                          <Link className="btn btn-blue" to={`/order-reveal/?order=${orderId}`}>Reveal Results</Link>
                        </div>
                      </div>
                    )}

                    {order.raffles.map((id, i) => {
                      const raffleTickets = order.ticketsPurchased.get(id);

                      const raffle = raffleTickets[0].raffle;
                      let image = raffle.images.find(({ keyImage }) => keyImage);

                      if (!image && raffle.images.length > 0)
                        image = raffle.images[0];

                      let total = 0;

                      raffleTickets.forEach(({ pricePaid }) => total += pricePaid);

                      return (
                        <div className="cart-item-container" key={id}>
                          <div className="cart-item">
                            <div className="cart-item-meta">
                              <div className="cart-item-img" style={{ backgroundImage: `url("${image ? image.path : ""}")` }}></div>
                              <div className="cart-item-name">
                                <Link target="_blank" to={`/c/${raffle.slug}`}>
                                  {raffle.name}
                                </Link>
                              </div>
                              <div className="cart-item-tickets-tag">
                                <div className="tag clickable">
                                  {`${raffleTickets.length} tickets`}
                                </div>
                              </div>
                            </div>
                            <div className="cart-item-pricing">
                              <div className="countdown-numbers">
                                <div className="countdown-number smaller">
                                  {total.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
                                </div>
                                <div className="countdown-number"></div>
                              </div>
                            </div>
                          </div>

                          <div className="cart-item-tickets admin" style={{ maxHeight: "1000px" }}>
                            <div>
                              {raffleTickets.map((ticket, i) => (
                                <span key={i}>{ticket.ticketNum}</span>
                              ))}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="row gutter thinner">
              <div className="col-6 hide-mobile">
                <Link
                  className="btn btn-blue full"
                  target="_blank"
                  to="/account/tickets"
                >
                  My Tickets
                </Link>

                <Link
                  className="btn btn-blue full"
                  target="_blank"
                  to="/account/charity"
                >
                  Charity Contributions
                </Link>
              </div>

              <div className="col-5">
                <div className="order-confirmation-summary">
                  <div className="summary-row">
                    <div className="summary-col">
                      <h2 className="black">Subtotal</h2>
                    </div>
                    <div className="summary-col cost">
                      <h2>{order.ticketsSubtotal.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}</h2>
                    </div>
                  </div>

                  <div className="summary-row">
                    <div className="summary-col">
                      <h2 className="black">Total Charged to Card</h2>
                    </div>
                    <div className="summary-col cost">
                      <h2>{order.totalPricePaid.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}</h2>
                    </div>
                  </div>

                  <div className="summary-row">
                    <div className="summary-col">
                      <h2 className="black">Your Charity Contribution</h2>
                    </div>
                    <div className="summary-col cost">
                      <h2>{order.contributionsTotal.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}</h2>
                    </div>
                  </div>

                  <div className="summary-row">
                    <div className="summary-col">
                      <h2 className="black">Total Charity Contributions</h2>
                    </div>
                    <div className="summary-col cost">
                      <h2>{order.allContributions.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col hide-desktop">
                <Link
                  className="btn btn-blue full"
                  target="_blank"
                  to="/account/tickets"
                >
                  My Tickets
                </Link>

                <Link
                  className="btn btn-blue full"
                  target="_blank"
                  to="/account/charity"
                >
                  Charity Contributions
                </Link>
              </div>
            </div>
          </>
        )}
      </div>

      <OtherRaffles className="cta-slab no-margin white" exclude={excluded} />
    </>
  );
};

export default OrderConfirmation;