import Axios from "axios";
import LoadingSpinner from "../../includes/LoadingSpinner";
import React, { useEffect, useState } from "react";
import { Plus, X } from "react-feather";

const CharityRestriction = ({ all = false, appliesToCharities = [], multiple = false, setAppliesToCharities }) => {
  const [showAddCharityPopover, setShowAddCharityPopover] = useState(false);

  const [loading, setLoading] = useState(false);
  const [charitiesQuery, setCharitiesQuery] = useState("");
  const [charitiesResults, setCharitiesResults] = useState([]);

  // Filter categories for search popover
  useEffect(() => {
    setLoading(true);

    const data = {
      query: charitiesQuery,
      filterIds: appliesToCharities.map(({ _id }) => _id),
      limit: 5,
    };

    if (all)
      data.filterBy = "none";

    Axios.post(`${process.env.REACT_APP_API}/api/charities/adminBrowse`, data).then(({ data }) => {
      setCharitiesResults(data.charities);
      setLoading(false);
    });
  }, [all, charitiesQuery, appliesToCharities]);

  const addCharity = (charity) => {
    setAppliesToCharities([...appliesToCharities, charity]);
    setShowAddCharityPopover(false);
  };

  const deleteCharity = (id) => setAppliesToCharities(appliesToCharities.filter(({ _id }) => _id !== id));

  return (
    <div>
      <div className="coupon-code-select-raffles">
        {appliesToCharities.map(({ _id, name }) => (
          <div key={_id} className="tag">
            {name}{" "}
            <button type="button" onClick={() => deleteCharity(_id)}>
              <X />
            </button>
          </div>
        ))}

        <div className="new-tag-container">
          {(multiple || appliesToCharities.length === 0) && (
            <div className="tag new" onClick={() => setShowAddCharityPopover(!showAddCharityPopover)}>
              <Plus />
            </div>
          )}

          {showAddCharityPopover && (
            <div className="new-tag-search">
              <input
                type="search"
                className="admin-input"
                placeholder="Search for charities"
                autoFocus={true}
                value={charitiesQuery}
                onChange={(e) => setCharitiesQuery(e.target.value)}
              />

              {loading ? (
                <LoadingSpinner className="center" dark={true} />
              ) : (
                <div className="category-results">
                  {charitiesResults.length ? (
                    charitiesResults.map(({ _id, name }) => (
                      <div key={name} onClick={() => addCharity({ _id, name })}>
                        {name}
                      </div>
                    ))
                  ) : (
                    <div className="no-cats">No charities to show</div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CharityRestriction;
