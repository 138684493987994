import React from "react";
import { Link } from "react-router-dom";
import { Facebook, Instagram, Tiktok, Twitter } from "@icons-pack/react-simple-icons";

const socialLinks = [
  { name: "Facebook", url: "https://www.facebook.com/pryzeuk/", icon: <Facebook /> },
  { name: "Twitter", url: "https://twitter.com/pryze", icon: <Twitter /> },
  { name: "Instagram", url: "https://instagram.com/pryze", icon: <Instagram /> },
  { name: "TikTok", url: "https://tiktok.com/@pryzeuk", icon: <Tiktok /> },
];

const MainFooter = ({ landing = false }) => {
  return (
    <footer className="app-main-footer" id="footer">
      <div className="row">
        <div className="col">
          {!landing && (
            <div className="footer-logo"></div>
          )}
          
          <p>Pryze Ltd &copy; {new Date().getFullYear()}</p>

          {!landing && (
            <p className="links">
              <Link to="/p/about-us">About Us</Link> | <Link to="/p/acceptable-use">Acceptable Use</Link> | <Link to="/p/privacy-policy">Privacy Policy</Link>
              <br />
              <Link to="/p/terms-and-conditions">Terms &amp; Conditions</Link> | <Link to="/p/terms-of-use">Terms of Use</Link>
            </p>
          )}

          <div className="social-links">
            {socialLinks.map(({ name, url, icon }) => (
              <a
                href={url}
                key={name}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                {icon}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
