import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { X } from "react-feather";
import LoadingSpinner from "../includes/LoadingSpinner";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useGoogleLogin } from "@react-oauth/google";
import { Helmet } from "react-helmet";

const Register = () => {
  const history = useHistory();
  const location = useLocation();
  const { config, messages } = useContext(AppContext);
  const recaptchaRef = useRef();
  const [loading, setLoading] = useState(false);
  const [referral, setReferral] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const [overEighteen, setOverEighteen] = useState(false);
  const [terms, setTerms] = useState(false);
  const [recaptcha, setRecaptcha] = useState();

  const [reward, setReward] = useState("£0.00");
  const [threshold, setThreshold] = useState("£0.00");

  const checkboxes = [
    {
      id: "over-18",
      title: "I am over 18 and a UK resident",
      checked: overEighteen,
      onChange: (e) => setOverEighteen(e.target.checked),
    },
    {
      id: "terms",
      title: (
        <>
          <span>I accept the </span>
          <a href="/p/terms-and-conditions" target="_blank">
            terms
          </a>
          <span> and </span>
          <a href="/p/privacy" target="_blank">
            privacy policy
          </a>
        </>
      ),
      checked: terms,
      onChange: (e) => setTerms(e.target.checked),
    },
    {
      id: "newsletter",
      title: "Sign up for offers, news and more",
      checked: newsletter,
      onChange: (e) => setNewsletter(e.target.checked),
    },
  ];

  const facebookCallback = async (response) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/api/auth/facebook-login`, {
        access_token: response.accessToken,
      });

      localStorage.setItem("sessionToken", data.token);
      redirectAfterRegister();
    } catch (error) {
      messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "Facebook login failed" },
      });
    }
  };
  
  const googleCallback = async (response) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/api/auth/google-login`, {
        access_token: response.access_token,
      });

      localStorage.setItem("sessionToken", data.token);
      redirectAfterRegister();
    } catch (error) {
      messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "Google login failed" },
      });
    }
  };

  const googleCallbackError = () => {
    messages.dispatch({
      type: "send",
      payload: { type: "error", msg: "Google login failed" }
    });
  };

  const onGoogleClick = useGoogleLogin({
    flow: "implicit",
    onError: googleCallbackError,
    onSuccess: googleCallback
  });

  const redirectAfterRegister = () => {
    const queryParams = new URLSearchParams(location.search);

    // If referred from "orderComplete" clear stored cart session details
    if (queryParams.get("redirect")) {
      history.push(queryParams.get("redirect"));
    } else {
      history.push("/");
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const body = {
        firstName,
        lastName,
        email,
        password,
        passwordAgain,
        newsletter,
        recaptcha,
      };

      if (referral)
        body.referralCode = referral.code;

      await axios.post(`${process.env.REACT_APP_API}/api/auth/register`, {
        ...body
      });

      setLoading(false);

      // Log the user in automatically (with remember me)
      const { data } = await axios.post(`${process.env.REACT_APP_API}/api/auth/login`, {
        email,
        password,
        rememberMe: true,
      });

      localStorage.removeItem("referralCode");
      localStorage.setItem("sessionToken", data.token);

      // Redirect after registration
      const queryParams = new URLSearchParams(location.search);

      // If referred from "orderComplete" clear stored cart session details
      if (queryParams.get("redirect")) {
        history.push(queryParams.get("redirect"));
      } else {
        history.push("/");
      }
    } catch (error) {
      setLoading(false);
      recaptchaRef.current.reset();

      let msg = "Something went wrong.";
      if (error.response && error.response.data) msg = error.response.data.msg;

      messages.dispatch({
        type: "send",
        payload: { type: "error", msg },
      });
    }
  };

  useEffect(() => {
    const code = localStorage.getItem("referralCode");
    
    if (code) {
      setLoading(true);

      axios.get(`${process.env.REACT_APP_API}/api/auth/checkCode/${code}`)
        .then(({ data }) => {
          setReferral({ code, user: data.user });
          setLoading(false);
        }).catch(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (!config || !config.state) {
      return;
    }

    const referrals = config.state.find(({ type }) => type === "referrals");

    if (referrals && referrals.options) {
      setReward(referrals.options.bonus.toLocaleString("en-GB", { style: "currency", currency: "GBP" }));
      setThreshold(referrals.options.required.toLocaleString("en-GB", { style: "currency", currency: "GBP" }));
    }
  }, [config, config.state]);

  return (
    <div className="main-panel">
      <Helmet>
        <title>Pryze | Join</title>
      </Helmet>
      
      <div className="row center-cols">
        <div className="col-5">
          <div className="slate">
            <h1 className="title">Register</h1>

            {referral && (
              <p className="referral">
                You are currently being referred by {referral.user.firstName}.{" "}
                This means, after spending {threshold} on our site, you will each receive {reward} credit!
              </p>
            )}

            <span className="login-or">Register with</span>

            <div className="social-login-btns">
              <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  fields="name,email"
                  onSuccess={facebookCallback}
                  render={({ onClick }) => (
                    <button type="button" className="facebook-login-btn" onClick={onClick} />
                  )}
                />
                <button type="button" className="google-login-btn" onClick={onGoogleClick} />
            </div>

            <span className="login-or">or</span>

            <form onSubmit={(e) => submit(e)}>
              <div className="form-row">
                <input
                  type="text"
                  id="first-name"
                  placeholder="First Name *"
                  autoComplete="first-name"
                  className="input white"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>

              <div className="form-row">
                <input
                  type="text"
                  id="last-name"
                  placeholder="Last Name *"
                  autoComplete="last-name"
                  className="input white"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="form-row">
                <input
                  type="email"
                  id="email"
                  placeholder="Email *"
                  autoComplete="email"
                  className="input white"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="form-row">
                <input
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  className="input white"
                  placeholder="Password *"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="form-row">
                <input
                  type="password"
                  id="password-again"
                  autoComplete="confirm-new-password"
                  className="input white"
                  placeholder="Confirm Password *"
                  value={passwordAgain}
                  onChange={(e) => setPasswordAgain(e.target.value)}
                />
              </div>

              {referral && (
                <div className="form-row">
                  <label className="label-code" htmlFor="referralCode">Referral Code</label>
                  <X
                    className="delete-code"
                    size={15}
                    onClick={() => {
                      localStorage.removeItem("referralCode");
                      setReferral(false);
                    }}
                  />
                  <input
                    type="text"
                    id="referral-code"
                    className="input white disabled"
                    value={referral.code}
                    disabled
                  />
                </div>
              )}

              {checkboxes.map(({ id, title, checked, onChange }) => (
                <div className="row no-padding edit-details upper" key={id}>
                  <input type="checkbox" id={id} checked={checked} onChange={onChange} />
                  <label htmlFor={id}>{title}</label>
                </div>
              ))}

              <div className="recaptcha-widget">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LfrLDkdAAAAAI38R9Q6o2cd-p0Lf_iX9bz6Lvdj"
                  onChange={(val) => setRecaptcha(val)}
                />
              </div>

              <button
                type="submit"
                className="btn form-btn btn-blue"
                disabled={loading || !terms || !overEighteen}
              >
                {!loading ? "Register" : <LoadingSpinner />}
              </button>

              <p className="login-or">
                Already have an account? Click <Link to="/auth/login">here</Link> to login.
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
