import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import LoadingSpinner from "../../includes/LoadingSpinner";

const HowDoesItWork = () => {
  const { config } = useContext(AppContext);

  const [currentStage, setCurrentStage] = useState({});
  const [howItWorks, setHowItWorks] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!config || !config.state) {
      return;
    }
    
    let howItWorks = config.state.find(({ type }) => type === "how_it_works") || {};

    setHowItWorks(howItWorks);

    if (howItWorks && howItWorks.stages && howItWorks.stages.length > 0) {
      setCurrentStage(howItWorks.stages[0]);
      setLoading(false);
    }
  }, [config, config.state]);

  return (
    <div className="row">
      <div className="row gutter">
        {loading ? (
          <div className="col">
            <LoadingSpinner className="center" />
          </div>
        ) : (
          <>
            <div className="col-2"></div>
            <div className="how-to">
              <div className="col-4">
                <h1 className="title white">It's as simple as</h1>

                <div className="steps">
                  {howItWorks && howItWorks.stages && howItWorks.stages.map((stage, i) => (
                    <button key={stage.stage} className={"btn btn-" + (currentStage.stage === stage.stage ? "darkblue" : "white") + " circle"} onClick={() => setCurrentStage(stage)}>
                      {stage.stage}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="stage-box">
                <div className="stage-image" style={{ backgroundImage: `url("${currentStage.image}")` }} />
                <div className="stage-info">
                  <h2 className="subtitle top">{currentStage.header}</h2>
                  <p className="info-text">{currentStage.text}</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HowDoesItWork;
