import Axios from "axios";
import React, { useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import CharityRestriction from "../includes/CharityRestriction";

const AddContributionModal = ({ closeModal }) => {
  const { messages } = useContext(AppContext);

  const [appliesToCharities, setAppliesToCharities] = useState([]);
  const [amount, setAmount] = useState("");

  const submit = (e) => {
    e.preventDefault();

    if (!appliesToCharities || appliesToCharities.length === 0 || !amount)
      return messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "All fields required" },
      });

    Axios.post(`${process.env.REACT_APP_API}/api/contributions`, { charity: appliesToCharities[0], amount })
      .then(() => {
        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: "Successfully added contribution" },
        });

        closeModal();
      })
      .catch((err) => {
        if (err.response.data && err.response.data.msg) {
          console.log(err.response.data.msg);
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg },
          });
        }
      });
  };

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={closeModal}></div>
      <div className="modal">
        <h2>Add Contribution</h2>
        <form onSubmit={(e) => submit(e)}>
          <div className="form-row">
            <label htmlFor="name">Charity</label>
            <CharityRestriction
                appliesToCharities={appliesToCharities}
                multiple={false}
                setAppliesToCharities={setAppliesToCharities}
            />
          </div>

          <div className="form-row">
            <label htmlFor="url">Amount</label>
            <input
              type="number"
              id="amount"
              className="admin-input"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>

          <button type="submit" className="btn btn-admin">
            Add
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddContributionModal;