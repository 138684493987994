import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import { useHistory } from "react-router-dom";

const CookieNotice = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("cookieNoticeDismissed") !== "true") {
      setShow(true);
    }
  }, []);

  const onLearnMore = () => {
    history.push("/p/privacy-policy");
  };

  const onClose = () => {
    localStorage.setItem("cookieNoticeDismissed", true);
    setShow(false);
  };

  return (
    show && (
      <div className="global-cookie-notice">
        <span onClick={onLearnMore}>
          We use cookies to ensure you have the best experience on our site — click here to learn
          more.
        </span>
        <button className="close-icon" onClick={onClose}>
          <X size={20} />
        </button>
      </div>
    )
  );
};

export default CookieNotice;
