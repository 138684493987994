import React, { useState, useContext } from "react";
import { Edit2 } from "react-feather";
import Axios from "axios";
import { AppContext } from "../../../../context/AppContext";
import moment from "moment";
import Dropzone from "react-dropzone";

const GlobalPromoPopup = ({ data: configData = {} }) => {
  const { messages } = useContext(AppContext);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [image, setImage] = useState(configData.image);
  const [active, setActive] = useState(configData.options.active || false);
  const [excludeLoggedIn, setExcludeLoggedIn] = useState(
    configData.options.excludeLoggedIn || false
  );
  const [link, setLink] = useState(configData.options.link || "");
  const [lastUpdated, setLastUpdated] = useState(configData.updatedAt);

  const uploadImage = (e) => {
    if (!e[0]) return false;

    const formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("image", e[0]);

    messages.dispatch({ type: "send", payload: { type: "info", msg: "Uploading..." } });

    Axios.post(`${process.env.REACT_APP_API}/api/imageUpload`, formData, config)
      .then(({ data }) => {
        if (data.file) {
          Axios.post(`${process.env.REACT_APP_API}/api/config/${configData._id}`, {
            image: data.file,
          })
            .then(() => {
              messages.dispatch({ type: "send", payload: { type: "success", msg: "Saved" } });
              setImage(data.file);
              setShowImageUpload(false);
            })
            .catch((e) => {
              if (e.response && e.response.data)
                messages.dispatch({
                  type: "send",
                  payload: { type: "error", msg: e.response.data.msg.message },
                });
            });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg },
          });
        }
      });
  };

  const save = (e) => {
    e.preventDefault();

    Axios.post(`${process.env.REACT_APP_API}/api/config/${configData._id}`, {
      ...configData,
      image,
      options: { active, excludeLoggedIn, link },
    })
      .then(() => {
        messages.dispatch({ type: "send", payload: { type: "success", msg: "Saved" } });
        setLastUpdated(Date.now());
      })
      .catch((e) => {
        if (e.response && e.response.data)
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: e.response.data.msg.message },
          });
      });
  };

  return (
    <div>
      {showImageUpload && (
        <div className="modal-container">
          <div className="modal-bg" onClick={() => setShowImageUpload(false)}></div>
          <div className="modal">
            <h2>Upload Image</h2>

            <div className="form-row">
              <Dropzone
                multiple={false}
                accept={["image/jpeg", "image/jpg", "image/png", "image/gif"]}
                onDropAccepted={(e) => uploadImage(e)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="input-dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>

            <button className="btn btn-admin" onClick={() => setShowImageUpload(false)}>
              Close
            </button>
          </div>
        </div>
      )}

      <div className="config-section">
        <h2 className="admin-subtitle">Promo Popup</h2>

        <div className="row no-padding gutter">
          <div className="col-2">
            <div
              className="config-dynamic-image"
              onClick={() => setShowImageUpload(true)}
              style={{
                backgroundImage: `url(${image})`,
              }}
            >
              <div className="edit-icon">
                <Edit2 />
              </div>
            </div>
            <span className="promo-popup-recommended-size">Recommended size: 1000x700</span>
          </div>
          <div className="col-10">
            <form onSubmit={save} className="config-promo-popup">
              <div className="promo-option">
                <input
                  type="url"
                  id="link"
                  className="admin-input white"
                  placeholder="Promo Link"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </div>
              <div className="promo-option">
                <input
                  type="checkbox"
                  id="active"
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
                <label htmlFor="active">Active</label>
              </div>
              <div className="promo-option">
                <input
                  type="checkbox"
                  id="excludeLoggedIn"
                  checked={excludeLoggedIn}
                  onChange={(e) => setExcludeLoggedIn(e.target.checked)}
                />
                <label htmlFor="excludeLoggedIn">Exclude logged in users</label>
              </div>

              <button type="submit" className="btn btn-admin btn-small">
                Save
              </button>
              <small>Last updated {moment(lastUpdated).fromNow()}</small>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalPromoPopup;
