import React, { useContext, useState } from "react";
import { DollarSign, Edit2, Trash2 } from "react-feather";
import AddEditPrizeModal from "./add-question/AddEditPrizeModal";
import RaffleInstantCSVUpload from "./file-upload/RaffleInstantCSVUpload";
import { AppContext } from "../../../../context/AppContext";

const RaffleModifyInstantWins = ({ description = "", prizes = [], update }) => {
  const { messages } = useContext(AppContext);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const afterSubmit = (prize) => {
    if (typeof showAddModal === "object") {
      return update("instantWins", prizes.map((p, i) => (i === showAddModal.i ? prize : p)));
    }

    return update("instantWins", [...prizes, prize]);
  };

  const deletePrize = (i) => {
    if (window.confirm("Are you sure that you would like to delete this prize?"))
      update("instantWins", prizes.filter((_, j) => i !== j));
  };

  const onUpload = (uploaded) => {
    if (!uploaded || uploaded.length === 0) {
      messages.dispatch({ type: "send", payload: { type: "error", msg: "Invalid CSV format!" } });
      return;
    }

    let parsed = new Map();
    prizes.forEach((prize) => parsed.set(prize.ticketNum, prize));

    for (var upload of uploaded) {
      if (Object.keys(upload).length !== 4) return messages.dispatch({ type: "send", payload: { type: "error", msg: "Invalid CSV format!" } });
      else if (parsed.has(upload.ticketNum)) continue;

      const value = parseFloat(upload.value);
      const promotionalBalance = upload.promotionalBalance.toLowerCase() === "true";

      parsed.set(upload.ticketNum, { ticketNum: upload.ticketNum, prize: upload.prize, value, promotionalBalance });
    }
    
    parsed = [...parsed.values()];
    const updated = parsed.sort((a, b) => a.ticketNum.localeCompare(b.ticketNum, undefined, { numeric: true }));

    update("instantWins", updated);
    setShowUploadModal(false);
  };

  return (
    <div className="row no-padding gutter">
      <div className="col-6">
        <div className="admin-slate">
          <h2>Instant Wins Description</h2>
          <textarea
            cols="30"
            rows="3"
            className="admin-input"
            value={description}
            onChange={e => update("instantWinsDescription", e.target.value)}
          />
        </div>
      </div>
      <div className="col-6">
        <div className="admin-slate">
          <h2>Instant Wins Prizes</h2>

          {showAddModal && (
            <AddEditPrizeModal
              afterSubmit={afterSubmit}
              closeModal={() => setShowAddModal(false)}
              prizeData={showAddModal}
            />
          )}

          {showUploadModal && (
            <RaffleInstantCSVUpload
              onUpload={onUpload}
              closeModal={() => setShowUploadModal(false)}
            />
          )}

          <div className="form-row">
            <div className="row no-padding no-gutter">
              <div className="col right">
                <button type="button" className="btn btn-admin btn-tiny" onClick={() => setShowAddModal(true)}>
                  Add Prizes
                </button>
                <button type="button" className="btn btn-admin btn-tiny" onClick={() => setShowUploadModal(true)} style={{ marginLeft: "10px" }}>
                  Upload CSV
                </button>
              </div>
            </div>

            <table className="raffles-table no-side-padding">
              <tbody>
                {!prizes.length ? (
                  <tr>
                    <td colSpan="5" className="no-raffles">
                      No prizes to show
                    </td>
                  </tr>
                ) : (
                  prizes.map(({ prize, promotionalBalance, ticketNum, value }, i) => (
                    <tr key={i}>
                      <td className="left">#{ticketNum}</td>
                      <td>{prize}</td>
                      <td>£{value}</td>
                      <td>{promotionalBalance && <DollarSign height={20} width={20} />}</td>
                      <td className="right">
                        <button
                          type="button"
                          className="delete-btn"
                          onClick={() => setShowAddModal({ i, prize, promotionalBalance, ticketNum, value })}
                        >
                          <Edit2 />
                        </button>
                        <button type="button" className="delete-btn" onClick={() => deletePrize(i)}>
                          <Trash2 />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaffleModifyInstantWins;