import React, { useContext, useRef, useState } from "react";
import Axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

import { AppContext } from "../../../context/AppContext";
import LoadingSpinner from "../../includes/LoadingSpinner";
import { Helmet } from "react-helmet";

const Contact = () => {
  const { messages, user } = useContext(AppContext);

  const [recaptcha, setRecaptcha] = useState();
  const recaptchaRef = useRef();

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState(user.state.email || "");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const submit = (e) => {
    e.preventDefault();

    if (!email || !subject || !message || !recaptcha)
      return messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "All fields must be filled!" }
      });
    
    setLoading(true);

    Axios.post(`${process.env.REACT_APP_API}/api/contact`, { email, message, subject, recaptcha }).then(() => {
      messages.dispatch({
        type: "send",
        payload: { type: "success", msg: "Your message has been successfully sent to our team!" }
      });

      setLoading(false);

      setEmail(user.state.email || "");
      setSubject("");
      setMessage("");

      recaptchaRef.current.reset();
    }).catch((err) => {
      messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "There was an error sending your message, please try again!" }
      });

      setLoading(false);
      recaptchaRef.current.reset();
    });
  };

  return (
    <div className="cta-slab curve">
      <Helmet>
        <title>Pryze | Contact</title>
      </Helmet>
      
      <div className="row">
        <div className="col">
          <h1 className="title">Contact Us</h1>
          
          <form className="contact-form" onSubmit={submit}>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              className="input white"
              placeholder="Email"
              value={email}
              style={user.state.email ? { display: "none" } : {}}
              disabled={user.state.email}
            />

            <input
              type="text"
              onChange={(e) => setSubject(e.target.value)}
              className="input white"
              placeholder="Subject"
              value={subject}
            />

            <textarea
              className="input white"
              placeholder="Message"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />

            <div className="recaptcha-widget">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LfrLDkdAAAAAI38R9Q6o2cd-p0Lf_iX9bz6Lvdj"
                onChange={(val) => setRecaptcha(val)}
              />
            </div>

            <button
              type="submit"
              className="btn form-btn btn-blue"
              disabled={loading}
            >
              {!loading ? "Submit" : <LoadingSpinner />}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;