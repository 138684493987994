import Axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import OrderCartSummary from "../cart-summary/OrderCartSummary";

const SetPaymentDetails = ({ card }) => {
  const { messages } = useContext(AppContext);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [charityTotal, setCharityTotal] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(false);
  const [promoBalDiscount, setPromoBalDiscount] = useState(false);
  const [orderSubtotal, setOrderSubtotal] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);

  const [checkoutId, setCheckoutId] = useState(false);
  
  //const [paymentToken, setPaymentToken] = useState(false);
  //const [showSave, setShowSave] = useState(false);

  //const [cof, setCof] = useState(false);
  //const [st, setSt] = useState(false);
  //const [updated, setUpdated] = useState(false);

  const dispatchMessage = messages.dispatch;

  const usePromotionalBalance = localStorage.getItem("usePromoBal") === "true";
  const couponCode = localStorage.getItem("appliedCouponCode");
  const newsletter = localStorage.getItem("cartSignupNewsletter") === "true";

  /*
  useEffect(() => {
    if (!st || !updated) {
      return;
    }

    const button = document.getElementById("st-form__submit");
    button.style.display = "none";

    Axios.get(`${process.env.REACT_APP_API}/api/payments/getToken?card=undefined&cof=${cof ? "1" : "0"}&couponCode=${localStorage.appliedCouponCode}&usePromoBal=${localStorage.usePromoBal}`)
      .then(({ data }) => {
        st.updateJWT(data.paymentToken);

        button.style.display = "block";
        setUpdated(false);
      });
  }, [cof, st, updated]);
  */

  useEffect(() => {
    const err = async () => {
      setLoading(false);
      history.push("basket");

      dispatchMessage({
        type: "send",
        payload: {
          type: "error",
          msg: "There was a problem retrieving your cart. Please try again later.",
        },
      });
    };

    Axios.post(`${process.env.REACT_APP_API}/api/payments/setup`, {
      usePromotionalBalance,
      couponCode,
      newsletter,
    }).then(({ data }) => {
      if (!data.tickets.length) {
        return history.push("basket");
      }

      //setCof(card !== undefined);
      setTickets(data.tickets);
      setCharityTotal(data.charityTotal);
      setOrderSubtotal(data.subtotal);
      setCouponDiscount(data.couponDiscount);
      setPromoBalDiscount(data.promoBalDiscount);
      setOrderTotal(data.totalPrice);

      Axios.get(`${process.env.REACT_APP_API}/api/payments/getToken?card=${card ? card._id : "undefined"}&cof=${card ? "2" : "0"}&couponCode=${localStorage.appliedCouponCode}&gateway=total_processing&usePromoBal=${localStorage.usePromoBal}`)
        .then(({ data }) => {
          setCheckoutId(data.id);
          //setPaymentToken(data.paymentToken);
          
          setLoading(false);
        }).catch(() => err());
    }).catch(() => {
      setLoading(false);
      history.push("basket");

      dispatchMessage({
        type: "send",
        payload: {
          type: "error",
          msg: "There was a problem retrieving your cart. Please try again later.",
        },
      });
    });
  }, [card, dispatchMessage, history, usePromotionalBalance, couponCode, newsletter]);

  useEffect(() => {
    if (!checkoutId) return;

    const options = document.createElement("script");

    options.innerHTML = `
      var wpwlOptions = {
        style: "plain",
        brandDetection: true,
        onReady: function() {
          $(".wpwl-group-cardHolder").after($(".wpwl-group-expiry"));
          $(".wpwl-group-cardNumber").before($(".wpwl-group-cardHolder"));

          $(".wpwl-label-cardHolder").html("Card Holder");
          $(".wpwl-label-cvv").html("Security Code");
          $(".wpwl-control-cardHolder").attr("placeholder", "Card Holder");
        }
      };
    `;

    const script = document.createElement("script");
    
    script.src = `${process.env.REACT_APP_TOTAL_PROCESSING_URL}/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
    script.async = true;

    document.body.appendChild(options);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(options);
      document.body.removeChild(script);
    };
  }, [checkoutId]);

  /*
  useEffect(() => {
    if (!paymentToken) {
      return;
    }

    const timeoutID = setTimeout(() => {
      (function() {
        var options = {
          jwt: paymentToken,
          animatedCard: false,
          livestatus: 1,
          translations: {
            "Card number": "Card Number",
            "Expiration date": "Expiration Date",
            "Security code": "Security Code"
          },
          styles: {
            "background-color-input": "#f3f8fc",
            "border-radius-input": "30px",
            "font-size-input": "15px",
            "border-size-input": "0px",
            "space-inset-input": "17px 22px",
            "font-size-label": "18px",
            "line-height-label": "30px",
            "color-label": "#000",
            "color-input": "#000"
          }
        };

        if (card !== undefined) {
          options.fieldsToSubmit = ["securitycode"];
        } else {
          options.deferInit = true;
        }

        var st = window.SecureTrading(options);

        st.Components();

        if (card === undefined) {
          const amount = `${orderTotal.toFixed(2)}`;

          st.ApplePay({
            buttonPlacement: "st-apple-pay",
            buttonStyle: "black",
            buttonText: "plain",
            merchantId: process.env.REACT_APP_APPLE_MERCHANT_ID,
            paymentRequest: {
              countryCode: "GB",
              currencyCode: "GBP",
              merchantCapabilities: ["supports3DS", "supportsDebit"],
              supportedNetworks: ["amex", "masterCard", "visa"],
              total: {
                amount,
                label: "Pryze Ltd"
              }
            }
          });
        }
        
        setSt(st);

        if (card === undefined) {
          setShowSave(true);
        }
      })();
    }, 1000);

    return () => clearTimeout(timeoutID);
  }, [card, orderTotal, paymentToken]);
  */

  // If payable balance is 0
  const processNoPayment = async () => {
    setProcessing(true);

    try {
      await Axios.post(`${process.env.REACT_APP_API}/api/payments/noPayment`, {
        usePromotionalBalance,
        couponCode,
        newsletter,
      }).then(({ data }) => {
        window.location.replace(`/order-confirmation/${data.orderId}?ref=orderComplete`);
      });
    } catch (error) {
      dispatchMessage({
        type: "send",
        payload: {
          type: "error",
          msg: "There was a problem processing your order.",
        },
      });
    }

    setProcessing(false);
  };

  return (
    <div>
      {loading ? (
        <div className="center">
          <LoadingSpinner dark={true} />
        </div>
      ) : (
        <div className="row gutter no-padding center-cols">
          <div className="col-4">
            <OrderCartSummary
              tickets={tickets}
              charityTotal={charityTotal}
              subtotal={orderSubtotal}
              couponDiscount={couponDiscount}
              promoBalDiscount={promoBalDiscount}
              orderTotal={orderTotal}
            />

            {/* If order doesn't require payment */}
            {orderTotal === 0 && (
              <button
                className="btn form-btn btn-blue cart-checkout-btn"
                disabled={processing}
                onClick={processNoPayment}
              >
                {processing ? "Processing..." : "Complete Order"}
              </button>
            )}
          </div>

          {orderTotal !== 0 && (
            <div className="col-6">
              <div className="cart-main cart-items">
                <div className="row no-padding">
                  <div className="col-12 no-margin">
                    {card ? (
                      <h3 className="cart-items-address-title">Payment Details - {card.type} (x-{card.lastFour})</h3>
                    ) : (
                      <h3 className="cart-items-address-title">Payment Details</h3>
                    )}

                    <div id="payment-form" className="payment-form">
                      <form
                        action="order-payment"
                        className="paymentWidgets"
                        data-brands="VISA MASTER"
                      ></form>
                    </div>
                    
                    {/*
                    <div id="payment-form" className="payment-form">
                      <div id="st-notification-frame"></div>
                      <form
                        id="st-form"
                        action={`${process.env.REACT_APP_API}/api/payments/callback?cof=${cof ? "1" : "0"}&couponCode=${localStorage.appliedCouponCode}&token=${localStorage.sessionToken}&usePromoBal=${localStorage.usePromoBal}`}
                        method="POST"
                      >
                        <div id="st-apple-pay"></div>
                        <div id="st-google-pay"></div>
                        {showSave && (
                          <span className="or-card">Or pay with card</span>
                        )}
                        <div className="row gutter no-padding">
                          <div id="st-card-number" className="col-12 form-input no-margin st-card-number"></div>
                          <div id="st-expiration-date" className="col-6 form-input no-margin st-expiration-date"></div>
                          <div id="st-security-code" className="col-6 form-input st-security-code"></div>
                        </div>
                        {showSave && (
                          <div className="row no-padding edit-details">
                            <input
                              id="save-details"
                              type="checkbox"
                              checked={cof}
                              onChange={(e) => {
                                setCof(e.target.checked);
                                setUpdated(true);
                              }}
                            />
                            <label htmlFor="save-details">Save card details for later</label>
                          </div>
                        )}
                        {updated && (
                          <LoadingSpinner className="center" dark={true} />
                        )}
                        <button type="submit" id="st-form__submit" className="st-form__submit" disabled>
                          {paymentToken ? "Pay" : "Please wait..."}
                        </button>
                        <div id="st-animated-card" className="st-animated-card-wrapper"></div>
                      </form>
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SetPaymentDetails;
