import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../includes/LoadingSpinner";
import SetAddresses from "./steps/SetAddresses";
import SetCard from "./steps/SetCard";
import SetPaymentDetails from "./steps/SetPaymentDetails";
import VerifyPayment from "./steps/VerifyPayment";

const OrderPayment = () => {
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(-1);

  const [checkoutId, setCheckoutId] = useState(false);
  const [resourcePath, setResourcePath] = useState(false);

  const [step, setStep] = useState(false);

  const couponCode = localStorage.getItem("appliedCouponCode");
  const location = useLocation();
  const newsletter = localStorage.getItem("cartSignupNewsletter") === "true";
  const usePromotionalBalance = localStorage.getItem("usePromoBal") === "true";

  const selectCard = (id) => {
    for (let i = 0; i < cards.length; i++) {
      var card = cards[i];

      if (card._id === id) {
        setSelectedCard(i);
        return;
      }
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get("id") && queryParams.get("resourcePath")) {
      setCheckoutId(queryParams.get("id"));
      setResourcePath(queryParams.get("resourcePath"));

      setStep("verify");
    } else {
      Axios.post(`${process.env.REACT_APP_API}/api/payments/setup`, {
        usePromotionalBalance,
        couponCode,
        newsletter,
      }).then(({ data }) => {
        const totalPrice = data.totalPrice;

        if (totalPrice > 0) {
          Axios.get(`${process.env.REACT_APP_API}/api/card/all`).then(({ data }) => {
            if (data.cards && data.cards.length > 0) {
              setCards(data.cards.map(({ _id, lastFour, type }) => (
                { _id, lastFour, type }
              )));
            }
      
            setStep("address");
          }).catch(() => setStep("address"));
        } else {
          setStep("address");
        }
      }).catch(() => setStep("address"));
    }
  }, [couponCode, location.search, newsletter, usePromotionalBalance]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <div className="cta-slab curve">
      <Helmet>
        <title>Pryze | Checkout</title>
      </Helmet>

      <div className="row">
        {step ? (
          <div className="col cart">
            <h1 className="title">Checkout</h1>

            <div>
              {step === "address" && <SetAddresses cards={cards.length > 0} setStep={setStep} />}
              {step === "card" && <SetCard cards={cards} setCard={selectCard} setStep={setStep} />}
              {step === "payment" && <SetPaymentDetails card={selectedCard > -1 ? cards[selectedCard] : undefined} />}
              {step === "verify" && <VerifyPayment checkoutId={checkoutId} resourcePath={resourcePath} />}
            </div>
          </div>
        ) : (
          <div className="col">
            <LoadingSpinner className="center" dark={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderPayment;