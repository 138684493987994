import Axios from "axios";
import moment from "moment";
import React, { useState, useContext } from "react";

import { AppContext } from "../../../../context/AppContext";

const ConfigStatistics = ({ data: configData }) => {
  const { messages } = useContext(AppContext);

  const [totalWon, setTotalWon] = useState(configData.options.totalWon || 0);  
  const [lastUpdated, setLastUpdated] = useState(configData.updatedAt);

  const save = (e) => {
    e.preventDefault();

    Axios.post(`${process.env.REACT_APP_API}/api/config/${configData._id}`, { ...configData, options: { totalWon } })
      .then(() => {
        messages.dispatch({ type: "send", payload: { type: "success", msg: "Saved" } });
        setLastUpdated(Date.now());
      })
      .catch((e) => {
        if (e.response && e.response.data)
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: e.response.data.msg.message },
          });
      });
  };

  return (
    <div>
      <div className="config-section">
        <h2 className="admin-subtitle">Homepage Statistics</h2>

        <form onSubmit={save} className="config-referral-scheme">
          <div className="form-row">
            <label htmlFor="bonus">Total Won</label>
            <input
              type="number"
              id="totalwon"
              className="admin-input white"
              value={totalWon}
              onChange={(e) => setTotalWon(parseInt(e.target.value))}
            />
          </div>

          <div className="dynamic-text-actions">
            <button type="submit" className="btn btn-admin btn-small">
              Save
            </button>
            <small>Last updated {moment(lastUpdated).fromNow()}</small>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfigStatistics;