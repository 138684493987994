import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import useMediaQuery from "../../../../utils/useMediaQuery";

const RaffleImageCarousel = ({ onSale, images = [] }) => {
  const [sortedImages, setSortedImages] = useState([images.filter(image => !image.keyImage)]);

  const isDesktop = useMediaQuery("(max-width: 1920px)");
  const isMobile = useMediaQuery("(max-width: 900px)");
  const isTablet = useMediaQuery("(max-width: 1300px)");

  useEffect(() => {
    setSortedImages(images.filter(image => !image.keyImage));
  }, [images]);
  
  return (
    <>
      <Slider
        className="raffle-slider"
        dots={false}
        slidesToShow={!isDesktop ? 4 : (!isTablet ? 3 : (!isMobile ? 2 : 1))}
      >
        {sortedImages && sortedImages.length && sortedImages.map((image, i) => (
          <div className="raffle-carousel-slide" key={i}>
            <img alt={i} className={"raffle-image" + (i === 0 ? " curve" : "")} src={image.path} />
          </div>
        ))}
      </Slider>

      {onSale && (
        <div className="raffle-sale-banner">
          <p className="sale-text">SALE!</p>
        </div>
      )}
    </>
  )
};

export default RaffleImageCarousel;
