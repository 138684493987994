import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Redirect, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";

const ReferralPage = () => {
  const { code } = useParams();
  const { messages, user } = useContext(AppContext);

  if (user.state._id) {
    messages.dispatch({
      type: "send",
      payload: { type: "error", msg: "You cannot use a new referral code as you have already registered!" }
    });
  } else {
    localStorage.setItem("referralCode", code);
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content="🎉 You're invited to play on Pryze, and get £5!" />
        <meta property="og:image" content="https://pryze-static.s3.eu-west-2.amazonaws.com/img/pryzereferrals-254d3b31-2ece-4a13-90c0-c098c47c8bac.png" />

        <title>Pryze | Join</title>
      </Helmet>

      {user.state._id ? (
        <Redirect to="/" />
      ) : (
        <Redirect to="/auth/register" />
      )}
    </>
  );
}

export default ReferralPage;