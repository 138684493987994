import Axios from "axios";
import Dropzone from "react-dropzone";
import React, { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../../context/AppContext";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import { Trash2 } from "react-feather";

const ConfigPromotedRaffles = () => {
  const { messages } = useContext(AppContext);

  const [promoted, setPromoted] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);

  const [image, setImage] = useState("");
  const [raffle, setRaffle] = useState({});

  const [loading, setLoading] = useState(false);
  const [raffleQuery, setRaffleQuery] = useState("");
  const [raffleResults, setRaffleResults] = useState([]);
  const [showRaffleFind, setShowRaffleFind] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/promotedRaffles/all`).then(({ data }) => {
      setPromoted(data.promotedRaffles);
    });
  }, []);

  useEffect(() => {
    setShowAddModal(false);
  }, [promoted]);
  
  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_API}/api/raffles/adminBrowse`, {
      query: raffleQuery,
      limit: 5,
    }).then(({ data }) => {
      setRaffleResults(data.raffles);
      setLoading(false);
    });
  }, [raffleQuery]);

  useEffect(() => {
    setImage("");
    setRaffle({});
  }, [showAddModal]);
  
  const addItem = (e) => {
    e.preventDefault();

    if (!image || !raffle)
      return messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "All fields required" },
      });

    Axios.post(`${process.env.REACT_APP_API}/api/promotedRaffles`, { image, raffle: raffle._id }).then(({ data }) => {
      setPromoted(promoted.length > 0 ? [...promoted, { _id: data.promotedRaffle._id, image, raffle }] : [{ _id: data.promotedRaffle._id, image, raffle }]);
      messages.dispatch({
        type: "send",
        payload: { type: "success", msg: "Successfully updated promoted raffles" }
      });
    }).catch(() => messages.dispatch({ type: "send", payload: { type: "error", msg: "Error adding promoted raffle!" } }));
  };

  const deleteItem = (index) => {
    if (window.confirm("Are you sure you want to delete this promoted raffle?")) {
      let raffle = promoted[index]._id;

      Axios.delete(`${process.env.REACT_APP_API}/api/promotedRaffles/${raffle}`).then(() => {
        setPromoted(promoted.filter((_, i) => i !== index));
        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: "Successfully updated promoted raffles" }
        });
      }).catch(() => messages.dispatch({ type: "send", payload: { type: "error", msg: "Error deleting promoted raffle!" } }));
    }
  };

  const uploadImage = (e) => {
    if (!e[0]) return false;

    const formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("image", e[0]);

    Axios.post(`${process.env.REACT_APP_API}/api/imageUpload`, formData, config)
      .then(( { data } ) => {
        if (data.file) setImage(data.file);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg }
          });
        }
      });
  };
  
  return (
    <div>
      <div className="config-section">
        <h2 className="admin-subtitle">Homepage Promoted Raffles</h2>

        {showAddModal && (
          <div className="modal-container">
            <div className="modal-bg" onClick={() => setShowAddModal(false)}></div>
            <div className="modal">
              <h2>Select Raffle</h2>

              <div className="form-row">
                <label htmlFor="image">Image</label>
                <Dropzone
                  multiple={false}
                  accept={["image/webp", "image/jpeg", "image/jpg", "image/png", "image/gif"]}
                  onDropAccepted={(e) => uploadImage(e)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="input-dropzone">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                      </div>
                    </section>
                  )}
                </Dropzone>

                {image && (
                  <div
                    className="form-image"
                    style={{
                      backgroundImage: `url(${image})`,
                    }}
                  />
                )}
              </div>

              <div className="form-row">
                <div onClick={() => setShowRaffleFind(!showRaffleFind)}>
                  <label>Raffle</label>
                  <input
                    type="text"
                    value={raffle.name}
                    className="admin-input"
                    disabled={true}
                  />
                </div>

                {showRaffleFind && (
                  <div className="find-user-dropdown">
                    <input
                      type="search"
                      className="admin-input"
                      value={raffleQuery}
                      onChange={(e) => setRaffleQuery(e.target.value)}
                      autoFocus={true}
                    />

                    {loading ? (
                      <LoadingSpinner className="center" dark={true} />
                    ) : (
                      <ul>
                        {!raffleResults.length ? (
                          <li>No results found</li>
                        ) : (
                          raffleResults.map((raffle, i) => (
                            <li
                              onClick={() => {
                                setRaffle(raffle);
                                setShowRaffleFind(false);
                              }}
                              key={i}
                            >
                              {raffle.name}
                            </li>
                          ))
                        )}
                      </ul>
                    )}
                  </div>
                )}
              </div>

              <button onClick={addItem} className="btn btn-admin">
                Add
              </button>
            </div>
          </div>
        )}

        <table className="config-table">
          <thead>
            <tr>
              <th style={{ width: "250px" }}>Image</th>
              <th>Raffle</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {!promoted.length ? (
              <tr>
                <td colSpan="3" className="no-raffles">
                  No promoted raffles to show
                </td>
              </tr>
            ) : (
              promoted.map(({ image, raffle }, i) => (
                <tr key={i}>
                  <td>
                    <img src={`${image}`} alt="Raffle" className="raffle-table-image" />
                  </td>
                  <td>{raffle.name}</td>
                  <td className="right">
                    <button className="delete-btn" onClick={() => deleteItem(i)}>
                      <Trash2 />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        <button
          onClick={() => setShowAddModal(true)}
          className="btn btn-admin btn-small"
          style={{ marginTop: 10 }}
        >
          Add New
        </button>
      </div>
    </div>
  );
};

export default ConfigPromotedRaffles;
