import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import LoadingSpinner from "../../includes/LoadingSpinner";
import Slider from "react-slick";
import axios from "axios";
import useMediaQuery from "../../../utils/useMediaQuery";

const Winners = () => {
  const [loading, setLoading] = useState(true);
  const [winners, setWinners] = useState([]);

  const isMobile = useMediaQuery("(max-width: 1300px)");
  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/api/winners/all`).then(({ data }) => {
      setWinners(data.winners);
      setLoading(false);
    });
  }, []);

  if (!winners || winners.length === 0) {
    return null;
  }

  return (
    <div className="cta-slab">
      <div className="row">
        <div className="col">
          <h1 className="title no-bottom">Latest Winners</h1>
        </div>
      </div>

      <div className="row no-padding gutter">
        <div className="col">
          {loading ? (
            <LoadingSpinner className="center" dark />
          ) : (
            <Slider
              arrows={false}
              autoplay={true}
              autoplayScroll={3}
              autoplaySpeed={5000}
              className="testimonial-slider winners"
              overScan={3}
              slidesToShow={isMobile ? 1 : 3}
            >
              {winners && winners.length && winners.map(({ createdAt, image, instantWin, prizeOverride, raffle, ticketNumber, ticketsPurchased, user }, i) => (
                <div className="carousel-slide" key={i}>
                  <div className={`winner-card`}>
                    <Link to={`/c/${raffle.slug}`}>
                      <div className="winner-image" style={{ backgroundImage: `url("${image}")` }}>
                        <div className="winner-image-shade">
                          <div className="winner-details">
                            <p className="winner-title">{instantWin ? "Instantly Won by" : "Won by"}</p>
                            <p>{`${user.firstName} ${user.lastName.charAt(0)}.`}</p>
                            <div className="ticket">{ticketNumber}</div>
                          </div>
                          <div className="winner-details footer">
                            <h1>{moment(instantWin ? createdAt: raffle.endDate).format("Do MMMM YYYY")}</h1>
                          </div>
                        </div>
                      </div>
                      <div className="raffle-details">
                        <h3>{prizeOverride ?? raffle.name}</h3>
                        <div className="raffle-details-tickets">
                          <div className="price">
                              {raffle.price === 0
                                ? "FREE"
                                : raffle.price.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
                          </div>
                          <div className="tickets-bar-container">
                            <div className="bar">
                              <div
                                className="bar-fill"
                                style={{'--width': (ticketsPurchased.length / raffle.maxEntries) * 100 + "%" }}
                              ></div>
                            </div>
                            <div className="bar-indices">
                              <div className="left">{ticketsPurchased.length} sold</div>
                              <div className="right tickets-total">{raffle.maxEntries} total</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
};

export default Winners;
