import React, { useEffect, useState } from "react";
import { Check, ChevronDown, ChevronUp } from "react-feather";

const options = [
  {
    key: "value",
    title: "Prize Value"
  },
  {
    key: "status",
    title: "Status"
  },
  {
    key: "ticketNum",
    title: "Ticket Number"
  }
];

/*const DropdownSorter = ({ setSortBy, title }) => {
  const ref = useRef(null);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target) && status) {
        setTimeout(() => setStatus(false), 1);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => document.removeEventListener("click", handleClickOutside, true);
  }, [status]);

  return (
    <div style={{ position: "relative" }}>
      <button className="btn btn-blue" onClick={() => setStatus(!status)}>
        {title} {status ? <ChevronUp className="nav-chev" /> : <ChevronDown className="nav-chev" />}
      </button>
      <div className={`dropdown middle${status ? " open" : ""}`} ref={ref} style={status ? { display: "block" } : { display: "none" }}>
        <ul>
          {options.map(({ key, title }, i) => (
            <li className="subcategory" key={i}>
              <Link
                onClick={
                  () => {
                    setSortBy(key)
                    setStatus(!status);
                  }
                }
                to="#"
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};*/

const RaffleInstantWins = ({ raffle }) => {
  const [expanded, setExpanded] = useState(true);
  const [orderedInstantWins, setOrderedInstantWins] = useState([]);
  const [sortBy, setSortBy] = useState("ticketNum");

  //const update = (sortBy) => setSortBy(sortBy);

  useEffect(() => {
    const orderedInstantWins = raffle.instantWins ? [...raffle.instantWins] : [];
    const winners = new Map();

    raffle.instantWinners.forEach(({ ticketNum, user }) => winners.set(ticketNum, `${user.firstName} ${user.lastName}`));

    switch (sortBy) {
      case "status":
        orderedInstantWins.sort((a, b) => {
          if (a.status === b.status)
            return 0;

          return a.status && !b.status ? 1 : -1;
        });

        break;
      case "value":
        orderedInstantWins.sort((a, b) => {
          if (a.value === b.value)
            return 0;

          return a.value < b.value ? 1 : -1;
        });

        break;
      default:
        orderedInstantWins.sort((a, b) => a.ticketNum.localeCompare(b.ticketNum, undefined, { numeric: true }));
        break;
    }

    orderedInstantWins.forEach((instantWin) => {
      if (winners.has(instantWin.ticketNum)) {
        instantWin.status = true;
        instantWin.winner = winners.get(instantWin.ticketNum);
      } else {
        instantWin.status = false;
      }
    });
    
    setOrderedInstantWins(orderedInstantWins);
  }, [raffle, sortBy]);

  if (!orderedInstantWins || orderedInstantWins.length === 0)
    return null;

  return (
    <div className="cta-slab no-margin">
      <div className="row">
        <div className="col">
          <div className="instant-wins-prizes header">
            <div className="row no-padding">
              <div className="col">
                <h1>Instant Win Prizes</h1>
              </div>
              <div className="col margin-center right">
                <button className="tag clickable no-border" onClick={() => setExpanded(!expanded)}>
                  {expanded ? <ChevronUp height={40} width={40} /> : <ChevronDown height={40} width={40} />}
                </button>
              </div>
            </div>
          </div>

          <div className="instant-wins-prizes body" style={expanded ? { maxHeight: "1000px" } : { maxHeight: "10px", paddingBottom: "0", paddingTop: "0" }}>
            <div className="instant-wins-table">
              <div className="record">
                <div className="cell full">
                  <div className="form-row">
                    <label htmlFor="sortBy">Sort Prizes By:</label>
                    <select
                      className="admin-input-select instant"
                      name="sortBy"
                      id="sortBy"
                      onChange={(e) => setSortBy(e.target.value)}
                      defaultValue="ticketNum"
                    >
                      {options.map((option, i) => (
                        <option key={i} value={option.key}>
                          {option.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="record top">
                <div className="cell">
                  <h2>Pryze Name</h2>
                </div>
                <div className="cell ticket-number">
                  <h2>Ticket Number</h2>
                </div>
                <div className="cell status">
                  <h2>Status</h2>
                </div>
              </div>

              <div className="scrollable">
                {orderedInstantWins.map((instantWin, i) => (
                  <div className="record bg padded" key={i}>
                    <div className="cell">
                      <span>{instantWin.prize}</span>
                    </div>
                    <div className="cell ticket-number">
                      <span className="ticket-bubble">{instantWin.ticketNum}</span>
                    </div>
                    <div className="cell status">
                      {instantWin.status ? (
                        <span className="won">Won, {instantWin.winner} <Check height={20} width={20} /></span>
                      ) : (
                        <span className="not-won">Up For Grabs</span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaffleInstantWins;