import React, { useState, useContext, useEffect } from "react";
import { Edit2, Trash2 } from "react-feather";
import Dropzone from "react-dropzone";
import Axios from "axios";
import { AppContext } from "../../../../context/AppContext";

const ConfigHowItWorks = ({ howItWorks }) => {
  const { messages } = useContext(AppContext);

  const [editingItem, setEditingItem] = useState(-1);
  const [showAddModal, setShowAddModal] = useState(false);
  const [stages, setStages] = useState(howItWorks.stages || []);

  const [header, setHeader] = useState("");
  const [image, setImage] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    if (editingItem < 0)
      return;
    else if (editingItem >= stages.length) {
      setEditingItem(-1);
      return;
    }

    let stage = stages[editingItem];

    setHeader(stage.header);
    setImage(stage.image);
    setText(stage.text);
  }, [editingItem, stages]);

  useEffect(() => {
    setEditingItem(-1);
    setShowAddModal(false);
    sendUpdate(howItWorks._id, stages);
  }, [howItWorks._id, stages]);

  useEffect(() => {
    setHeader("");
    setImage("");
    setText("");
  }, [showAddModal]);

  const addItem = (e) => {
    e.preventDefault();

    if (!header || !image || !text)
      return messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "All fields required" },
      });
      
    if (editingItem > -1) {
      let stage = stages[editingItem];
      stage.header = header;
      stage.image = image;
      stage.text = text;

      let newStages = stages.filter((_, i) => i !== editingItem);

      newStages.splice(editingItem, 0, stage);
      setStages(newStages);
    } else {
      let newStage = { stage: stages.length > 0 ? (stages[stages.length - 1].stage + 1) : 1, header, image, text };
      setStages(stages.length > 0 ? [...stages, newStage] : [newStage]);
    }

    messages.dispatch({
      type: "send",
      payload: { type: "success", msg: "Successfully updated stages" }
    });
  };

  const deleteItem = (index) => {
    if (window.confirm("Are you sure you want to delete this stage?")) {
      setStages(stages.filter((_, i) => i !== index));
    }
  };

  const uploadImage = (e) => {
    if (!e[0]) return false;

    const formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("image", e[0]);

    Axios.post(`${process.env.REACT_APP_API}/api/imageUpload`, formData, config)
      .then(( { data } ) => {
        if (data.file) setImage(data.file);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg }
          });
        }
      });
  };

  const sendUpdate = (_id, stages) => Axios.post(`${process.env.REACT_APP_API}/api/config/${_id}`, { stages });

  return (
    <div>
      {showAddModal && (
        <div className="modal-container">
        <div className="modal-bg" onClick={() => setShowAddModal(false)}></div>
        <div className="modal">
          <h2>Add Stage #{stages.length + 1}</h2>

          <form onSubmit={addItem}>
            <div className="form-row">
              <label htmlFor="image">Image</label>
              <Dropzone
                multiple={false}
                accept={["image/jpeg", "image/jpg", "image/png", "image/gif"]}
                onDropAccepted={(e) => uploadImage(e)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="input-dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>

              {image && (
                <div
                  className="form-image"
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                />
              )}
            </div>

            <div className="form-row">
              <label htmlFor="Header">Header</label>
              <input
                type="text"
                id="header"
                className="admin-input"
                value={header}
                onChange={(e) => setHeader(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Text">Text</label>
              <textarea
                  id="text"
                  className="admin-input"
                  rows="5"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
            </div>

            <button type="submit" className="btn btn-admin">
              Add
            </button>
          </form>
        </div>
      </div>
      )}

      {editingItem > -1 && (
        <div className="modal-container">
        <div className="modal-bg" onClick={() => setEditingItem(-1)}></div>
        <div className="modal">
          <h2>Edit Stage #{editingItem + 1}</h2>

          <form onSubmit={addItem}>
            <div className="form-row">
              <label htmlFor="image">Image</label>
              <Dropzone
                multiple={false}
                accept={["image/jpeg", "image/jpg", "image/png", "image/gif"]}
                onDropAccepted={(e) => uploadImage(e)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="input-dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>

              {image && (
                <div
                  className="form-image"
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                />
              )}
            </div>

            <div className="form-row">
              <label htmlFor="Header">Header</label>
              <input
                type="text"
                id="header"
                className="admin-input"
                value={header}
                onChange={(e) => setHeader(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Text">Text</label>
              <textarea
                  id="text"
                  className="admin-input"
                  rows="5"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
            </div>

            <button type="submit" className="btn btn-admin">
              Edit
            </button>
          </form>
        </div>
      </div>
      )}

      <div className="config-section">
        <h2 className="admin-subtitle">Stages</h2>

        {/* Table */}
        <table className="config-table">
          <thead>
            <tr>
              <th>#</th>
              <th style={{ width: "250px" }}>Image</th>
              <th>Header</th>
              <th>Text</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {!stages.length ? (
              <tr>
                <td colSpan="5" className="no-raffles">
                  No stages to show
                </td>
              </tr>
            ) : (
              stages.map(({ stage, image, header, text }, i) => (
                <tr key={i}>
                  <td>{stage}</td>
                  <td>
                    <img src={`${image}`} alt="Raffle" className="raffle-table-image" />
                  </td>
                  <td>{header}</td>
                  <td>{text}</td>
                  <td className="right">
                    <button className="delete-btn" onClick={() => setEditingItem(i)}>
                      <Edit2 />
                    </button>
                    <button className="delete-btn" onClick={() => deleteItem(i)}>
                      <Trash2 />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        <button
          onClick={() => setShowAddModal(true)}
          className="btn btn-admin btn-small"
          style={{ marginTop: 10 }}
        >
          Add New
        </button>
      </div>
    </div>
  );
};

export default ConfigHowItWorks;