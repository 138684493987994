import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import LoadingSpinner from "../includes/LoadingSpinner";
import { Helmet } from "react-helmet";

const VerifyEmail = () => {
  const { email, code } = useParams();

  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_API}/api/auth/verify-account`, { email, code })
      .then(({ data }) => {
        localStorage.setItem("sessionToken", data.loginToken); // Set a login token

        // Physical redirect to success page
        window.location.replace("/auth/verify-email/success");
      })
      .catch(() => {
        window.location.replace("/auth/verify-email/error");
      });
  }, [email, code]);

  return (
    <div className="main-panel">
      <Helmet>
        <title>Pryze | Email Verification</title>
      </Helmet>
      
      <div className="row">
        <div className="col">
          <div className="center">
            <LoadingSpinner />
            <h1>Verifying your account...</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
