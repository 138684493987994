import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import FlashMessage from "./includes/FlashMessage";

import LandingHeader from "./includes/LandingHeader";
import MainFooter from "./includes/MainFooter";

const getFaviconEl = () => {
  return document.getElementById("favicon");
};

const LandingLayout = ({ children }) => {
  useEffect(() => {
    // Change favicon
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = "/favicon.ico";

    // Change meta viewport
    document
      .querySelector("meta[name=viewport]")
      .setAttribute(
        "content",
        "width=device-width, initial-scale=0.86, maximum-scale=0.86, minimum-scale=0.86"
      );
  }, []);

  return (
    <div>
      <Helmet>
        <title>Pryze | Coming Soon</title>
      </Helmet>
      
      {/*
      <div className="site-banner">
        <strong>Disclaimer:</strong> This site is currently a demo and none of the competitions you see are real.
      </div>
      */}

      <FlashMessage />
      <LandingHeader />
      <div className="app-main-content">{children}</div>
      <MainFooter landing={true} />
    </div>
  );
};

export default LandingLayout;
