import jwt from "jsonwebtoken";
import axios from "axios";

export const tokenValid = () => {
  const token = localStorage.getItem("sessionToken");

  if (token) {
    const decodedToken = jwt.decode(token);
    if (!decodedToken) return false;
    
    const expiry = decodedToken.exp;
    const now = new Date();

    // If token is expired
    if (now.getTime() > expiry * 1000) return false;

    // Set headers
    axios.defaults.headers.common.authorization = `Bearer ${token}`;
    return decodedToken;
  } else if (axios.defaults.headers.common.authorization) {
    delete axios.defaults.headers.common.authorization;
    return false;
  }
};
