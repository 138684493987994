import React from "react";
import moment from "moment";

const SingleReferral = ({ referral }) => {
  const progress = referral.progress > referral.required ? referral.required : referral.progress;
  const percentage = Math.round((progress / referral.required) * 100);

  const currencyString = (amount) => amount.toLocaleString("en-GB", { style: "currency", currency: "GBP" });

  return (
    <>
      <tr>
        <td>
          {referral.referrer.firstName} {referral.referrer.lastName}
          <br />
          {referral.referrer.email}
        </td>
        <td>
          {referral.user.firstName} {referral.user.lastName}
          <br />
          {referral.user.email}
        </td>
        <td>
          {currencyString(progress)}/{currencyString(referral.required)} ({percentage}%)
        </td>
        <td>
          {currencyString(referral.bonus)}
        </td>
        <td>
          {moment(referral.createdAt).format("DD/MM/YYYY (HH:mm)")}
        </td>
      </tr>
    </>
  )
};

export default SingleReferral;