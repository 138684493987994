import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import TicketItem from "./includes/TicketItem";
import Axios from "axios";
import SortBySwitcher from "./includes/SortBySwitcher";
import { AppContext } from "../../../../context/AppContext";

const MyTickets = () => {
  const { user } = useContext(AppContext);
  
  const [sortBy, setSortBy] = useState("current");
  const [currentTime, setCurrentTime] = useState(moment());
  const [raffles, setRaffles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/raffleTickets/purchased`).then(({ data }) => {
      const raffles = [];

      data.raffles.forEach((raffle) => {
        let canReveal = false;
        const raffleData = raffle.raffle[0];

        if (raffleData && raffleData.instantWins && raffleData.instantWins.length > 0)
          raffle.tickets.forEach((ticket) => {
            if ("revealed" in ticket && !ticket.revealed)
              canReveal = true;
          });

        raffle.canReveal = canReveal;
        raffles.push(raffle);
      });

      setRaffles(raffles);
      setLoading(false);
    });
  }, [sortBy]);

  // Keep a track of current time for countdown timers
  useEffect(() => {
    if (!raffles.length) return;

    const intervalID = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(intervalID);
  }, [raffles.length]);

  const filteredTickets = raffles.filter(({ raffle }) =>
    sortBy === "current"
      ? moment(raffle[0].endDate).isAfter(moment())
      : moment(raffle[0].endDate).isBefore(moment())
  );

  return (
    <div style={{ width: "100%" }}>
      <div className="row no-padding user-account-main-header">
        <div className="col">
          <h1>My Tickets</h1>
        </div>
        <div className="col right">
          <SortBySwitcher
            className="tickets-sorter"
            sortOptions={[
              { sort: "current", name: "Current Tickets" },
              { sort: "past", name: "Past Tickets" },
            ]}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </div>
      </div>

      <div>
        {loading ? (
          <LoadingSpinner className="center" dark={true} />
        ) : !filteredTickets.length ? (
          <p>There are no tickets to show</p>
        ) : (
          filteredTickets.map(({ canReveal, raffle, tickets }, i) => (
            <TicketItem key={i} admin={user.state.admin} canReveal={canReveal} currentTime={currentTime} raffle={raffle[0]} tickets={tickets} />
          ))
        )}
      </div>
    </div>
  );
};

export default MyTickets;
