import axios from "axios";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { AppContext } from "../../context/AppContext";
import LoadingSpinner from "../includes/LoadingSpinner";

const CompleteRegistration = () => {
  const { user, messages } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [overEighteen, setOverEighteen] = useState(false);
  const [terms, setTerms] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  
  const checkboxes = [
    {
      id: "over-18",
      title: "I am over 18 and a UK resident",
      checked: overEighteen,
      onChange: (e) => setOverEighteen(e.target.checked),
    },
    {
      id: "terms",
      title: (
        <>
          <span>I accept the </span>
          <a href="/p/terms-and-conditions" target="_blank">
            terms
          </a>
          <span> and </span>
          <a href="/p/privacy" target="_blank">
            privacy policy
          </a>
        </>
      ),
      checked: terms,
      onChange: (e) => setTerms(e.target.checked),
    },
    {
      id: "newsletter",
      title: "Sign up for offers, news and more",
      checked: newsletter,
      onChange: (e) => setNewsletter(e.target.checked),
    },
  ];

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      axios.post(`${process.env.REACT_APP_API}/api/auth`, { confirmationRequired: false, newsletter })
        .then(() => {
          user.dispatch({
            type: "update",
            payload: { confirmationRequired: false, newsletter }
          });

          setLoading(false);
        }).catch((err) => {
          setLoading(false);

          if (err.response.data && err.response.data.msg)
            messages.dispatch({
              type: "send",
              payload: { type: "error", msg: err.response.data.msg }
            });
        });
    } catch (error) {
      setLoading(false);

      let msg = "Something went wrong.";
      if (error.response && error.response.data) msg = error.response.data.msg;

      messages.dispatch({
        type: "send",
        payload: { type: "error", msg },
      });
    }
  };

  return (
    <div className="main-panel">
      <Helmet>
        <title>Pryze | Complete Registration</title>
      </Helmet>

      <div className="row center-cols">
        <div className="col-5">
          <div className="slate">
            <h1 className="title">Complete Registration</h1>

            <form onSubmit={(e) => submit(e)}>
              {checkboxes.map(({ id, title, checked, onChange }) => (
                <div className="form-row" key={id}>
                  <div className="checkbox">
                    <input type="checkbox" id={id} checked={checked} onChange={onChange} />
                    <label htmlFor={id}>{title}</label>
                  </div>
                </div>
              ))}

              <button
                type="submit"
                className="btn form-btn btn-blue"
                disabled={loading || !terms || !overEighteen}
              >
                {!loading ? "Complete Registration" : <LoadingSpinner />}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteRegistration;