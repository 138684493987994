import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";
import { Copy } from "react-feather";

import LoadingSpinner from "../../../includes/LoadingSpinner";
import axios from "axios";

const Referrals = () => {
  const { config, messages, user } = useContext(AppContext);
  
  //const [currentReferral, setCurrentReferral] = useState();
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [referrals, setReferrals] = useState([]);
  const [reward, setReward] = useState("£0.00");
  const [threshold, setThreshold] = useState("£0.00");

  const copyUrl = async () => {
    try {
      if (user.state.referralCode && user.state.referralCode !== "") {
        let matches = window.location.href.match(/^(https?:\/\/)([^/?#]+)(?:[/?#]|$)/i);
        let domain = matches && matches[2];

        if (domain) {
          await navigator.clipboard.writeText(matches[1] + domain + "/r/" + user.state.referralCode);
          messages.dispatch({ type: "send", payload: { type: "success", msg: "Successfully copied referral URL to clipboard!" } });
        } else {
          messages.dispatch({ type: "send", payload: { type: "error", msg: "Failed to copy your referral URL!" } });
        }
      } else {
        messages.dispatch({ type: "send", payload: { type: "error", msg: "No referral code found!" } });
      }
    } catch (err) {
      console.log("Failed to copy: ", err);
    }
  };

  /*
  const editCode = (code) => {
    axios.post(`${process.env.REACT_APP_API}/api/auth`, { newsletter: user.state.newsletter, referralCode: code })
      .then(() => {
        user.dispatch({
          type: "update",
          payload: { referralCode: code }
        });

        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: `You have set your referral code to ${code}!` }
        });
      }).catch((err) => {
        if (err.response.data && err.response.data.msg)
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg }
          });
      });
  };
  */

  useEffect(() => {
    Promise.all([
      axios.get(`${process.env.REACT_APP_API}/api/referrals`).then(({ data }) => {
        setReferrals(data.referrals);
      }),
      //axios.get(`${process.env.REACT_APP_API}/api/referrals/current`).then(({ data }) => {
      //  setCurrentReferral(data.referral);
      //})
    ]).then(() => setLoadingPage(false));
  }, []);

  useEffect(() => {
    if (!config || !config.state) {
      return;
    }

    const referrals = config.state.find(({ type }) => type === "referrals");

    if (referrals && referrals.options) {
      setReward(referrals.options.bonus.toLocaleString("en-GB", { style: "currency", currency: "GBP" }));
      setThreshold(referrals.options.required.toLocaleString("en-GB", { style: "currency", currency: "GBP" }));
    }
  }, [config, config.state]);

  useEffect(() => {
    const getLink = (code) => {
      let matches = window.location.href.match(/^(https?:\/\/)([^/?#]+)(?:[/?#]|$)/i);
      let domain = matches && matches[2];

      if (domain) {
        return matches[1] + domain + "/r/" + code;
      }

      return code;
    };

    const newCode = async () => {
      let code = Math.random().toString(16).substring(2, 8);
  
      await axios.post(`${process.env.REACT_APP_API}/api/auth`, { newsletter: user.state.newsletter, referralCode: code })
        .then(() => {
          user.dispatch({
            type: "update",
            payload: { referralCode: code }
          });
  
          messages.dispatch({
            type: "send",
            payload: { type: "success", msg: "A new referral code has been generated!" }
          });
        }).catch((err) => {
          code = null;
  
          setTimeout(() => {
            if (!user.state.referralCode)
              newCode();
          }, 5000);
        });
  
      return code;
    };

    if (!user.state.referralCode || user.state.referralCode === "") {
      newCode().then((code) => {
        setLink(getLink(code));
        setLoading(false);
      });
    } else {
      setLink(getLink(user.state.referralCode));
      setLoading(false);
    }
  }, [messages, user, user.state.referralCode]);

  return (
    loadingPage ? (
      <>
        <div className="row no-padding user-account-main-header">
          <div className="col">
            <h1>Referrals</h1>
          </div>
        </div>

        <div className="row no-padding">
          <div className="col">
            <LoadingSpinner className="center" dark={true} />
          </div>
        </div>
      </>
    ) : (
      <div style={{ width: "100%" }}>
        <div className="row no-padding user-account-main-header">
          <div className="col">
            <h1>Referrals</h1>
          </div>
        </div>

        <div className="row no-padding">
          <div className="col">
            <p className={"account-text" + (user.state.admin ? " admin-text" : "")}>You (and a friend) will both receive {reward} in promotional credit when they spend {threshold} for their first time on any of our competitions!</p>
          </div>
        </div>

        {!loading && user.state.referralCode ? (
          <div className="row gutter no-padding">
            <div className="col-4">
              <div className="user-account-referrals">
                <h2>Your referral code:</h2>
                <div
                  className="link-box"
                  title="Copy URL"
                  onClick={(e) => copyUrl()}
                >
                  <span className="code">{user.state.referralCode}</span>
                  <span className="link">
                    {link + " "}<Copy size={12.5} style={{ color: "grey", marginLeft: "5px" }} />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="user-account-referrals details">
                <table>
                  <thead>
                    <tr>
                      <th>Referral</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referrals && referrals.length > 0 && (
                      referrals.map((referral, i) => (
                        <tr key={i}>
                          <td>{referral.user.firstName + " " + referral.user.lastName}</td>
                          {referral.complete ? (
                            <td className="complete">💰 {referral.bonus.toLocaleString("en-GB", { style: "currency", currency: "GBP" })} Earned</td>    
                          ) : (
                            <td>{referral.bonus.toLocaleString("en-GB", { style: "currency", currency: "GBP" })} Pending</td>
                          )}
                        </tr>
                      ))
                    )}
                    <tr>
                      <td className="invite"><Link to="/referrals">Invite a friend</Link></td>
                      <td>Earn {reward}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <LoadingSpinner style={{ textAlign: "center" }} />
        )}
      </div>
    )
  );
};

export default Referrals;