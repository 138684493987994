import React, { useState, useEffect } from "react";
import Axios from "axios";
import LoadingSpinner from "../../includes/LoadingSpinner";
import Winner from "./includes/Winner";
import useMediaQuery from "../../../utils/useMediaQuery";
import { Helmet } from "react-helmet";

const WinnersPage = () => {
  const [loading, setLoading] = useState(true);
  const [winners, setWinners] = useState([]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/winners/all`).then(({ data }) => {
      setWinners(data.winners);
      setLoading(false);
    });    
  }, []);

  const isMobile = useMediaQuery("(max-width: 1300px)");

  return (
    <div className="cta-slab curve">
      <Helmet>
        <title>Pryze | Winners</title>
      </Helmet>
      
      <div className="row">
        <div className="col">
          <h1 className="title">Meet Our Winners</h1>
        </div>
      </div>
      <div className="row no-padding">
        {loading ? (
          <div className="col">
            <LoadingSpinner className="center" dark />
          </div>
        ) :
        winners.length ? (
          <div className="row no-padding gutter">
            {winners.map((winner, i) => (
              <div key={i} className="col-4">
                <Winner className={(!isMobile && (i + 4) % 3 === 1) ? "float-right" : (!isMobile && (i + 4) % 3 === 0) ? "float-left" : ""} winner={winner} />
              </div>
            ))}
            {[winners % 3].fill().map((_, i) => (
              <div className="col-4" key={i} />
            ))}
          </div>
        ) : (
          <div className="row">
            <h2 className="no-winners">
              😮 Woah! You're early, we're just getting started here at Pryze. Our first draw hasn't taken place yet. If you haven't already, check out our live competitions by clicking the button below 👀
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default WinnersPage;