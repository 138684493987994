import Axios from "axios";
import { useContext, useState } from "react";
import { AppContext } from "../../../../context/AppContext";
import LoadingSpinner from "../../../includes/LoadingSpinner";

const SetAddresses = ({ cards, setStep }) => {
  const { user, messages } = useContext(AppContext);

  const [submitting, setSubmitting] = useState(false);
  const [phone, setPhone] = useState(user.state.phone);
  const [addresses, setAddresses] = useState(user.state.addresses);
  const [duplicateShippingAddr, setDuplicateShippingAddr] = useState(
    user.state.duplicateShippingAddr
  );

  const updateAddress = (key, addrType, val) => {
    const updatedAddresses = addresses;
    updatedAddresses[addrType][key] = val;

    setAddresses({ ...updatedAddresses });
  };

  const isInvalid = () => {
    if (!phone) return true;

    if (!addresses.shipping.addressLine1) return true;
    if (!addresses.shipping.city) return true;
    if (!addresses.shipping.county) return true;
    if (!addresses.shipping.postCode) return true;

    if (!duplicateShippingAddr) {
      if (!addresses.billing.addressLine1) return true;
      if (!addresses.billing.city) return true;
      if (!addresses.billing.county) return true;
      if (!addresses.billing.postCode) return true;
    }
  };

  const onSubmit = async () => {
    setSubmitting(true);

    try {
      await Axios.post(`${process.env.REACT_APP_API}/api/auth`, {
        addresses: {
          shipping: addresses.shipping,
          billing: !duplicateShippingAddr
            ? addresses.billing
            : {
                addressLine1: addresses.shipping.addressLine1,
                addressLine2: addresses.shipping.addressLine2,
                city: addresses.shipping.city,
                county: addresses.shipping.county,
                postCode: addresses.shipping.postCode,
              },
        },
        duplicateShippingAddr,
        phone,
      });

      user.dispatch({
        type: "update",
        payload: { addresses, duplicateShippingAddr },
      });

      return setStep(cards ? "card" : "payment");
    } catch (err) {
      if (err.response && err.response.data) {
        messages.dispatch({
          type: "send",
          payload: { type: "error", msg: err.response.data.msg },
        });
      }
      setSubmitting(false);
    }
  };

  return (
    <div className="row gutter no-padding center-cols">
      <div className="col-8">
        <div className="cart-main cart-items">
          <div className="row gutter checkout-summary">
            {["shipping", "billing"].map((addrType) => (
              <div className="col-6" key={addrType}>
                <div>
                  <h3 className="cart-items-address-title">{addrType} Address</h3>

                  {(addrType === "shipping" ||
                    (addrType === "billing" && !duplicateShippingAddr)) && (
                    <>
                      <div className="form-row">
                        <label className="required" htmlFor={`${addrType}addressLine1`}>Address Line 1</label>
                        <input
                          type="text"
                          id={`${addrType}addressLine1`}
                          value={addresses[addrType].addressLine1}
                          onChange={(e) => updateAddress("addressLine1", addrType, e.target.value)}
                          className="input"
                        />
                      </div>

                      <div className="form-row">
                        <label htmlFor={`${addrType}addressLine2`}>Address Line 2</label>
                        <input
                          type="text"
                          id={`${addrType}addressLine2`}
                          value={addresses[addrType].addressLine2}
                          onChange={(e) => updateAddress("addressLine2", addrType, e.target.value)}
                          className="input"
                        />
                      </div>

                      <div className="form-row">
                        <label className="required" htmlFor={`${addrType}city`}>City</label>
                        <input
                          type="text"
                          id={`${addrType}city`}
                          value={addresses[addrType].city}
                          onChange={(e) => updateAddress("city", addrType, e.target.value)}
                          className="input"
                        />
                      </div>

                      <div className="form-row">
                        <label className="required" htmlFor={`${addrType}county`}>County</label>
                        <input
                          type="text"
                          id={`${addrType}county`}
                          value={addresses[addrType].county}
                          onChange={(e) => updateAddress("county", addrType, e.target.value)}
                          className="input"
                        />
                      </div>

                      <div className="form-row">
                        <label className="required" htmlFor={`${addrType}postCode`}>Post Code</label>
                        <input
                          type="text"
                          id={`${addrType}postCode`}
                          value={addresses[addrType].postCode}
                          onChange={(e) => updateAddress("postCode", addrType, e.target.value)}
                          className="input"
                        />
                      </div>
                    </>
                  )}

                  {addrType === "shipping" && (
                    <div className="form-row">
                      <label className="required" htmlFor="phone">Phone</label>
                      <input
                        type="text"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="input"
                      />
                    </div>
                  )}

                  {addrType === "billing" && (
                    <div className="row no-padding edit-details">
                      <input
                        type="checkbox"
                        checked={duplicateShippingAddr}
                        onChange={(e) => setDuplicateShippingAddr(e.target.checked)}
                        id="same-as-shipping-addr"
                        name="same-as-shipping-addr"
                      />
                      <label htmlFor="same-as-shipping-addr">Same as shipping address</label>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="row">
            <button
              className="btn btn-blue form-btn"
              disabled={isInvalid() || submitting}
              onClick={onSubmit}
            >
              {submitting ? <LoadingSpinner /> : "Continue"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetAddresses;
