import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const RaffleInfo = ({
  id,
  image,
  originalPrice,
  onSale,
  price,
  contributions,
  purchases,
  maxEntries,
  startDate,
  endDate,
}) => {
  return (
    <div className="admin-slate">
      <div className="row no-padding">
        <div className="col">
          <div
            className="raffle-img"
            style={{
              backgroundImage: image ? `url(${image.path})` : "none",
            }}
          ></div>
        </div>
        <div className="col raffle-info-item">
          <h3>Price</h3>
          <p>
            {onSale && (
              <sup>
                <s>
                  {originalPrice.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
                </s>
              </sup>
            )}{" "}
            {price.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
          </p>
        </div>
        <div className="col raffle-info-item">
          <Link to={`/admin/raffles/${id}/sales`}>
            <h3>Entrants</h3>
            <p>
              {purchases}/{maxEntries}
            </p>
          </Link>
        </div>
        <div className="col raffle-info-item">
          <Link to={`/admin/raffles/${id}/sales`}>
            <h3>Sales</h3>
            <p>
              {(purchases * price).toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
            </p>
          </Link>
        </div>
        <div className="col raffle-info-item">
          <h3>Contributions</h3>
          <p>
              {contributions.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
          </p>
        </div>
        <div className="col raffle-info-item">
          <h3>Start Date</h3>
          <p>{moment(startDate).format("DD/MM/YYYY HH:mm")}</p>
        </div>
        <div className="col raffle-info-item">
          <h3>End Date</h3>
          <p>{moment(endDate).format("DD/MM/YYYY HH:mm")}</p>
        </div>
      </div>
    </div>
  );
};

export default RaffleInfo;
