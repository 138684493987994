import Axios from "axios";
import React, { useContext, useState } from "react";
import { ArrowLeft, Power } from "react-feather";
import PinInput from "react-pin-input";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../../../../context/AppContext";
import LoadingSpinner from "../../../../includes/LoadingSpinner";

const TwoFactorAuth = () => {
  const { user, messages } = useContext(AppContext);
  const history = useHistory();

  const [pin, setPin] = useState(null);
  
  const [setup, setSetup] = useState(false);
  const [qrCode, setQrCode] = useState(false);
  const [fallbackCode, setFallbackCode] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [processing, setProcessing] = useState(false);

  const onRemove2FA = async () => {
    try {
      await Axios.post(`${process.env.REACT_APP_API}/api/auth/disable-2fa`);

      user.dispatch({
        type: "update",
        payload: { twoFacAuth: false },
      });
      messages.dispatch({
        type: "send",
        payload: { type: "success", msg: "Successfully turned off two-factor authentication" },
      });

      history.push("/account/settings");
    } catch (error) {
      messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "Could not turn off 2FA" },
      });
    }
  };

  const onClickSetup = async () => {
    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_API}/api/auth/setup-2fa`);

      setSetup(true);
      setQrCode(data.qrCode);
      setFallbackCode(data.fallbackCode);
    } catch (error) {}
  };

  const onCompleteConfirmationCode = (code) => {
    setProcessing(true);

    Axios.post(`${process.env.REACT_APP_API}/api/auth/2fa-setup-verification`, {
      confirmationCode: code,
    }).then(() => {
      user.dispatch({
        type: "update",
        payload: { twoFacAuth: true },
      });
      messages.dispatch({
        type: "send",
        payload: { type: "success", msg: "Successfully set up two-factor authentication" },
      });

      history.push("/account/settings");
    }).catch((error) => {
      if (pin) {
        setConfirmationCode("");
        pin.clear();
      }

      messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "Token is incorrect" },
      });

      setProcessing(false);
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="user-account-main-header">
        <h1>
          <Link to="/account/settings" className="back-btn">
            <ArrowLeft />
          </Link>
          Two-Factor Authentication
        </h1>
      </div>

      <div className="two-fac">
        <div className="md-text center" style={{ maxWidth: "850px", margin: "0 auto" }}>
          <p>
            Two-factor authentication helps protect your account by requiring a login code via an
            authentication app in addition to your password when you log in. Setup couldn't be more
            simple — just click the button below to get started. If you have your phone on hand, the
            process will only take about 30 seconds.
          </p>
        </div>

        <div className="center">
          {setup ? (
            <div className="tfac-setup">
              <div className="qrcode-container">
                <div className="qrcode-img">
                  <img src={qrCode} alt="" />
                </div>
                <div className="qrcode-text">
                  <p>
                    Manual entry:
                    <div className="fb-code">{fallbackCode}</div>
                  </p>
                </div>
              </div>

              <form onSubmit={(e) => {
                e.preventDefault();
                onCompleteConfirmationCode(confirmationCode);
              }}>
                <div className="response-code">
                  <p>Next, enter the confirmation code shown on the app:</p>
                  <PinInput
                    ref={setPin}
                    focus={true}
                    length={6}
                    type="numeric"
                    inputMode="number"
                    className="pin-input"
                    onChange={setConfirmationCode}
                    onComplete={onCompleteConfirmationCode}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                  <button className="btn btn-blue" type="submit" disabled={processing}>
                    {processing ? <LoadingSpinner /> : "Confirm"}
                  </button>
                </div>
              </form>
            </div>
          ) : user.state.twoFacAuth ? (
            <button className="btn btn-blue btn-large" onClick={onRemove2FA}>
              <Power /> Turn off 2FA
            </button>
          ) : (
            <button className="btn btn-blue btn-large" onClick={onClickSetup}>
              <Power /> Turn on
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuth;
