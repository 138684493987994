import Axios from "axios";
import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import LoadingSpinner from "../../includes/LoadingSpinner";
import AddLiveDrawModal from "./includes/AddLiveDrawModal";
import LiveDraw from "./includes/LiveDraw";

const LiveDrawsPage = () => {
  const [limit] = useState(10);

  const [loading, setLoading] = useState(true);
  const [liveDraws, setLiveDraws] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [showModal, setShowModal] = useState(false);

  const next = () => setPage(page + 1);
  const prev = () => setPage(page - 1);

  const reload = (limit, page) => {
    setLoading(true);

    Axios.get(`${process.env.REACT_APP_API}/api/liveDraws${page > 0 ? `/limit/${limit}/skip/${page * limit}` : ""}`)
      .then(({ data }) => {
        setLiveDraws(data.liveDraws);
        setTotalPages(Math.ceil(data.total / limit));

        setLoading(false);
      });
  };

  useEffect(() => reload(limit, page), [limit, page]);

  return (
    <>
      {showModal && (
        <AddLiveDrawModal closeModal={() => setShowModal(false)} drawAdded={() => reload(limit, page)} />
      )}

      <div className="row">
        <div className="col">
          <div className="all-raffles-header">
            <div className="row no-padding">
              <div className="col">
                <h1 className="admin-title">Live Draws</h1>
              </div>
              <div className="col right">
                <button className="btn btn-admin" onClick={() => setShowModal(true)}>
                  Add Draw
                </button>
              </div>
            </div>
          </div>

          {loading ? (
            <LoadingSpinner dark={true} className="center" />
          ) : (
            <table className="config-table">
              <thead>
                <tr>
                  <th style={{ width: 30 }}></th>
                  <th>Date</th>
                  <th>Title</th>
                  <th>URL</th>
                  <th style={{ width: 120 }}></th>
                </tr>
              </thead>
              <tbody>
                {!liveDraws.length ? (
                  <tr>
                    <td colSpan="5" className="no-orders">
                      No live draws to show
                    </td>
                  </tr>
                ) : (
                  liveDraws.map((liveDraw, i) => (
                    <LiveDraw key={i} liveDraw={liveDraw} update={() => reload(limit, page)} />
                  ))
                )}
              </tbody>
            </table>
          )}

          <div className="raffles-paginate">
            <button onClick={prev} disabled={page === 0} className="btn btn-admin btn-paginate">
              <ChevronLeft />
            </button>
            <button
              onClick={next}
              disabled={page + 1 >= totalPages}
              className="btn btn-admin btn-paginate"
            >
              <ChevronRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveDrawsPage;