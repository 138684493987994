import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";

import { AppContext } from "../../../../context/AppContext";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import { useHistory, Link } from "react-router-dom";
//import RaffleSelectAmount from "./RaffleSelectAmount";
import RaffleSelectAnswer from "./RaffleSelectAnswer";

import { Minus, Plus } from "react-feather";
import useMediaQuery from "../../../../utils/useMediaQuery";

const POLL_FOR_UNAVAILABLE_TICKETS_IN_SECONDS = 10;

const validateSetAmount = (currentUserTotal, newAmount, raffle, unavailableTickets) => {
  let maxTickets = Math.min(raffle.maxEntriesPerPerson, (raffle.maxEntries - unavailableTickets.length), (raffle.maxEntriesPerPerson - currentUserTotal));

  if (newAmount < 0)
    return 0;
  if (newAmount > maxTickets)
    return maxTickets;
  
  return newAmount;
};

const TicketSelector = ({ raffle }) => {
  const { _id, slug, instantWins, luckyDipDefault, ticketMatrix } = raffle;
  const { useLetters, toLetter, toLetterKey, ticketsPerLetter } = ticketMatrix;

  const hasInstantWins = (instantWins && instantWins.length > 0);
  const letterRange = useLetters
    ? "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").slice(0, toLetterKey + 1)
    : ["A"]; // array of letters
  const numberRange = [...Array(ticketsPerLetter).keys()];

  const isMobile = useMediaQuery("(max-width: 1300px)");

  const history = useHistory();
  const selector = document.getElementById("ticket-selector");

  const { user, messages } = useContext(AppContext);

  const [addingToCart, setAddingToCart] = useState(false);
  const [addToCartTop, setAddToCartTop] = useState(0);
  const [amount, setAmount] = useState(0);
  const [amountText, setAmountText] = useState("0");
  const [boxWidth, setBoxWidth] = useState(0);
  const [canAutoSelect, setCanAutoSelect] = useState(false);
  const [currentUserTotal, setCurrentUserTotal] = useState(0);
  const [currentTotalPrice, setCurrentTotalPrice] = useState(0);
  const [selected, setSelected] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState("A");
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [unavailableTickets, setUnavailableTickets] = useState([]);
  const [answeringQuestion, setAnsweringQuestion] = useState(false);
  const [selectingAmount, setSelectingAmount] = useState(false);

  useEffect(() => {
    let poll = undefined;

    if (user.state && user.state._id) {
      const getCurrentUserTotal = () => {
        Axios.get(`${process.env.REACT_APP_API}/api/raffleTickets/raffle/${_id}/${user.state._id}/total`).then(
          ({ data }) => {
            setCurrentUserTotal(data.total);
            setCanAutoSelect(true);
          }
        ).catch(() => setCurrentUserTotal(0));
      };

      const getUnavailableTickets = () => {
        Axios.get(`${process.env.REACT_APP_API}/api/raffleTickets/raffle/${_id}/unavailable`).then(
          ({ data }) => {
            const unavailableNumbers = [];

            data.unavailableNumbers.forEach(({ticketNum}) => {
              unavailableNumbers.push(ticketNum);
            });

            setUnavailableTickets(unavailableNumbers);
          }
        ).catch(() => setUnavailableTickets([]));
      };

      getCurrentUserTotal();
      getUnavailableTickets();

      poll = setInterval(
        () => {
          getCurrentUserTotal();
          getUnavailableTickets();
        },
        POLL_FOR_UNAVAILABLE_TICKETS_IN_SECONDS * 1000
      );
    }

    const timeout = setTimeout(() => {
      const addToCart = document.getElementById("add-to-cart");

      if (addToCart) {
        setAddToCartTop((window.scrollY + addToCart.getBoundingClientRect().top + 40) - window.innerHeight);
        setBoxWidth(addToCart.offsetWidth);
      }
    }, 1000);

    return () => {
      if (poll)
        clearInterval(poll);
      
      clearTimeout(timeout);
    };
  }, [_id, user.state._id]); // eslint-disable-line

  useEffect(() => {
    const onScroll = () => {
      let addToCart = document.getElementById("add-to-cart");

      if (!addToCart)
        return;

      if (!answeringQuestion && selectedTickets.length > 0) {
        let ticketSelector = document.getElementById("ticket-selector");

        if (ticketSelector) {
          let ticketPos = window.scrollY + ticketSelector.getBoundingClientRect().top - 350;

          if (addToCartTop > window.scrollY && ticketPos < window.scrollY) {
            addToCart.classList.add("sticky");
            addToCart.style.width = boxWidth + "px";
          } else {
            addToCart.classList.remove("sticky");
            addToCart.style.width = null;
          }
        }
      } else {
        addToCart.classList.remove("sticky");
        addToCart.style.width = null;
      }
    };

    onScroll();
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [addToCartTop, answeringQuestion, boxWidth, selectedTickets]);

  useEffect(() => {
    setSelectedLetter("A");
    setSelectedTickets([]);
  }, [raffle]);

  useEffect(() => {
    if (!canAutoSelect || selected) return;
    else if (!raffle.instantWins || raffle.instantWins.length === 0 || (raffle.luckyDipDefault || 0) === 0) return;

    setSelected(true);

    const newAmount = validateSetAmount(currentUserTotal, amount + raffle.luckyDipDefault, raffle, unavailableTickets);

    if (newAmount > amount) {
      setAmount(newAmount);
      setAmountText(`${newAmount}`);

      const tickets = [];
      for (let i = 0; i < newAmount - amount; i++) tickets.push("?");

      setSelectedTickets(tickets);
    }
  }, [amount, canAutoSelect, currentUserTotal, raffle, selected, unavailableTickets]);

  useEffect(() => {
    setCurrentTotalPrice(raffle.price * selectedTickets.length);
  }, [raffle, raffle.price, selectedTickets]);

  const addToCart = (ticketNums, questionAnswer) => {
    setAddingToCart(true);

    Axios.post(`${process.env.REACT_APP_API}/api/raffleTickets/raffle/${_id}/reserve`, {
      ticketNums,
      questionAnswer,
    })
      .then(({ data }) => {
        user.dispatch({ type: "addToCart", payload: data.tickets });
        history.push("/basket");
      })
      .catch((err) => {
        setAddingToCart(false);
        if (err.response && typeof err.response.data.msg === "string")
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg },
          });
      });
  };

  const addToCartHidden = (amount, questionAnswer) => {
    setAddingToCart(true);

    Axios.post(`${process.env.REACT_APP_API}/api/raffleTickets/raffle/${_id}/reserve/${amount}`, {
      questionAnswer,
    }).then(({ data }) => {
      user.dispatch({ type: "addToCart", payload: data.tickets });
      history.push("/basket");
    }).catch((err) => {
      setAddingToCart(false);

      if (err.response && typeof err.response.data.msg === "string")
        messages.dispatch({ type: "send", payload: { type: "error", msg: err.response.data.msg } });
    });
  };

  const checkAmount = (amountText, hasInstantWins = false) => {
    if (amountText === "" || isNaN(amountText)) {
      setAmountText(amountText);
      return;
    }

    let allTickets = new Set();

    const minLetterBound = useLetters ? "A".charCodeAt(0) : false;
    const maxLetterBound = useLetters ? toLetter.charCodeAt(0) : false;
    const minNumberBound = 1;
    const maxNumberBound = ticketsPerLetter;

    if (minLetterBound && maxLetterBound)
      for (let i = minLetterBound; i <= maxLetterBound; i++) {
        for (let j = minNumberBound; j <= maxNumberBound; j++) {
          allTickets.add(String.fromCharCode(i) + j);
        }
      }
    else
      for (let i = minNumberBound; i <= maxNumberBound; i++) {
        allTickets.add(i.toString());
      }

    selectedTickets.forEach(ticket => allTickets.delete(ticket));
    unavailableTickets.forEach(ticket => allTickets.delete(ticket));

    allTickets = Array.from(allTickets);

    let amount = parseInt(amountText);
    let tickets = [...selectedTickets];
    let validated = validateSetAmount(currentUserTotal, amount, raffle, unavailableTickets);

    setAmount(validated);
    setAmountText(`${validated}`);

    if (validated > selectedTickets.length) {
      let extra = validated - selectedTickets.length;

      for (let i = 0; i < extra; i++)
        if (hasInstantWins)
          tickets.push("?");
        else {
          let j = Math.floor(Math.random() * allTickets.length);

          tickets.push(allTickets[j]);
          allTickets.splice(j, 1);
        }

      setSelectedTickets(tickets);
    } else if (validated < selectedTickets.length) {
      let extra = selectedTickets.length - validated;

      for (let i = 0; i < extra; i++)
        tickets.pop();

      setSelectedTickets(tickets);
    }
  };

  const selectTicket = (ticketNum, hasInstantWins = false) => {
    if (!hasInstantWins && selectedTickets.includes(ticketNum)) {
      if (amount > 1) {
        let newAmount = amount - 1;

        setAmount(newAmount);
        setAmountText(`${newAmount}`);
      } else {
        setAmount(0);
        setAmountText("0");
      }

      return setSelectedTickets((tickets) => tickets.filter((ticket) => ticket !== ticketNum)); //remove
    } else if (validateSetAmount(currentUserTotal, amount + 1, raffle, unavailableTickets) > amount) {
      let newAmount = amount + 1;

      setAmount(newAmount);
      setAmountText(`${newAmount}`);

      return setSelectedTickets((tickets) => [...tickets, ticketNum]); //add
    } else {
      let tickets = [...selectedTickets];
      
      if (tickets.length === 0)
        return setSelectedTickets(tickets);
      
      tickets[tickets.length - 1] = ticketNum;
      return setSelectedTickets(tickets);
    }
  };

  const selectTickets = (ticketNums) => {
    const allowedAdd = validateSetAmount(currentUserTotal, amount + ticketNums.length, raffle, unavailableTickets);

    if (allowedAdd > amount) {
      setAmount(allowedAdd);
      setAmountText(`${allowedAdd}`);

      const tickets = [...selectedTickets];
      for (let i = 0; i < allowedAdd - amount; i++) tickets.push(ticketNums[i]);

      return setSelectedTickets(tickets);
    }
  };

  const removeTicket = () => {
    if (amount > 1) {
      const tickets = [...selectedTickets];
      tickets.pop();

      let newAmount = amount - 1;

      setAmount(newAmount);
      setAmountText(`${newAmount}`);
      setSelectedTickets(tickets);
    } else {
      setAmount(0);
      setAmountText("0");
      setSelectedTickets([]);
    }
  };

  const luckyDip = (amount = 1) => {
    let allTickets = new Set();

    const minLetterBound = useLetters ? "A".charCodeAt(0) : false;
    const maxLetterBound = useLetters ? toLetter.charCodeAt(0) : false;
    const minNumberBound = 1;
    const maxNumberBound = ticketsPerLetter;

    if (minLetterBound && maxLetterBound)
      for (let i = minLetterBound; i <= maxLetterBound; i++) {
        for (let j = minNumberBound; j <= maxNumberBound; j++) {
          allTickets.add(String.fromCharCode(i) + j);
        }
      }
    else
      for (let i = minNumberBound; i <= maxNumberBound; i++) {
        allTickets.add(i.toString());
      }

    selectedTickets.forEach(ticket => allTickets.delete(ticket));
    unavailableTickets.forEach(ticket => allTickets.delete(ticket));

    if (allTickets.size >= amount) {
      allTickets = Array.from(allTickets);
      let tickets = [];

      for (let i = 0; i < amount; i++) {
        let j = Math.floor(Math.random() * allTickets.length);

        tickets.push(allTickets[j]);
        allTickets.splice(j, 1);
      }

      selectTickets(tickets);
    }
  };

  return (
    <div>
      <div className="raffle-ticket-selector-main">
        <div className="row raffle-description no-margin no-bottom">
          <div className="ticket-selector-container" id="ticket-selector">
            {answeringQuestion ? (
              <RaffleSelectAnswer
                question={raffle.question && raffle.question.question}
                answers={raffle.question && raffle.question.answers}
                isMobile={isMobile}
                afterAnswered={(ans) => hasInstantWins ? addToCartHidden(amount, ans) : addToCart(selectedTickets, ans)}
                onClose={() => setAnsweringQuestion(false)}
              />
            ) : hasInstantWins ? (
              <>
                <div className="ticket-selector-header instant-wins">
                  <h1>Instant Win Competition</h1>
                  <h2>Choose how many lucky numbers you would like to purchase!</h2>
                  
                  {user.state.isAuthenticated && (
                    <div className="center">
                      <div className="btn lucky-dip-btn amount instant-wins">
                        <div className="float-left">
                          <Minus
                            className="add-subtract"
                            onClick={() => {
                              if (validateSetAmount(currentUserTotal, amount - 1, raffle, unavailableTickets) < amount)
                                removeTicket();
                            }}
                          />
                        </div>
                        <div>
                          <input
                            className="input quick-pick"
                            type="text"
                            value={amountText}
                            onChange={(e) => checkAmount(e.target.value, hasInstantWins)}
                          />
                        </div>
                        <div className="float-right">
                          <Plus
                            className="add-subtract"
                            onClick={() => {
                              let validated = validateSetAmount(currentUserTotal, amount + 1, raffle, unavailableTickets);

                              if (validated > amount)
                                selectTicket("?", true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="ticket-selector-header">
                  <h1>Choose your lucky numbers</h1>
                  {user.state.isAuthenticated && (
                    selectingAmount ? (
                      <div className={isMobile ? "center" : "float-right"}>
                        <div className="btn lucky-dip-btn amount">
                          <div className="float-left">
                            <Minus
                              className="add-subtract"
                              onClick={() => {
                                if (validateSetAmount(currentUserTotal, amount - 1, raffle, unavailableTickets) < amount)
                                  selectTicket(selectedTickets.slice(-1)[0]);
                              }}
                            />
                          </div>
                          <div>
                            <input
                              className="input quick-pick"
                              type="text"
                              value={amountText}
                              onChange={(e) => checkAmount(e.target.value, hasInstantWins)}
                            />
                          </div>
                          <div className="float-right">
                            <Plus
                              className="add-subtract"
                              onClick={() => {
                                let validated = validateSetAmount(currentUserTotal, amount + 1, raffle, unavailableTickets);

                                if (validated > amount)
                                  luckyDip();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <button className={"btn lucky-dip-btn" + (!isMobile ? " float-right" : "")} onClick={() => {
                        var maxTickets = Math.min(raffle.maxEntriesPerPerson, (raffle.maxEntries - unavailableTickets.length), (raffle.maxEntriesPerPerson - currentUserTotal));
      
                        if (maxTickets > 0) {
                          if (maxTickets > 1) {
                            if ((luckyDipDefault || 0) > 0)
                              luckyDip(luckyDipDefault);

                            setSelectingAmount(true);
                          }
                          else
                            luckyDip();
                        } else {
                          messages.dispatch({ type: "send", payload: { type: "error", msg: "You have already purchased the maximum amount of tickets for this competition!" } });
                        }
                      }}>
                        Quick Pick
                      </button>)
                  )}
                </div>
                {useLetters && (
                  <div className="ticket-selector-letters">
                    {letterRange.map((letter) => (
                      <button
                        key={letter}
                        onClick={() => setSelectedLetter(letter)}
                        className={`selector-btn letter ${selectedLetter === letter ? "selected" : ""}`}
                      >
                        {letter}
                      </button>
                    ))}
                </div>
                )}
                <div className={`ticket-selector-numbers${useLetters ? " has-letters" : ""}`}>
                  {numberRange.map((i) => {
                    const ticketNum = (useLetters ? selectedLetter : "") + (i + 1);

                    return (
                      <button
                        key={i}
                        onClick={() => {
                          var maxTickets = Math.min(raffle.maxEntriesPerPerson, (raffle.maxEntries - unavailableTickets.length), (raffle.maxEntriesPerPerson - currentUserTotal));
      
                          if (maxTickets > 0)
                            selectTicket(ticketNum);
                          else
                            messages.dispatch({ type: "send", payload: { type: "error", msg: "You have already purchased the maximum amount of tickets for this competition!" } })
                        }}
                        className={`selector-btn ${
                          selectedTickets.includes(ticketNum) ? "selected" : ""
                        }`}
                        disabled={unavailableTickets.includes(ticketNum)}
                      >
                        {ticketNum}
                      </button>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row raffle-description no-top no-bottom">
          <div id="add-to-cart" className="add-to-cart-btn-container">
            {user.state.isAuthenticated ? (
              <div className="add-to-cart-inner">
                {!user.state.verified ? (
                  <div className="add-to-cart-text">
                    You must verify your account before buying your first ticket!
                  </div>
                ) : (
                  <div className="row gutter no-padding">
                    <div className={`col-${isMobile ? "12" : "8"} no-margin`}>
                      <div className={isMobile ? "center" : ""}>
                        {selectedTickets.map((ticketNum, i) => {
                          return (
                            <button
                              key={i}
                              onClick={() => hasInstantWins ? removeTicket() : selectTicket(ticketNum)}
                              className={`selector-btn overview`}
                            >
                              {ticketNum}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    <div className={`col-${isMobile ? "12" : "4"} no-margin`}>
                      <div className="add-to-cart-btns">
                        <div className="btn add-to-cart-btn cost" style={answeringQuestion ? { marginRight: "unset" } : {}}>
                          Cost: {currentTotalPrice.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
                        </div>
                        {!answeringQuestion && (
                          <button
                            className="btn add-to-cart-btn pay"
                            disabled={!selectedTickets.length || addingToCart || !user.state.verified}
                            onClick={() => {
                              var maxTickets = Math.min(raffle.maxEntriesPerPerson, (raffle.maxEntries - unavailableTickets.length), (raffle.maxEntriesPerPerson - currentUserTotal));

                              if (maxTickets > 0) {
                                if (maxTickets >= selectedTickets.length) {
                                  setAnsweringQuestion(true);

                                  if (selector !== null)
                                    selector.scrollIntoView();
                                } else {
                                  messages.dispatch({ type: "send", payload: { type: "error", msg: "You can only purchase another " + maxTickets + " more tickets for this competition!" } });
                                }
                              } else {
                                messages.dispatch({ type: "send", payload: { type: "error", msg: "You have already purchased the maximum amount of tickets for this competition!" } });
                              }
                            }}
                          >
                            {addingToCart ? <LoadingSpinner /> : "Pay & Play"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="add-to-cart-inner">
                <div className="add-to-cart-text">
                  You need to be logged in order to buy a ticket
                </div>
                <Link
                  to={`/auth/login?redirect=${encodeURIComponent(`/c/${slug}`)}`}
                  className="btn lucky-dip-btn login"
                >
                  Log in
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketSelector;
