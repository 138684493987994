import Axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Menu } from "react-feather";

import { AppContext } from "../../../context/AppContext";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleUser } from "@fortawesome/free-regular-svg-icons"

import Slider from "react-slick";
import Dropdown from "./navigation/Dropdown";

const Navigation = () => {
  const history = useHistory();
  const location = useLocation();

  const { user } = useContext(AppContext);

  const [promotedRaffles, setPromotedRaffles] = useState([]);
  const [showPromotedRaffles, setShowPromotedRaffles] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(0);

  const toRaffle = (slug) => {
    history.push(`/c/${slug}`);
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/promotedRaffles`).then(({ data }) => {
      setPromotedRaffles(data.promotedRaffles);
    });
  }, []);

  useEffect(() => {
    setShowMenu(false);
    setShowPromotedRaffles(location.pathname === "/" && !user.state.confirmationRequired);
  }, [location.pathname, user.state]);

  // Keep a track of current time for countdown timers
  useEffect(() => {
    if (!user.state.cart) return;

    const updateCart = () => {
      setCartQuantity(user.state.cart.filter(({ exp }) => moment(exp).isAfter(moment())).length);
    };

    updateCart();
    const intervalID = setInterval(() => updateCart(), 1000);
    return () => clearInterval(intervalID);
  }, [user.state.cart, cartQuantity]);

  const links = [
    { title: "About", url: "/about" },
    { title: "Competitions", url: "/all" },
    { title: "Refer A Friend", url: "/referrals" },
    { title: "Results", sub: [
      { mob: "Live Draws", title: "📺 Live Draws", url: "/draws" },
      { mob: "Winners", title: "🏆 Winners", url: "/winners" }
    ] },
    { title: "Contact", url: "/contact" },
  ];

  return (
    <div className="navigation">
      {showPromotedRaffles && promotedRaffles && promotedRaffles.length > 0 && (
        <div className="navigation-upper promoted">
          <Slider
            accessibility={false}
            autoplay={true}
            autoplaySpeed={10000}
            arrows={false}
            className="promoted-raffles-slider"
            dots={false}
            duration={100}
            slidesToShow={1}
            swipe={false}
          >
            {promotedRaffles.map(({ image, raffle }, i) => (
              <div className="promoted-raffle-slide" key={i}>
                <div className="promoted-raffle" style={{ backgroundImage: `url("${image}")` }}>
                  <div className="row hide-mobile promoted-raffle-extra">
                    <div className="promoted-raffle-text">
                      <div className="promoted-section">
                        <span className="promoted-raffle-name">
                          {raffle.price > 0 ? `Support ${raffle.charity.name} and` : "Free Entry Competition"}
                        </span>
                      </div>
                      <div className="promoted-section">
                        <span className="promoted-raffle-name win">
                          Win a {raffle.name}
                        </span>
                      </div>
                      <div className="promoted-section">
                        <button className="promoted-raffle-button" onClick={() => toRaffle(raffle.slug)}>Enter</button>
                      </div>
                    </div>
                    
                    <div className="promoted-raffle-ticket-section">
                      <div className="progress-bar">
                        <CircularProgressbarWithChildren
                          value={(raffle.ticketsPurchased.length / raffle.maxEntries)}
                          strokeWidth={16}
                          maxValue={1}
                        >
                          <div className="tickets">
                            <p className="sold">{raffle.ticketsPurchased.length} sold</p>
                            <p className="total">out of {raffle.maxEntries}</p>
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                  </div>

                  <div className="row hide-desktop promoted-raffle-lower">
                    <div className="promoted-raffle-ticket-section">
                      <div className="progress-bar">
                        <CircularProgressbarWithChildren
                          value={(raffle.ticketsPurchased.length / raffle.maxEntries)}
                          strokeWidth={16}
                          maxValue={1}
                        >
                          <div className="tickets">
                            <p className="sold">{raffle.ticketsPurchased.length} sold</p>
                            <p className="total">out of {raffle.maxEntries}</p>
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                    <div className="promoted-raffle-lower-section">
                      <div className="promoted-section">
                        <span className="promoted-raffle-name">
                          {raffle.price > 0 ? `Support ${raffle.charity.name}` : "Free Entry Competition"}
                        </span>
                        <br />
                        <span className="promoted-raffle-name win">
                          Win a {raffle.name}
                        </span>
                      </div>
                      <div className="promoted-section">
                        <button className="promoted-raffle-button" onClick={() => toRaffle(raffle.slug)}>Enter</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}

      <nav className={`main-navigation${showPromotedRaffles && promotedRaffles && promotedRaffles.length > 0 ? " home" : ""}`}>
        <div className="row main-navigation-inner">
          <div className="navigation-buttons hide-desktop">
            <button className="global-menu" onClick={() => setShowMenu(!showMenu)}>
              <Menu />
              {!!cartQuantity && <span className="cart-quantity">{cartQuantity}</span>}
            </button>
            <Link to="/account" className="btn global-account-btn">
              <FontAwesomeIcon icon={faCircleUser} style={{ color: "white", height: "35px", margin: "-8px 0 0 -8px", width: "35px" }} />
            </Link>
          </div>

          <div className="col">
            <h1>
              <Link to="/" className="global-logo">
                Pryze
              </Link>
            </h1>
          </div>
          <div className="col">
            <ul className="main-navigation-links hide-desktop" style={{ height: showMenu ? "100vh" : 0 }}>
              {links.map(({ title, sub, url }) => (
                sub ? sub.map(({ mob, url }) => (
                  <li key={mob}><Link to={url}>{mob}</Link></li>
                )) : (
                  <li key={title}><Link to={url}>{title}</Link></li>
                )
              ))}

              <li className="cta-btn">
                {user.state.isAuthenticated ? (
                  <>
                    <Link to="/account" className="btn account-btn">
                      My Account
                    </Link>
                    <Link to="/basket" className="btn cart-btn">
                      {!!cartQuantity && <span className="cart-quantity">{cartQuantity}</span>}
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to="/auth/register" className="btn btn-transparent">
                      Join
                    </Link>
                    <Link to="/auth/login" className="btn">
                      Login
                    </Link>
                  </>
                )}
              </li>
            </ul>
            <ul className="main-navigation-links hide-mobile" style={{ height: showMenu ? "100vh" : 0 }}>
              {links.map(({ title, sub, url }) => (
                <li key={title}>
                  {sub ? (
                    <Dropdown sub={sub} title={title} />
                  ) : (
                    <Link to={url}>{title}</Link>
                  )}
                </li> 
              ))}

              <li className="cta-btn">
                {user.state.isAuthenticated ? (
                  <>
                    <Link to="/account" className="btn account-btn">
                      My Account
                    </Link>
                    <Link to="/basket" className="btn cart-btn">
                      {!!cartQuantity && <span className="cart-quantity">{cartQuantity}</span>}
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to="/auth/register" className="btn btn-transparent">
                      Join
                    </Link>
                    <Link to="/auth/login" className="btn">
                      Login
                    </Link>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
