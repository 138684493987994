import React, { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../context/AppContext";
import Axios from "axios";
import LoadingSpinner from "../../includes/LoadingSpinner";
import { Helmet } from "react-helmet";

const Referrals = () => {
  const { config, messages, user } = useContext(AppContext);
  
  const [creatingLink, setCreatingLink] = useState(false);
  const [link, setLink] = useState(false);
  const [reward, setReward] = useState("£0.00");
  const [threshold, setThreshold] = useState("£0.00");

  const copyUrl = async () => {
    try {
      if (user.state.referralCode && user.state.referralCode !== "") {
        let matches = window.location.href.match(/^(https?:\/\/)([^/?#]+)(?:[/?#]|$)/i);
        let domain = matches && matches[2];

        if (domain) {
          await navigator.clipboard.writeText(matches[1] + domain + "/r/" + user.state.referralCode);
          messages.dispatch({ type: "send", payload: { type: "success", msg: "Successfully copied referral URL to clipboard!" } });
        } else {
          messages.dispatch({ type: "send", payload: { type: "error", msg: "Failed to copy your referral URL!" } });
        }
      } else {
        messages.dispatch({ type: "send", payload: { type: "error", msg: "No referral code found!" } });
      }
    } catch (err) {
      console.log("Failed to copy: ", err);
    }
  };

  useEffect(() => {
    if (!config || !config.state) {
      return;
    }
    
    const referrals = config.state.find(({ type }) => type === "referrals");

    if (referrals && referrals.options) {
      setReward(referrals.options.bonus.toLocaleString("en-GB", { style: "currency", currency: "GBP" }));
      setThreshold(referrals.options.required.toLocaleString("en-GB", { style: "currency", currency: "GBP" }));
    }
  }, [config, config.state]);

  useEffect(() => {
    const newCode = async () => {
      let code = Math.random().toString(16).substring(2, 8);
  
      await Axios.post(`${process.env.REACT_APP_API}/api/auth`, { newsletter: user.state.newsletter, referralCode: code })
        .then(() => {
          user.dispatch({
            type: "update",
            payload: { referralCode: code }
          });
        }).catch((err) => {
          code = false;
        });
  
      return code;
    };

    if (user.state._id) {
      let matches = window.location.href.match(/^(https?:\/\/)([^/?#]+)(?:[/?#]|$)/i);
      let domain = matches && matches[2];

      if (user.state.referralCode) {
        if (domain) {
          setLink(`${matches[1] + domain + "/r/" + user.state.referralCode}`);
        } else
          setLink(`https://pryze.co.uk/r/${user.state.referralCode}`);
      } else {
        setCreatingLink(true);

        newCode().then((code) => {
          if (code) setLink(`${matches[1] + domain + "/r/" + code}`);
          else setLink("no_code");

          setCreatingLink(false);
        });
      }
    }
    else
      setLink("no_code");
  }, [user, user.state._id, user.state.referralCode]);

  return (
    <div className="cta-slab curve">
      <Helmet>
        <title>Pryze | Refer A Friend</title>
      </Helmet>
      
      <div className="row">
        <div className="col">
          <h1 className="title">Refer friends, earn rewards 🎁</h1>
          
          {user.state._id && (
            <div className="row no-padding">
              <div className="program-section">
                <div className="col">
                  <h2>Your referral link</h2>
                  
                  {creatingLink ? (
                    <div className="row">
                      <div className="col">
                        <LoadingSpinner dark={true} />
                      </div>
                    </div>
                  ) : (
                    <>
                      <input className="input white" type="text" value={link} disabled />

                      <div className="col">
                        <button className="btn btn-blue" onClick={copyUrl}>Copy Link</button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="program-section">
              <div className="col">
                <p>Everyone who registers with Pryze gets their own referral link.</p>
                <p>When others use your link to create an account, they become your referral.</p>
                <p>This means that you can get free promotional credit added to your account when someone signs up using your link.</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="program-section">
              <div className="col">
                <span className="tip">Tip: </span>Share competitions that you think your friends would be interested in and tag these friends in your shared competitions.
              </div>
            </div>
          </div>

          <div className="row gutter">
            <div className="col-4">
              <div className="referral-guide man after" />
              <div className="program-section">
                <p className="guide">Register to Pryze.co.uk</p>
              </div>
            </div>
            <div className="col-4">
              <div className="referral-guide tablet after" />
              <div className="program-section">
                <p className="guide">Share your referral link to other users</p>
              </div>
            </div>
            <div className="col-4">
              <div className="referral-guide coins" />
              <div className="program-section">
                <p className="guide">When your referral spends {threshold}, you'll both receive {reward} promotional credit</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referrals;