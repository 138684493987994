import Axios from "axios";
import moment from "moment";
import React, { useContext } from "react";
import { Trash2 } from "react-feather";
import { AppContext } from "../../../../context/AppContext";

const LiveDraw = ({ liveDraw, update }) => {
  const { messages } = useContext(AppContext);

  const onDelete = async (id) => {
    if (
      window.confirm(
        "Are you sure you would like to permanently delete this live draw?"
      )
    ) {
      try {
        await Axios.delete(`${process.env.REACT_APP_API}/api/liveDraws/${id}`);
        update();

        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: "Successfully deleted live draw" },
        });
      } catch (e) {
        messages.dispatch({
          type: "send",
          payload: {
            type: "error",
            msg: "There was a problem deleting this live draw",
          },
        });
      }
    }
  };

  const setLive = async (id, live) => {
    try {
      await Axios.patch(`${process.env.REACT_APP_API}/api/liveDraws/${id}`, { live });
      update();

      messages.dispatch({ type: "send", payload: { type: "success", msg: "Successfully updated live draw" } });
    } catch (e) {
      messages.dispatch({ type: "send", payload: { type: "error", msg: "There was a problem updating this live draw" } });
    }
  };

  return (
    <tr>
      <td>
        <div
          className={`status-dot ${liveDraw.live ? "active" : "inactive"}`}
          onClick={() => setLive(liveDraw._id, !liveDraw.live)}
        >
        </div>
      </td>
      <td>
        {moment(liveDraw.date).format("DD/MM/YYYY HH:mm")}
      </td>
      <td>
        {liveDraw.title}
      </td>
      <td>
        <a target="_blank" rel="noreferrer" href={liveDraw.url}>{liveDraw.url}</a>
      </td>
      <td className="right">
        <button className="delete-btn" onClick={() => onDelete(liveDraw._id)}>
          <Trash2 />
        </button>
      </td>
    </tr>
  )
};

export default LiveDraw;