import Axios from "axios";
import moment from "moment";
import React, { useState, useContext } from "react";

import { AppContext } from "../../../../context/AppContext";

const ConfigLanding = ({ data: configData }) => {
  const { messages } = useContext(AppContext);

  const [show, setShow] = useState(configData.options.show || false);  
  const [lastUpdated, setLastUpdated] = useState(configData.updatedAt);

  const save = (e) => {
    e.preventDefault();

    Axios.post(`${process.env.REACT_APP_API}/api/config/${configData._id}`, { ...configData, options: { show } })
      .then(() => {
        messages.dispatch({ type: "send", payload: { type: "success", msg: "Saved" } });
        setLastUpdated(Date.now());
      })
      .catch((e) => {
        if (e.response && e.response.data)
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: e.response.data.msg.message },
          });
      });
  };

  return (
    <div>
      <div className="config-section">
        <h2 className="admin-subtitle">Landing Page</h2>

        <form onSubmit={save} className="config-landing-page">
          <div className="form-row">
            <div className="landing-option">
              <input
                type="checkbox"
                id="show"
                checked={show}
                onChange={(e) => setShow(e.target.checked)}
              />
              <label htmlFor="show">Show Landing Page</label>
            </div>
          </div>

          <div className="dynamic-text-actions">
            <button type="submit" className="btn btn-admin btn-small">
              Save
            </button>
            <small>Last updated {moment(lastUpdated).fromNow()}</small>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfigLanding;