import React from "react";
import moment from "moment";

const ViewPromoBal = ({ user, onClose }) => {
  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={onClose} />
      <div className="modal" style={{ maxHeight: "1000px", overflow: "scroll" }}>
        <h2>Promotional Balance History</h2>

        <table className="promotional-balance-history-table">
          <thead>
            <tr>
              <th>Amount</th>
              <th>Date</th>
              <th>Transaction Note</th>
            </tr>
          </thead>
          <tbody>
            {user.promotionalBalanceHistory.map((record, i) => (
              <tr key={i}>
                <td>{record.amount.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}</td>
                <td>{moment(record.date).format("DD/MM/YYYY @ HH:mm")}</td>
                <td>{record.notes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewPromoBal;