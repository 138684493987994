import React, { useState, useContext } from "react";
import Axios from "axios";
import Dropzone from "react-dropzone";
import { AppContext } from "../../../context/AppContext";

const AddCharityModal = ({ closeModal, charityAdded }) => {
  const { messages } = useContext(AppContext);

  const [name, setName] = useState("");
  const [logo, setLogo] = useState("");
  const [url, setUrl] = useState("");

  const submit = (e) => {
    e.preventDefault();

    if (!name || !logo || !url)
      return messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "All fields required" },
      });

    Axios.post(`${process.env.REACT_APP_API}/api/charities`, { name, logo, url })
      .then(() => {
        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: "Successfully created charity" },
        });

        charityAdded();
        closeModal();
      })
      .catch((err) => {
        if (err.response.data && err.response.data.msg) {
          console.log(err.response.data.msg);
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg },
          });
        }
      });
  };

  const uploadImage = (e) => {
    if (!e[0]) return false;

    const formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("image", e[0]);

    Axios.post(`${process.env.REACT_APP_API}/api/imageUpload`, formData, config)
      .then(( { data } ) => {
        if (data.file) setLogo(data.file);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg }
          });
        }
      });
  };

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={closeModal}></div>
      <div className="modal">
        <h2>Add Charity</h2>
        <form onSubmit={(e) => submit(e)}>
          <div className="form-row">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              className="admin-input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus={true}
            />
          </div>

          <div className="form-row">
            <label htmlFor="logo">Logo</label>
            <Dropzone
              multiple={false}
              accept={["image/jpeg", "image/jpg", "image/png", "image/gif"]}
              onDropAccepted={(e) => uploadImage(e)}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="input-dropzone">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                  </div>
                </section>
              )}
            </Dropzone>

            {logo && (
              <div
                className="form-image"
                style={{
                  backgroundImage: `url(${logo})`,
                }}
              />
            )}
          </div>

          <div className="form-row">
            <label htmlFor="url">URL</label>
            <input
              type="text"
              id="url"
              className="admin-input"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>

          <button type="submit" className="btn btn-admin">
            Add
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddCharityModal;
