import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import moment from "moment";
import { AppContext } from "../../../context/AppContext";
import LoadingSpinner from "../../includes/LoadingSpinner";
import CartItem from "./includes/CartItem";
import CartCheckoutSummary from "./CartCheckoutSummary";
import OtherRaffles from "../raffles/includes/OtherRaffles";
import { Helmet } from "react-helmet";

const Cart = () => {
  const { messages } = useContext(AppContext);

  const [excluded, setExcluded] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(moment());
  const [tickets, setTickets] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);

  const dispatchMessage = messages.dispatch;

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/raffleTickets/reserved`)
      .then(({ data }) => {
        if (data.tickets) {
          const excluded = new Set();
          data.tickets.forEach((ticket) => excluded.add(ticket.raffle._id));

          setExcluded([...excluded]);
          setTickets(data.tickets);
        }

        if (data.totalPrice)
          setCartTotal(data.totalPrice);

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        dispatchMessage({
          type: "send",
          payload: {
            type: "error",
            msg: "There was a problem retrieving your cart. Please try again later.",
          },
        });
      });
  }, [dispatchMessage]);

  // Keep a track of current time for countdown timers
  useEffect(() => {
    if (!tickets.length) return;

    const intervalID = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(intervalID);
  }, [tickets.length]);

  const removeTicket = (ticket) => {
    const newTickets = tickets.filter((t) => t._id !== ticket._id);

    setTickets(newTickets);
    setCartTotal(cartTotal - ticket.raffle.price);
  };

  return (
    <div className="cta-slab curve">
      <Helmet>
        <title>Pryze | My Basket</title>
      </Helmet>
      
      <div className="row">
        <div className="col cart">
          <h1 className="title">Basket</h1>

          {loading ? (
            <div className="cart-main">
              <div className="center">
                <LoadingSpinner dark={true} />
              </div>
            </div>
          ) : (
            <div className="cart-main">
              {!tickets.length ? (
                  <p className="center" style={{ marginBottom: "0" }}>Your basket is empty!</p>
                ) : (
                  <div className="slate cart-items">
                    {tickets.map((ticket, i) => (
                      <CartItem
                        key={i}
                        currentTime={currentTime}
                        ticket={ticket}
                        removeTicket={() => removeTicket(ticket)}
                      />
                    ))}
                  </div>
                )
              }
            </div>
          )}
        </div>
      </div>

      {!!tickets.length && (
        <CartCheckoutSummary subTotal={cartTotal} />
      )}

      <OtherRaffles className="cta-slab double-curve white" exclude={excluded} />
    </div>
  );
};

export default Cart;
