import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import Axios from "axios";
import { AppContext } from "../../../context/AppContext";
import LoadingSpinner from "../../includes/LoadingSpinner";
import RaffleHeader from "./includes/RaffleHeader";
import RaffleInfo from "./includes/RaffleInfo";
import RaffleDesc from "./includes/RaffleDesc";
import RaffleCategorySearch from "./includes/RaffleCategorySearch";
import RaffleCharitySearch from "./includes/RaffleCharitySearch";
import RaffleTicketsVideo from "./includes/RaffleTicketsQuestionsVideo";
import RaffleInstantWins from "./includes/RaffleInstantWins";

const RafflePage = () => {
  const { messages } = useContext(AppContext);
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [raffleData, setRaffleData] = useState({});

  const messageDispatch = messages.dispatch;

  useEffect(() => {
    const loadInstantWins = (raffleData) => {
      if (!raffleData.instantWins)
        raffleData.instantWins = [];

      if (!raffleData.instantWinsDescription)
        raffleData.instantWinsDescription = "";

      Axios.get(`${process.env.REACT_APP_API}/api/instantWins/raffle/${id}`).then(({ data }) => {
        const winners = new Map();

        data.instantWins.forEach(({ ticketNum, user }) => {
          winners.set(ticketNum, user);
        });

        raffleData.winners = winners;

        setRaffleData(raffleData);
        setLoading(false);
      }).catch(() => {
        setRaffleData(raffleData);
        setLoading(false);

        messageDispatch({
          type: "send",
          payload: { type: "warning", msg: "Failed to load instant win data for this raffle" }
        });
      });
    };

    Axios.get(`${process.env.REACT_APP_API}/api/raffles/${id}`)
      .then(({ data }) => {
        const raffle = data.raffle;

        Axios.get(`${process.env.REACT_APP_API}/api/contributions/raffle/${id}`)
          .then(({ data }) => {
            const contributions = new Set();
            let amount = 0;

            if (data.contributions.length > 0) {
              data.contributions.forEach(({ contribution }) => {
                if (!contributions.has(contribution._id)) {
                  contributions.add(contribution._id);
                  amount += contribution.amount;
                }
              });
            }

            raffle.contributions = amount;
            loadInstantWins(raffle);
          }).catch(() => {
            loadInstantWins(raffle);
            messageDispatch({
              type: "send",
              payload: { type: "error", msg: "Could not fetch contributions for that raffle" }
            });
          });
      })
      .catch(() => {
        setLoading(false);
        messageDispatch({
          type: "send",
          payload: { type: "error", msg: "That raffle has either been deleted or does not exist" },
        });
        history.push("/admin/raffles");
      });
  }, [id, messageDispatch, history]);

  return (
    <div className="row admin-raffle-page">
      <div className="col">
        {loading ? (
          <LoadingSpinner dark={true} className="center" />
        ) : (
          <div>
            <RaffleHeader
              id={raffleData._id}
              live={raffleData.live}
              name={raffleData.name}
              slug={raffleData.slug}
              endDate={raffleData.endDate}
            />
            <RaffleInfo
              id={raffleData._id}
              image={raffleData.images.find(({ keyImage }) => keyImage === true)}
              originalPrice={raffleData.originalPrice}
              price={raffleData.price}
              contributions={raffleData.contributions}
              onSale={raffleData.onSale}
              purchases={raffleData.ticketpurchases.length}
              maxEntries={raffleData.maxEntries}
              startDate={raffleData.startDate}
              endDate={raffleData.endDate}
            />
            <RaffleDesc desc={raffleData.description} />
            <RaffleInstantWins instantWins={raffleData.instantWins} instantWinsDescription={raffleData.instantWinsDescription} winners={raffleData.winners} />
            <RaffleCategorySearch
              searchTags={raffleData.searchTags}
              categories={raffleData.categories}
            />
            <RaffleCharitySearch
              charity={raffleData.charity}
              charityPercentage={raffleData.charityPercentage}
            />
            <RaffleTicketsVideo
              couponCodePercentage={raffleData.couponCodePercentage}
              luckyDipDefault={raffleData.luckyDipDefault}
              maxEntries={raffleData.maxEntries}
              maxEntriesPerPerson={raffleData.maxEntriesPerPerson}
              ticketMatrix={raffleData.ticketMatrix}
              question={raffleData.question && raffleData.question.question}
              answers={raffleData.question && raffleData.question.answers}
              video={raffleData.videoUrl}
              guaranteedWinnerInfoBox={raffleData.guaranteedWinnerInfoBox}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RafflePage;
