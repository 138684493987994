import React, { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import { ExternalLink } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import MyPromotionalBalance from "./includes/MyPromotionalBalance";

const AccountSidebar = () => {
  const { user } = useContext(AppContext);
  const location = useLocation();

  const accountLinks = [
    user.state.admin && {
      title: (
        <>
          Admin Console <ExternalLink width={16} height={16} />
        </>
      ),
      url: "/admin",
      className: "admin-link",
      newTab: true,
    },
    { title: "My Tickets", url: "/account/tickets" },
    { title: "Account", url: "/account/settings" },
    { title: "Charity", url: "/account/charity" },
    { title: "Referrals", url: "/account/referrals" },
    { title: "Support", url: "/account/support" },
    { title: "Logout", url: "/auth/logout" },
  ];

  return (
    <div className="row user-account-row">
      <div className="user-account-name">
        Hey 👋, {user.state.firstName + " " + user.state.lastName.substring(0, 1) + "."}
        <MyPromotionalBalance />
      </div>

      <nav className="user-account-nav">
        <ul>
          {accountLinks.map(
            ({ title, url, className, newTab }) =>
              title && (
                <li className={className} key={title}>
                  <Link
                    to={url}
                    className={`${className} ${location.pathname.includes(url) ? "active" : ""} ${!newTab && "under"}`}
                    target={newTab && "_blank"}
                  >
                    {title}
                  </Link>
                </li>
              )
            )
          }
        </ul>
      </nav>
    </div>
  );
};

export default AccountSidebar;
