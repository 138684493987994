import React, { useContext, useEffect, useRef, useState } from "react";
import { Info, Trash2 } from "react-feather";
import moment from "moment";
import Axios from "axios";
import { AppContext } from "../../../../context/AppContext";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

const CartItem = ({ currentTime, ticket, removeTicket, flashWhenExpired, minimal = false }) => {
  const { user, messages } = useContext(AppContext);
  const awaitRemoveTicket = useRef();
  const { raffle, ticketNum, exp, questionAnswer } = ticket;
  const [flashTime, setFlashTime] = useState(false);

  const deleteTicket = (e) => {
    e.preventDefault();

    Axios.delete(`${process.env.REACT_APP_API}/api/raffleTickets/reservation/${ticket._id}`)
      .then(() => {
        user.dispatch({ type: "removeFromCart", payload: ticket._id });
        removeTicket();
      })
      .catch(() => {
        messages.dispatch({
          type: "send",
          payload: { type: "error", msg: "There was a problem removing that ticket from the cart" },
        });
      });
  };

  const raffleImage = raffle.images.find(({ keyImage }) => keyImage)
    ? `url(${raffle.images.filter(({ keyImage }) => keyImage)[0].path})`
    : "none";

  useEffect(() => {
    // Ticket has expired!
    if (moment(exp).isBefore(currentTime)) {
      // If flash time is set, we want to warn that time has run out but still give time to finish payment
      if (flashWhenExpired) {
        if (!flashTime) setFlashTime(true);

        // In 1 minute time, we will actually run removeTicket
        if (!awaitRemoveTicket.current)
          awaitRemoveTicket.current = setTimeout(() => removeTicket(), 60000);
      } else {
        // Normal expiration, remove ticket immediately
        removeTicket();
      }
    }

    return () => false;
  }, [currentTime, exp, flashTime, flashWhenExpired, removeTicket]);

  return (
    raffle && (
      <div className={!minimal ? "cart-item" : "cart-item-minimal"}>
        <div className="cart-item-meta">
          <div
            className="cart-item-img"
            style={{
              backgroundImage: raffleImage,
            }}
          />

          <Link to={`/c/${raffle.slug}`} className="cart-item-name">
            {raffle.name}
          </Link>

          <div className="raffle-other-info">
            <div className="tag">{ticketNum}</div>
            {!minimal && (
              <div
                className="question-answer"
                data-tip={`<strong>Question:</strong> ${raffle.question.question}<br /><br /><strong>Your Answer</strong>: ${questionAnswer}`}
              >
                <Info />
              </div>
            )}
          </div>

          <ReactTooltip className="tooltip" html={true} backgroundColor="#000" />
        </div>

        <div className="cart-item-pricing">
          <p className="cart-item-reserved">
            {!minimal && (
              <span className="reserved-for-txt">
                Reserved for
              </span>
            )}
            <span className={flashTime ? "flash-expired" : undefined}>
              {flashTime ? "0:00" : moment(moment(exp).diff(currentTime)).format("m:ss")}
            </span>
          </p>

          <p className="cart-item-price">
            {raffle.price.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
          </p>

          {!minimal && (
            <button className="cart-item-delete" onClick={deleteTicket}>
              <Trash2 />
            </button>
          )}
        </div>
      </div>
    )
  );
};

export default CartItem;
