import React, { useContext } from "react";
import { useState } from "react";
import { AppContext } from "../../../../../context/AppContext";
import { useHistory, Link } from "react-router-dom";
import Axios from "axios";
import { ArrowLeft } from "react-feather";

const ChangePassword = () => {
  const history = useHistory();
  const { messages } = useContext(AppContext);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");

  const submit = (e) => {
    e.preventDefault();

    Axios.post(`${process.env.REACT_APP_API}/api/auth/change-password`, {
      currentPassword,
      newPassword,
      newPasswordAgain,
    })
      .then(() => {
        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: "Successfully changed password!" },
        });
        history.push("/account/settings");
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg },
          });
        }
      });
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="user-account-main-header">
        <h1>
          <Link to="/account/settings" className="back-btn">
            <ArrowLeft />
          </Link>
          Change Password
        </h1>
      </div>

      <form onSubmit={submit}>
        <div className="row no-padding gutter">
          <div className="col-6">
            <div className="form-row">
              <label htmlFor="currentPassword">Current Password</label>
              <input
                type="password"
                className="input"
                id="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                autoComplete="current-password"
              />
            </div>
          </div>
        </div>

        <div className="row no-padding gutter">
          <div className="col-6">
            <div className="form-row">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                className="input"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                autoComplete="new-password"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-row">
              <label htmlFor="newPasswordAgain">Confirm New Password</label>
              <input
                type="password"
                className="input"
                id="newPasswordAgain"
                value={newPasswordAgain}
                onChange={(e) => setNewPasswordAgain(e.target.value)}
                autoComplete="new-password"
              />
            </div>
          </div>
        </div>

        <button className="btn btn-blue" type="submit">
          Change Password
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
