import React, { useContext, useEffect, useState } from "react";
import { X } from "react-feather";
import { AppContext } from "../../../context/AppContext";

const PromoPopup = () => {
  const { config, user } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [popupData, setPopupData] = useState({ options: {} });

  useEffect(() => {
    if (!config || !config.state) {
      return;
    }

    const data = config.state.filter(({ type }) => type === "global_promotional_popup");
    const dismissed = localStorage.global_promotional_popup_dismissed;

    setPopupData(data[0] || {});
    if (data[0]?.options?.active && !dismissed) {
      if (data[0]?.options?.excludeLoggedIn && user.state.isAuthenticated) {
      } else {
        const timeout = setTimeout(() => {
          setShow(true);
        }, 5000);

        return () => clearTimeout(timeout);
      }
    }
  }, [config, config.state, user.state.isAuthenticated]);

  const onClickPromo = () => {
    window.location.href = popupData.options.link;
    onClosePopup();
  };

  const onClosePopup = () => {
    localStorage.setItem("global_promotional_popup_dismissed", true);
    setShow(false);
  };

  return (
    show && (
      <div className="global-promo-popup-container">
        <div className="global-promo-popup-bg"></div>

        <div className="global-promo-popup-modal">
          <button className="global-promo-popup-closebtn" onClick={onClosePopup}>
            <X />
          </button>
          <div style={{ cursor: "pointer" }} onClick={onClickPromo}>
            <img
              src={popupData.image}
              alt="Promotional discount"
              className="global-promo-popup-img"
            />
          </div>
        </div>
      </div>
    )
  );
};

export default PromoPopup;
