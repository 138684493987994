import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../context/AppContext";
import LoadingSpinner from "../../includes/LoadingSpinner";

const LandingPage = () => {
  const end = 832, start = 982;

  const { messages } = useContext(AppContext);
  const [bgLeft, setBgLeft] = useState(0);

  const resize = () => {
    let left = window.innerWidth - start;
    
    if (left < (end - start))
      left = end - start;

    setBgLeft(left < 0 ? left : 0);
  };

  useEffect(() => {
    let left = window.innerWidth - start;

    if (left < (end - start))
      setBgLeft(end - start);
    else
      setBgLeft(left < 0 ? left : 0);
    
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = (e) => {
    e.preventDefault();

    if (!email)
      return messages.dispatch({ type: "send", payload: { type: "error", msg: "Invalid email address!" } })

    setLoading(true);

    Axios.post(`${process.env.REACT_APP_API}/api/auth/subscribe`, { email }).then(({ data }) => {
      setEmail("");
      setLoading(false);

      messages.dispatch({
        type: "send",
        payload: {
          type: "success",
          msg: data.msg
        },
      });
    });
  };

  return (
    <>
      <div className="background-logo" style={{ left: bgLeft }} />

      <div className="row">
        <div className="col">
          <div className="landing-text">
            <h1>Eyes on the Pryze</h1>

            <p>Our website is currently undergoing scheduled maintenance.</p>
            <p>We should be back shortly. Thank you for your patience!</p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <form className="contact-form subscribe" onSubmit={submit}>
            <label htmlFor="email">Subscribe to our mailing list to receive the latest competitions and offers!</label>
            <input
              type="email"
              className="input white"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" className="btn form-btn btn-white subscribe" disabled={loading}>{loading ? <LoadingSpinner dark={true} /> : `Subscribe`}</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
