import React, { useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { AppContext } from "../../context/AppContext";
import LoadingSpinner from "../includes/LoadingSpinner";
import PinInput from "react-pin-input";
import { Helmet } from "react-helmet";

const Login = () => {
  const { messages } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  const [code, setCode] = useState("");
  const [pin, setPin] = useState(null);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [twoFacAuth, setTwoFacAuth] = useState(false);

  const redirectAfterLogin = () => {
    const queryParams = new URLSearchParams(location.search);

    // If referred from "orderComplete" clear stored cart session details
    if (queryParams.get("redirect")) {
      history.push(queryParams.get("redirect"));
    } else {
      history.push("/");
    }
  };

  const onClickRegister = () => {
    const queryParams = new URLSearchParams(location.search);

    // If referred from "orderComplete" clear stored cart session details
    if (queryParams.get("redirect")) {
      history.push(`/auth/register?redirect=${encodeURIComponent(queryParams.get("redirect"))}`);
    } else {
      history.push("/auth/register");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/api/auth/login`, {
        email,
        password,
        rememberMe,
      });

      if (data.twoFacAuthRequired) {
        setTwoFacAuth(true);
      } else {
        localStorage.setItem("sessionToken", data.token);
        redirectAfterLogin();
      }
    } catch (e) {
      messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "Email or password is wrong" },
      });
    }

    setLoading(false);
  };

  const onSubmit2FA = async (code) => {
    setLoading(true);

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/api/auth/login?w=2fa`, {
        email,
        password,
        rememberMe,
        code,
      });

      localStorage.setItem("sessionToken", data.token);
      redirectAfterLogin();
    } catch (error) {
      if (pin) {
        setCode("");
        pin.clear();
      }

      messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "Token is incorrect" },
      });
    }

    setLoading(false);
  };

  const facebookCallback = async (response) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/api/auth/facebook-login`, {
        access_token: response.accessToken,
      });

      localStorage.setItem("sessionToken", data.token);
      redirectAfterLogin();
    } catch (error) {
      messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "Facebook login failed" },
      });
    }
  };

  const googleCallback = async (response) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/api/auth/google-login`, {
        access_token: response.access_token,
      });

      localStorage.setItem("sessionToken", data.token);
      redirectAfterLogin();
    } catch (error) {
      messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "Google login failed" },
      });
    }
  };

  const googleCallbackError = () => {
    messages.dispatch({
      type: "send",
      payload: { type: "error", msg: "Google login failed" }
    });
  };

  const onGoogleClick = useGoogleLogin({
    flow: "implicit",
    onError: googleCallbackError,
    onSuccess: googleCallback
  });

  return (
    <div className="main-panel curve">
      <Helmet>
        <title>Pryze | Login</title>
      </Helmet>
      
      <div className="row center-cols">
        <div className="col-5">
          {twoFacAuth ? (
            <div className="slate">
              <h1 className="title">Two-Factor Auth</h1>
              <p className="center">Enter the 6-digit code from your code generator or third-party app below:</p>

              <form onSubmit={(e) => {
                e.preventDefault();
                onSubmit2FA(code);
              }}>
                <div className="tfa-login">
                <PinInput
                  ref={setPin}
                  focus={true}
                  length={6}
                  type="numeric"
                  inputMode="number"
                  className="pin-input"
                  onChange={setCode}
                  onComplete={(code) => onSubmit2FA(code)}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
                </div>
                <button type="submit" className="btn form-btn btn-blue" disabled={loading}>
                  {!loading ? "Continue" : <LoadingSpinner />}
                </button>
              </form>
            </div>
          ) : (
            <div className="slate">
              <h1 className="title">Login</h1>

              <form onSubmit={onSubmit}>
                <div className="form-row">
                  <input
                    type="email"
                    id="email"
                    placeholder="Email"
                    autoComplete="email"
                    className="input white"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-row">
                  <input
                    type="password"
                    id="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    className="input white"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                  <div className="row no-padding edit-details upper">
                    <div className="col">
                        <input
                          type="checkbox"
                          id="remember-me"
                          onChange={(e) => setRememberMe(e.target.checked)}
                        />
                        <label htmlFor="remember-me">Remember Me</label>
                    </div>
                    <div className="col right">
                      <Link to="/auth/forgot-password" className="forgot-password-link">
                        Forgot Password
                      </Link>
                    </div>
                  </div>

                <button type="submit" className="btn btn-blue form-btn login-btn" disabled={loading}>
                  {!loading ? "Login" : <LoadingSpinner />}
                </button>

                <button onClick={onClickRegister} className="btn btn-white form-btn register-btn">
                  Register
                </button>

                <span className="login-or">Or sign in with</span>

                <div className="social-login-btns">
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    fields="name,email"
                    onSuccess={facebookCallback}
                    render={({ onClick }) => (
                      <button type="button" className="facebook-login-btn" onClick={onClick} />
                    )}
                  />
                  <button type="button" className="google-login-btn" onClick={onGoogleClick} />
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
