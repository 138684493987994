import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import LoadingSpinner from "../../includes/LoadingSpinner";
import OtherRaffles from "../raffles/includes/OtherRaffles";
import { Check } from "react-feather";
import { Helmet } from "react-helmet";

const OrderReveal = () => {
  const { messages } = useContext(AppContext);

  const [couponCode, setCouponCode] = useState(false);
  const [excludeRaffles, setExcludeRaffles] = useState([]);
  const [instantWins, setInstantWins] = useState();
  const [loading, setLoading] = useState(true);
  const [mainPrizes, setMainPrizes] = useState(false);
  const [prizesLeft, setPrizesLeft] = useState(0);
  const [raffles, setRaffles] = useState();
  const [tickets, setTickets] = useState(false);
  const [winner, setWinner] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let endpoint, order = queryParams.get("order"), raffle = queryParams.get("raffle");

    if (order) {
      endpoint = `order/${order}`;
    } else if (raffle) {
      endpoint = `myRaffles/${raffle}`;
    } else {
      history.push("/account/tickets");
      messages.dispatch({ type: "send", payload: { type: "error", msg: "No order or raffle provided!" } });

      return;
    }

    Axios.get(`${process.env.REACT_APP_API}/api/instantWins/${endpoint}`)
      .then(({ data }) => {
        const excludeRaffles = [];
        const instantWins = new Map();
        const mainPrizes = [];

        let prizesLeft = 0;
        let totalPrizes = 0;

        data.raffles.forEach((raffle) => {
          excludeRaffles.push(raffle._id);
          mainPrizes.push(raffle.name);
          
          prizesLeft += (raffle.instantWins.length - raffle.prizesWon.length);
          totalPrizes += raffle.instantWins.length;
        });

        if (totalPrizes === 0) {
          history.push("/account/tickets");
          messages.dispatch({ type: "send", payload: { type: "error", msg: "Invalid order or raffle provided!" } });

          return;
        }

        data.instantWins.forEach((instantWin) => {
          const key = instantWin.raffle._id;
          let tickets = instantWins.get(key);

          if (!tickets) tickets = new Map();

          tickets.set(instantWin.ticketNum, instantWin.prize);
          instantWins.set(key, tickets);
        });

        const raffles = new Map();

        let tickets = new Map();
        let winner = false;

        data.tickets.forEach((ticket) => {
          if (!ticket.raffle.instantWins) return;

          const key = ticket.raffle._id;
          const instantWin = instantWins.get(key);

          if (instantWin && instantWin.has(ticket.ticketNum)) winner = true;

          const ticketsPurchased = tickets.get(key);
          
          if (ticketsPurchased) {
            const instantWinPrize = instantWin?.get(ticket.ticketNum);

            if (instantWinPrize) tickets.set(key, [...ticketsPurchased, { ...ticket, instantWinPrize }]);
            else tickets.set(key, [...ticketsPurchased, ticket]);
          } else {
            const instantWinPrize = instantWin?.get(ticket.ticketNum);

            raffles.set(key, ticket.raffle);

            if (instantWinPrize) tickets.set(key, [{ ...ticket, instantWinPrize }]);
            else tickets.set(key, [ticket]);
          }
        });
        
        for (let [key, value] of tickets) {
          let raffleInstantWins = instantWins.get(key);

          if (raffleInstantWins)
            value.sort((a, b) => {
              if (raffleInstantWins.has(a.ticketNum)) {
                if (raffleInstantWins.has(b.ticketNum)) return a.ticketNum.localeCompare(b.ticketNum, undefined, { numeric: true });

                return -1;
              } else if (raffleInstantWins.has(b.ticketNum)) return 1;

              return a.ticketNum.localeCompare(b.ticketNum, undefined, { numeric: true });
            });
          else value.sort((a, b) => a.ticketNum.localeCompare(b.ticketNum, undefined, { numeric: true }));
        }
        
        tickets = Array.from(tickets, ([key, value]) => ({ raffle: key, tickets: value }))
          .sort((a, b) => {
            if (instantWins.has(a.raffle)) {
              if (instantWins.has(b.raffle)) return 0;

              return -1;
            } else if (instantWins.has(b.raffle)) return 1;

            return 0;
          });
        
        if (data.couponCode) setCouponCode(data.couponCode);

        setExcludeRaffles(excludeRaffles);
        setInstantWins(instantWins);
        
        if (mainPrizes.length > 0)
          setMainPrizes(mainPrizes.length === 1 ? mainPrizes[0] : `${mainPrizes.length} other prizes`);

        setPrizesLeft(prizesLeft);
        setRaffles(raffles);
        setTickets(tickets);
        setWinner(winner);

        if (winner) {
          const duration = 15 * 1000, animationEnd = Date.now() + duration;
          const randomInRange = (min, max) => {
            return Math.random() * (max - min) + min;
          };
      
          let skew = 1;
      
          (function frame() {
            const timeLeft = animationEnd - Date.now(), ticks = Math.max(200, 500 * (timeLeft / duration));
            skew = Math.max(0.8, skew - 0.001);
      
            window.confetti({
              decay: 0.94,
              particleCount: 1,
              startVelocity: 0,
              ticks: ticks,
              origin: {
                x: Math.random(),
                y: Math.random() * skew - 0.2
              },
              gravity: randomInRange(0.8, 0.9),
              scalar: randomInRange(0.6, 1),
              shapes: ["star"],
              colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
              drift: randomInRange(-0.4, 0.4)
            });
      
            if (timeLeft > 0)
              requestAnimationFrame(frame);
          })();
        }

        setLoading(false);
      }).catch((err) => {
        console.error(err);

        history.push("/account/tickets");
        messages.dispatch({ type: "send", payload: { type: "error", msg: "Error fetching result data" } });
      });
  }, [history, location.search]); // eslint-disable-line

  return (
    <>
      <Helmet>
        <title>Pryze | Good luck...</title>
      </Helmet>

      <div className="cta-slab curve">
        <div className="row">
          {loading ? (
            <div className="col">
              <LoadingSpinner className="center" dark={true} />
            </div>
          ) : (
            <>
              <div className="col">
                {winner ? (
                  <>
                    <h1 className="title low-bottom">Winner Winner, Chicken Dinner!</h1>

                    <h2 className="subtitle" style={{ marginBottom: "5px" }}>Congratulations, you've won an Instant Pryze! 👏</h2>
                    <p className="order-text no-top" style={{ fontWeight: "bold" }}>Take a screenshot and tag us on social media! #InstantPryze</p>

                    <p className="order-text">Any promotional credit will be automatically applied, otherwise we'll be in touch with you shortly to arrange delivery of your prize 😉</p>

                    <p className="order-text">You can also leave us a review on Trustpilot to rate your experience with us!</p>
                    <div className="trustpilot" onClick={() => window.open("https://uk.trustpilot.com/evaluate/pryze.co.uk", "_blank")}></div>
                  </>
                ) : (
                  <>
                    <h1 className="title low-bottom">Better luck next time!</h1>
                    <h2 className="subtitle">Sorry, no instant win matches were found this time... 😞</h2>

                    {prizesLeft > 0 && (
                      <h2 className="subtitle top">There {prizesLeft === 1 ? `is still 1 instant win prize` : `are still ${prizesLeft} instant win prizes`} remaining!</h2>
                    )}

                    {mainPrizes && (
                      <h3 className="subtitle secondary top-lg">You're still in the main draw to win {mainPrizes}! 🤩</h3>
                    )}
                  </>
                )}

                {raffles && tickets && (
                  <>
                    <div className="instant-wins-prizes header">
                      <div className="row no-padding">
                        <div className="col">
                          <h1 className="header">Order Summary</h1>
                        </div>
                      </div>
                    </div>

                    <div className="instant-wins-prizes body">
                      <div className="instant-wins-table">
                        {tickets.map(({ raffle, tickets }) => {
                          const raffleInfo = raffles.get(raffle);

                          if (!raffleInfo)
                            return null;

                          const prizesMap = instantWins.get(raffle);

                          return tickets.map((ticket, i) => {
                            const winner = prizesMap && prizesMap.has(ticket.ticketNum);

                            return (
                              <div className="record bg" key={i}>
                                <div className="cell summary raffle-name summary-reveal">
                                  <Link className="reveal" target="_blank" to={`/c/${raffleInfo.slug}`}>
                                    {raffleInfo.name}
                                  </Link>

                                  {winner && (
                                    <p>{prizesMap.get(ticket.ticketNum)}</p>
                                  )}
                                </div>

                                <div className="cell summary tickets reveal">
                                  <span className={`ticket-bubble${winner ? " winner" : ""}`}>{ticket.ticketNum}</span>
                                </div>

                                <div className="cell summary status reveal">
                                  {winner ? (
                                    <span className="won">Winner <Check height={20} width={20} /></span>
                                  ) : (
                                    <span className="not-won">No Win</span>
                                  )}
                                </div>
                              </div>
                            );
                          });
                        })}
                      </div>
                    </div>
                  </>
                )}

                {!winner && (
                  <>
                    <div className="row">
                      <div className="col">
                        <p className="order-text">Thanks for taking the time to enter our instant win draw!</p>

                        {couponCode && (
                          <p className="order-text" style={{ fontWeight: "bold" }}>
                            Here's a {couponCode.percentAmount * 100}% OFF code available for a future purchase:
                            <span className="coupon-code" onClick={async () => {
                              await navigator.clipboard.writeText(couponCode.code);
                              messages.dispatch({ type: "send", payload: { type: "success", msg: "Successfully copied coupon code to clipboard!" } })
                            }}>
                              {couponCode.code}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row center-cols no-padding-vert">
                      <div className="col-4">
                        <Link className="btn btn-blue full" to="/account/tickets">My Tickets</Link>
                      </div>
                    </div>

                    <div className="row center-cols no-padding-vert">
                      <div className="col-4">
                        <Link className="btn btn-blue full" to="/all">Play Again</Link>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <OtherRaffles className="cta-slab white" exclude={excludeRaffles} />
    </>
  );
};

export default OrderReveal;