import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../context/AppContext";
//import LoadingSpinner from "../../includes/LoadingSpinner";
import useMediaQuery from "../../../utils/useMediaQuery";

const RaisingMoneyFor = () => {
  const { config } = useContext(AppContext);
  //const slider = useRef();

  //const isTablet = useMediaQuery("(max-width: 1300px)");
  const isMobile = useMediaQuery("(max-width: 725px)");

  //const [charities, setCharities] = useState([]);
  //const [loading, setLoading] = useState(true);
  const [totalWon, setTotalWon] = useState(0);
  const [totalRaised, setTotalRaised] = useState(0);
  const [totalWinners, setTotalWinners] = useState(0);

  useEffect(() => {
    Promise.all([
      //Axios.get(`${process.env.REACT_APP_API}/api/charities`).then(({ data }) => {
      //  setCharities(data.charities);
      //}),
      Axios.get(`${process.env.REACT_APP_API}/api/contributions/total`).then(({ data }) => {
        setTotalRaised(data.total);
      }),
      Axios.get(`${process.env.REACT_APP_API}/api/winners/total`).then(({ data }) => {
        setTotalWinners(data.total);
      }),
    ]);//.then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!config || !config.state) {
      return;
    }

    let statistics = config.state.find(({ type }) => type === "statistics") || {};

    if (statistics && statistics.options)
      setTotalWon(statistics.options.totalWon);
  }, [config, config.state]);

  return (
    <>
      <div className="cta-slab">
        <div className="row">
          <div className="col">
            <h1 className="title">Supporting UK Charities</h1>

            <div className="row center-cols">
              <div className="col-10">
                <div className="charities-overview">
                  <div className="donation">
                    <span className="charity-name">10% of every ticket sale goes to charity, and always will</span>
                  </div>
                  <div
                    className="work-for-good"
                    onClick={() => window.open("https://workforgood.co.uk", "_blank")}
                  >
                  </div>
                </div>
              </div>
              {/*loading ? (
                <LoadingSpinner className="center" dark />
              ) : (
                <div className="raising-money-for">
                  <div className="brand-wheel">
                    <div className="brand-slide">
                      {charities && charities.length > 0 && charities.map(({ logo, url }, i) => (
                        <div className="logo" key={i}>
                          <div className="image" onClick={() => window.open(url)} style={{ backgroundImage: `url("${logo}")` }} />
                        </div>
                      ))}
                    </div>

                    <div className="brand-slide delay">
                      {charities && charities.length > 0 && charities.map(({ logo, url }, i) => (
                        <div className="logo" key={i}>
                          <div className="image" onClick={() => window.open(url)} style={{ backgroundImage: `url("${logo}")` }} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )*/}
            </div>
          </div>
        </div>
      </div>

      <div className="row gutter" style={{ gap: "unset" }}>
        {isMobile ? (
          <>
            <div className="col-4 raising-for">
              <div className="icon gift" />
              <h1 className="amount">{totalWon.toLocaleString("en-GB", { style: "currency", currency: "GBP", minimumFractionDigits: 0, maximumFractionDigits: 0 })}</h1>
              <h2 className="header">Won in prizes</h2>
            </div>
            <div className="col-4 raising-for">
              <div className="icon ribbon" />
              <h1 className="amount">{totalRaised.toLocaleString("en-GB", { style: "currency", currency: "GBP", minimumFractionDigits: 0, maximumFractionDigits: 0 })}</h1>
              <h2 className="header">Raised for charity</h2>
            </div>
            <div className="col-4 raising-for">
              <div className="icon trophy" />
              <h1 className="amount">{totalWinners}</h1>
              <h2 className="header">Raffle winners</h2>
            </div>
          </>
        ) : (
          <>
            <div className="col-4 no-margin">
              <h2 className="statistics-text header">Won in prizes</h2>
              <h1 className="statistics-text amount">{totalWon.toLocaleString("en-GB", { style: "currency", currency: "GBP", minimumFractionDigits: 0, maximumFractionDigits: 0 })}</h1>
            </div>
            <div className="col-4 no-margin">
              <h2 className="statistics-text header">Raised for charity</h2>
              <h1 className="statistics-text amount">{totalRaised.toLocaleString("en-GB", { style: "currency", currency: "GBP", minimumFractionDigits: 0, maximumFractionDigits: 0 })}</h1>
            </div>
            <div className="col-4 no-margin">
              <h2 className="statistics-text header">Raffle winners</h2>
              <h1 className="statistics-text amount">{totalWinners}</h1>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RaisingMoneyFor;
