import React from "react";
import { Link } from "react-router-dom";

const RaffleCharitySearch = ({ charity, charityPercentage }) => {
  return (
    <div>
      <div className="row no-padding gutter">
        <div className="col-6">
          <div className="admin-slate">
            <h2>Charity</h2>
            {charity ? (
              <div className="tags">
                <Link to="/admin/charities" key={charity.name} className="tag">
                  {charity.name}
                </Link>
              </div>
            ) : (
              <p>No charity set</p>
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="admin-slate">
            <h2>Charity Percentage</h2>
            {charityPercentage > 0 ? (
              <div className="tags">
                <div className="tag">
                  {charityPercentage}%
                </div>
              </div>
            ) : (
              <p>No charity percentage set</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaffleCharitySearch;
