import React, { useContext, useState } from "react";
import moment from "moment";
import Axios from "axios";
import {
  Trash2,
  ChevronRight,
  ChevronLeft,
  Check,
  X,
  ChevronsUp,
  ChevronsDown,
  List,
  UserCheck,
  Edit2,
  DollarSign,
  Unlock,
} from "react-feather";
import { AppContext } from "../../../context/AppContext";
import EditUser from "./includes/EditUser";
import EditPromoBal from "./includes/EditPromoBal";
import ReactTooltip from "react-tooltip";
import ViewPromoBal from "./includes/ViewPromoBal";

const UsersTable = ({ users = [], page, setPage, totalPages, userUpdated }) => {
  const { messages } = useContext(AppContext);
  const [editingUser, setEditingUser] = useState(false);
  const [editingPromoBal, setEditingPromoBal] = useState(false);
  const [viewingPromoBal, setViewingPromoBal] = useState(false);

  const prev = () => setPage(page - 1);
  const next = () => setPage(page + 1);

  const deleteUser = (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this user? All data will be permanently lost."
    );

    if (confirmed) {
      Axios.delete(`${process.env.REACT_APP_API}/api/user/${id}`)
        .then(() => {
          userUpdated(Date.now());
          messages.dispatch({
            type: "send",
            payload: { type: "success", msg: "Successfully deleted user" },
          });
        })
        .catch(() => {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: "There was a problem deleting this user" },
          });
        });
    }
  };

  const changeUserPerms = (id, admin) => {
    const confirmed = window.confirm(
      admin
        ? "Are you sure you want to downgrade this user to standard permissions?"
        : "Are you sure you want to upgrade this user to admin permissions?"
    );

    if (confirmed) {
      Axios.put(`${process.env.REACT_APP_API}/api/user/${id}`, { admin: !admin })
        .then(() => {
          userUpdated(Date.now());
          messages.dispatch({
            type: "send",
            payload: { type: "success", msg: "Successfully modified user permissions" },
          });
        })
        .catch(() => {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: "There was a problem completing this action" },
          });
        });
    }
  };

  const verifyUser = (email, code) => {
    const confirmed = window.confirm("Are you sure you want to verify this users account?");

    if (confirmed) {
      Axios.get(`${process.env.REACT_APP_API}/api/user/verify/${email}/${code}`)
        .then(() => {
          userUpdated(Date.now());
          messages.dispatch({
            type: "send",
            payload: { type: "success", msg: "Successfully verified account" },
          });
        })
        .catch(() => {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: "There was a problem verifying this users account" },
          });
        });
    }
  };

  const disable2FA = (userId) => {
    const confirmed = window.confirm(
      "Are you sure you want to disable this users 2FA? They will have to manually re-enable it, and doing this could also be a severe security risk. Are you sure you want to continue?"
    );

    if (confirmed) {
      Axios.post(`${process.env.REACT_APP_API}/api/auth/disable-2fa`, { userId })
        .then(() => {
          userUpdated(Date.now());
          messages.dispatch({
            type: "send",
            payload: { type: "success", msg: "Successfully disabled 2FA" },
          });
        })
        .catch(() => {
          messages.dispatch({
            type: "send",
            payload: {
              type: "error",
              msg: "There was a problem disabling 2FA for this users account",
            },
          });
        });
    }
  };

  return (
    <div>
      {editingPromoBal && (
        <EditPromoBal
          user={editingPromoBal}
          userUpdated={userUpdated}
          onClose={() => setEditingPromoBal(false)}
        />
      )}

      {editingUser && (
        <EditUser
          user={editingUser}
          userUpdated={userUpdated}
          onClose={() => setEditingUser(false)}
        />
      )}

      {viewingPromoBal && (
        <ViewPromoBal
          user={viewingPromoBal}
          onClose={() => setViewingPromoBal(false)}
        />
      )}

      <table className="raffles-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Verified</th>
            <th>2FA</th>
            <th>Created</th>
            <th style={{ width: 275 }}></th>
          </tr>
        </thead>
        <tbody>
          {!users.length ? (
            <tr>
              <td colSpan="7" className="no-users">
                No users to show
              </td>
            </tr>
          ) : (
            users.map((user) => (
              <tr key={user._id}>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.verified ? <Check size={18} /> : <X size={18} />}</td>
                <td>{user.twoFacAuth ? <Check size={18} /> : <X size={18} />}</td>
                <td>{moment(user.createdAt).format("DD/MM/YYYY (HH:mm)")}</td>
                <td className="right">
                  {user.twoFacAuth && (
                    <button
                      className="delete-btn"
                      onClick={() => disable2FA(user._id)}
                      data-tip="Disable 2FA"
                    >
                      <Unlock />
                    </button>
                  )}
                  {!user.verified && (
                    <button
                      className="delete-btn"
                      onClick={() => verifyUser(user.email, user.verificationCode)}
                      data-tip="Verify account manually"
                    >
                      <UserCheck />
                    </button>
                  )}
                  <button
                    className="delete-btn"
                    onClick={() => setEditingPromoBal(user)}
                    data-tip="Edit Promotional Balance"
                  >
                    <DollarSign />
                  </button>
                  {user.promotionalBalanceHistory && user.promotionalBalanceHistory.length > 0 && (
                    <button
                      className="delete-btn"
                      onClick={() => setViewingPromoBal(user)}
                      data-tip="Promotional Balance History"
                    >
                      <List />
                    </button>
                  )}
                  <button
                    className="delete-btn"
                    onClick={() => setEditingUser(user)}
                    data-tip="Edit user"
                  >
                    <Edit2 />
                  </button>
                  <button
                    className="delete-btn"
                    onClick={() => changeUserPerms(user._id, user.admin)}
                    data-tip={user.admin ? "Downgrade to regular user" : "Upgrade to admin"}
                  >
                    {user.admin ? <ChevronsDown /> : <ChevronsUp />}
                  </button>
                  <button
                    className="delete-btn"
                    onClick={() => deleteUser(user._id)}
                    data-tip="Delete User"
                  >
                    <Trash2 />
                  </button>

                  <ReactTooltip className="tooltip" />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <div className="raffles-paginate">
        <button onClick={prev} disabled={page === 1} className="btn btn-admin btn-paginate">
          <ChevronLeft />
        </button>
        <button
          onClick={next}
          disabled={page === totalPages}
          className="btn btn-admin btn-paginate"
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export default UsersTable;
