import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/free-solid-svg-icons"

import moment from "moment";

const RaffleCard = ({ className, endDate, slug, name, price, onSale, images, maxEntries, ticketsPurchased }) => {
  const purchased = ticketsPurchased ? ticketsPurchased.length : 0;
  const raffleImage = images?.find(({ keyImage }) => keyImage)
    ? `url(${images.filter(({ keyImage }) => keyImage)[0].path})`
    : "none";

  const [timeRemaining, setTimeRemaining] = useState("0s");

  const getTimeRemaining = (milliseconds) => {
    if (milliseconds > 0) {
      let duration = moment.duration(milliseconds);

      if (Math.floor(duration.asDays()) > 0)
        return `${Math.floor(duration.asDays())}d`;
      else if (Math.floor(duration.hours()) > 0)
        return `${Math.floor(duration.hours())}h`;
      else if (Math.floor(duration.minutes()) > 0)
        return `${Math.floor(duration.minutes())}m`;
      else if (Math.floor(duration.seconds()) > 0)
        return `${Math.floor(duration.seconds())}s`;
    }
    
    return "0s";
  };
  
  useEffect(() => {
    setTimeRemaining(
      getTimeRemaining(
        moment.duration((moment(endDate).unix() - moment().unix()) * 1000, "milliseconds")._milliseconds
      )
    );
  
    const intervalID = setInterval(() => {
      setTimeRemaining(
        getTimeRemaining(
          moment.duration((moment(endDate).unix() - moment().unix()) * 1000, "milliseconds")._milliseconds
        )
      );
    }, 1000);
  
    return () => clearInterval(intervalID);
  }, [endDate]);

  return (
    <div className="raffle-card-container">
      <Link to={`/c/${slug}`} className={"raffle-card" + (className.length > 0 ? " " + className : "")}>
        {onSale && (
          <div className="raffle-sale-banner">
            <p className="sale-text">SALE!</p>
          </div>
        )}
        <div className="raffle-card-image" style={{ backgroundImage: raffleImage }} />
        <div className="raffle-card-contents">
          <p className="raffle-title">{name}</p>
          <div className="time-left">
            <FontAwesomeIcon icon={faClock} height="18" width="18" />
            <span>{timeRemaining}</span>
          </div>
          <div className="price">
            {price === 0
              ? "FREE"
              : price.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
          </div>
          <div className="tickets-bar-container">
            <div className="bar">
              <div
                className="bar-fill"
                style={{'--width': (purchased / maxEntries) * 100 + "%" }}
              ></div>
            </div>
            <div className="bar-indices">
              <div className="left">{maxEntries - purchased} left</div>
              <div className="right tickets-total">{maxEntries} total</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default RaffleCard;
