import React, { useState } from "react";
import LoadingSpinner from "../../../includes/LoadingSpinner";

const RaffleSelectAnswer = ({ question, answers = [], isMobile, onClose, afterAnswered }) => {
  const [currentAnswer, setCurrentAnswer] = useState(-1);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = () => {
    setSubmitting(true);
    afterAnswered(answers[currentAnswer].answer);
  };

  return (
    <div className="ticket-selector-header">
      {!isMobile && (
        <button className="btn lucky-dip-btn" onClick={onClose}>
          {"< "}Back
        </button>
      )}

      <h2>{question}</h2>

      <div className="form-row">
        <h3 className="raffle-selectans">Select answer</h3>

        <div>
          {answers.map(({ answer }, i) => (
            <div className="raffle-answer" key={i}>
              <button className={"btn lucky-dip-btn answer" + (currentAnswer === i ? " current" : "")} onClick={() => setCurrentAnswer(i)}>{answer}</button>
            </div>
          ))}
        </div>

        <button onClick={onSubmit} className="btn lucky-dip-btn continue" disabled={currentAnswer === -1}>{!submitting ? "Add to basket" : <LoadingSpinner /> }</button>
      </div>

      {isMobile && (
        <button className="btn lucky-dip-btn back" onClick={onClose}>
          {"< "}Back
        </button>
      )}
    </div>
  );
};

export default RaffleSelectAnswer;
