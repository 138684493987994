import React, { useEffect } from "react";
import FlashMessage from "./includes/FlashMessage";
import AdminHeader from "./includes/AdminHeader";
import AdminNavigation from "./includes/AdminNavigation";
import { Helmet } from "react-helmet";

const AdminLayout = ({ children }) => {
  useEffect(() => {
    // Change meta viewport
    document.querySelector("meta[name=viewport]").setAttribute("content", "width=1400");
  }, []);

  return (
    <div className="admin">
      <Helmet>
        <title>Admin — Pryze</title>
      </Helmet>
      
      <AdminHeader />
      <FlashMessage />

      <div className="admin-content">
        <AdminNavigation />
        <div className="admin-content-main">{children}</div>
      </div>
    </div>
  );
};

export default AdminLayout;
