import React from "react";
import { Helmet } from "react-helmet";

const PageNotFound = () => {
  return (
    <div className="cta-slab curve">
      <Helmet>
        <title>Pryze | Error</title>
      </Helmet>
      
      <div className="row">
        <div className="col">
          <h1>404</h1>
          <p>Page not found</p>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
