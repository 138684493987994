import React, { useContext } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import { AppContext } from "../context/AppContext";

import ProtectedRoute from "./routes/ProtectedRoute";
import PageNotFound from "./pages/error/PageNotFound";

import LandingLayout from "./layouts/LandingLayout";
import MainLayout from "./layouts/MainLayout";

import AboutPage from "./pages/about/AboutPage";
import Competitions from "./pages/home/Competitions";
import Referrals from "./pages/referrals/Referrals";
import Contact from "./pages/contact/Contact";
import HomePage from "./pages/home/HomePage";
import UserAccount from "./pages/account/UserAccount";
import RafflePage from "./pages/raffles/RafflePage";
import RaffleRedirectPage from "./pages/raffles/RaffleRedirectPage";
import ReferralPage from "./pages/referral/ReferralPage";
import Cart from "./pages/cart/Cart";
import DynamicPage from "./pages/dynamic-page/DynamicPage";
import OrderPayment from "./pages/order-payment/OrderPayment";
import WinnersPage from "./pages/winners/WinnersPage";
import LandingPage from "./pages/landing/LandingPage";
import DrawsPage from "./pages/draws/DrawsPage";
import OrderConfirmation from "./pages/order-confirmation/OrderConfirmation";
import OrderReveal from "./pages/order-reveal/OrderReveal";

const MainRouter = ({ landing }) => {
  const { user } = useContext(AppContext);

  return landing && (!user.state || !user.state.isAuthenticated || !user.state.admin) ? (
    <LandingLayout>
      <Switch>
        <Route path="*">
          <LandingPage />
        </Route>
      </Switch>
    </LandingLayout>
  ) : (
    <MainLayout>
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>

        <Route path="/p/:slug">
          <DynamicPage />
        </Route>

        <Route path="/r/:code">
          <ReferralPage />
        </Route>

        <Route path="/about">
          <AboutPage />
        </Route>
        
        {/* Raffle Routes */}
        <Route path="/all">
          <Competitions home={false} viewMore={false} />
        </Route>

        <Route path="/contact">
          <Contact />
        </Route>

        <Route path="/draws">
          <DrawsPage />
        </Route>

        <Route path="/raffles">
          <Redirect to="/all" />
        </Route>

        <Route path="/referrals">
          <Referrals />
        </Route>

        <Route path="/c/:slug">
          <RafflePage />
        </Route>

        <Route path="/raffle/:slug">
          <RaffleRedirectPage />
        </Route>

        <Route path="/winners">
          <WinnersPage />
        </Route>

        <ProtectedRoute path="/account">
          <UserAccount />
        </ProtectedRoute>

        <ProtectedRoute path="/basket">
          <Cart />
        </ProtectedRoute>

        <ProtectedRoute path="/order-confirmation/:orderId">
          <OrderConfirmation />
        </ProtectedRoute>

        <ProtectedRoute path="/order-payment">
          <OrderPayment />
        </ProtectedRoute>

        <ProtectedRoute path="/order-reveal">
          <OrderReveal />
        </ProtectedRoute>

        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </MainLayout>
  );
};

export default MainRouter;
