import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { ChevronRight, ChevronLeft } from "react-feather";
//import Axios from "axios";
//import { AppContext } from "../../../context/AppContext";

const RafflesTable = ({ raffles = [], page, setPage, totalPages, ticketDeleted }) => {
  //const { messages } = useContext(AppContext);

  const prev = () => setPage(page - 1);
  const next = () => setPage(page + 1);

  // const deleteRaffle = (id) => {
  //   const confirmed = window.confirm("Are you sure you want to delete this raffle?");

  //   if (confirmed) {
  //     Axios.delete(`${process.env.REACT_APP_API}/api/raffles/${id}`)
  //       .then(() => {
  //         ticketDeleted(Date.now());
  //         messages.dispatch({
  //           type: "send",
  //           payload: { type: "success", msg: "Successfully deleted raffle" },
  //         });
  //       })
  //       .catch(() => {
  //         messages.dispatch({
  //           type: "send",
  //           payload: { type: "error", msg: "There was a problem deleting the raffle" },
  //         });
  //       });
  //   }
  // };

  return (
    <div>
      <table className="raffles-table">
        <thead>
          <tr>
            <th style={{ width: 30 }}></th>
            <th>Title</th>
            <th>Entrants</th>
            <th>Sales</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th style={{ width: 160 }}></th>
          </tr>
        </thead>
        <tbody>
          {!raffles.length ? (
            <tr>
              <td colSpan="7" className="no-raffles">
                No raffles to show
              </td>
            </tr>
          ) : (
            raffles.map((raffle) => (
              <tr key={raffle._id}>
                <td>
                  <div className={`status-dot ${raffle.live ? "active" : "inactive"}`}></div>
                </td>
                <td>{raffle.name}</td>
                <td>
                  {raffle.ticketpurchases.length}/{raffle.maxEntries}
                </td>
                <td>
                  {raffle.totalSales.toLocaleString("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  })}
                </td>
                <td>{moment(raffle.startDate).format("DD/MM/YYYY (HH:mm)")}</td>
                <td>{moment(raffle.endDate).format("DD/MM/YYYY (HH:mm)")}</td>
                <td className="right">
                  <Link to={`/admin/raffles/${raffle._id}`} className="btn btn-admin">
                    View
                  </Link>
                  {/* <button className="delete-btn" onClick={() => deleteRaffle(raffle._id)}>
                    <Trash2 />
                  </button> */}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <div className="raffles-paginate">
        <button onClick={prev} disabled={page === 1} className="btn btn-admin btn-paginate">
          <ChevronLeft />
        </button>
        <button
          onClick={next}
          disabled={page === totalPages}
          className="btn btn-admin btn-paginate"
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export default RafflesTable;
