import React, { useState, useContext } from "react";
import Axios from "axios";
import { AppContext } from "../../../../context/AppContext";
import moment from "moment";

const AddLiveDrawModal = ({ closeModal, drawAdded }) => {
  const { messages } = useContext(AppContext);

  const [date, setDate] = useState(moment().toDate());
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");

  const submit = (e) => {
    e.preventDefault();

    if (!date || !title || !url)
      return messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "All fields required" },
      });

    Axios.post(`${process.env.REACT_APP_API}/api/liveDraws`, { date: moment(date).format("YYYY-MM-DDTHH:mm"), title, url })
      .then(() => {
        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: "Successfully created live draw" },
        });

        drawAdded();
        closeModal();
      })
      .catch((err) => {
        if (err.response.data && err.response.data.msg) {
          console.log(err.response.data.msg);
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg },
          });
        }
      });
  };

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={closeModal}></div>
      <div className="modal">
        <h2>Add Live Draw</h2>

        <form onSubmit={(e) => submit(e)}>
          <div className="form-row">
            <label htmlFor="date">Date</label>
            <input
              type="datetime-local"
              id="date"
              className="admin-input"
              value={moment(date).format("YYYY-MM-DDTHH:mm")}
              onChange={(e) => setDate(e.target.value)}
              autoFocus={true}
            />
          </div>

          <div className="form-row">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              className="admin-input"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="form-row">
            <label htmlFor="url">URL</label>
            <input
              type="text"
              id="url"
              className="admin-input"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>

          <button type="submit" className="btn btn-admin">
            Add
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddLiveDrawModal;
