import React from "react";
import ReactMarkdown from "react-markdown";

const RaffleInstantWins = ({ instantWins, instantWinsDescription, winners }) => {
  return instantWins && instantWins.length > 0 && winners && (
    <div className="admin-slate">
      <h2>Instant Wins</h2>
      
      <div className="form-row">
        <label htmlFor="description">Description</label>
        <ReactMarkdown className="md-text" linkTarget="_blank" children={instantWinsDescription || "No description set"} />
      </div>

      <div className="form-row">
        <label htmlFor="prizes">Available Prizes</label>
        <ul>
          {instantWins.map(({ prize, ticketNum }, i) => {
            const winner = winners.get(ticketNum);

            return !winner && (
              <li key={i}>
                <span className="text-red">
                  Ticket #{ticketNum} - {prize}
                </span>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="form-row">
        <label htmlFor="claimed-prizes">Claimed Prizes</label>
        <ul>
          {instantWins.map(({ prize, promotionalBalance, ticketNum }, i) => {
            const winner = winners.get(ticketNum);

            return winner && (
              <li key={i}>
                <span className="inline-block text-green">
                  Ticket #{ticketNum} - {prize}
                  {promotionalBalance && " (Promotional Credit)"}
                </span>
                <p className="inline-block" style={{ marginLeft: "5px" }}>- Won by: {winner.firstName} {winner.lastName}</p>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  );
};

export default RaffleInstantWins;