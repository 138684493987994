import React, { useState, useEffect, useContext } from "react";
import { Trash2 } from "react-feather";
import Axios from "axios";
import AddCharityModal from "./AddCharityModal";
import { AppContext } from "../../../context/AppContext";
import LoadingSpinner from "../../includes/LoadingSpinner";
import AddContributionModal from "./AddContributionModal";

const CharitiesPage = () => {
  const { messages } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [charities, setCharities] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showContributionModal, setShowContributionModal] = useState(false);
  
  const [charityAdded, setCharityAdded] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/charities`).then(({ data }) => {
      setCharities(data.charities);
      setLoading(false);
    });
  }, [charityAdded]);

  const deleteCharity = (id) => {
    if (window.confirm("Are you sure you want to delete this charity?")) {
      Axios.delete(`${process.env.REACT_APP_API}/api/charities/${id}`)
        .then(() => {
          setCharities(charities.filter((charity) => charity._id !== id));
        })
        .catch(() => {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: "There was a problem deleting that charity" },
          });
        });
    }
  };

  return (
    <div className="row">
      <div className="col">
        {showAddModal && (
          <AddCharityModal
            closeModal={() => setShowAddModal(false)}
            charityAdded={setCharityAdded}
          />
        )}

        {showContributionModal && (
          <AddContributionModal closeModal={() => setShowContributionModal(false)} />
        )}

        {/* Title */}
        <div className="row no-padding">
          <div className="col">
            <h1 className="admin-title">Charities</h1>
          </div>
          <div className="col right">
            <button className="btn btn-admin" style={{ marginRight: "5px" }} onClick={() => setShowContributionModal(true)}>
              Add Contribution
            </button>
            <button className="btn btn-admin" onClick={() => setShowAddModal(true)}>
              Add New
            </button>
          </div>
        </div>

        {/* Table */}
        <table className="raffles-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Logo</th>
              <th>URL</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="4">
                  <LoadingSpinner dark={true} className="center" />
                </td>
              </tr>
            ) : !charities.length ? (
              <tr>
                <td colSpan="4" className="no-raffles">
                  No charities to show
                </td>
              </tr>
            ) : (
              charities.map(({ _id, name, logo, url }) => (
                <tr key={name}>
                  <td>{name}</td>
                  <td><img src={`${logo}`} alt="Raffle" className="raffle-table-image" /></td>
                  <td><a href={url} rel="noreferrer" target="_blank">{url}</a></td>
                  <td className="right">
                    <button className="delete-btn" onClick={() => deleteCharity(_id)}>
                      <Trash2 />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CharitiesPage;
