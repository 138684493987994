import { CreditCard, Plus } from "react-feather";
import React, { useState } from "react";
import LoadingSpinner from "../../../includes/LoadingSpinner";

const SetCard = ({ cards, setCard, setStep }) => {
  const [selectedCard, setSelectedCard] = useState("new");
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async () => {
    setSubmitting(true);

    if (selectedCard !== "new") {
      setCard(selectedCard);
    }

    setStep("payment");
  };

  return (
    <div className="row gutter no-padding center-cols">
      <div className="col-8">
        <div className="cart-main cart-items">
          <div className="row gutter checkout-summary">
            <div className="col">
              <div>
                <h3 className="cart-items-address-title">Select a Payment Method</h3>

                <form className="select-payment-method" onSubmit={(e) => e.preventDefault()}>
                  <div
                    className={`select-card${selectedCard === "new" ? " selected" : ""}`}
                    onClick={() => setSelectedCard("new")}
                  >
                    <input
                      checked={selectedCard === "new"}
                      onChange={(e) => setSelectedCard("new")}
                      type="radio"
                      name="card"
                      id="new"
                    />
                    <Plus height="20px" />
                    <label htmlFor="new"> Enter New Details</label>
                  </div>

                  {cards.map((card, i) => (
                    <div
                      className={`select-card${selectedCard === card._id ? " selected" : ""}`}
                      onClick={() => setSelectedCard(card._id)}
                      key={i}
                    >
                      <input
                        checked={selectedCard === card._id}
                        onChange={(e) => setSelectedCard(e.target.value)}
                        type="radio"
                        name="card"
                        id={card._id}
                        value={card._id}
                      />
                      <CreditCard height="20px" />
                      <label htmlFor={card._id}>{card.type} (x-{card.lastFour})</label>
                    </div>
                  ))}
                </form>
              </div>
            </div>
          </div>

          <div className="row">
            <button
              className="btn btn-blue form-btn"
              disabled={submitting}
              onClick={onSubmit}
            >
              {submitting ? <LoadingSpinner /> : "Continue"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetCard;