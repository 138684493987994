import React from "react";
import { Link } from "react-router-dom";

import moment from "moment";

const Winner = ({ className, winner }) => {
  const image = `url(${winner.image})`;
  
  return (
    <div className={`winner-card${className && className.length > 0 ? ` ${className}` : ""}`}>
      <Link to={`/c/${winner.raffle.slug}`}>
        <div className="winner-image" style={{ backgroundImage: image }}>
          <div className="winner-image-shade">
            <div className="winner-details">
              <p className="winner-title">{winner.instantWin ? "Instantly Won by" : "Won by"}</p>
              <p>{`${winner.user.firstName} ${winner.user.lastName.charAt(0)}.`}</p>
              <div className="ticket">{winner.ticketNumber}</div>
            </div>
            <div className="winner-details footer">
              <h1>{moment(winner.instantWin ? winner.createdAt : winner.raffle.endDate).format("Do MMMM YYYY")}</h1>
            </div>
          </div>
        </div>

        <div className="raffle-details">
          <h3>{winner.prizeOverride ?? winner.raffle.name}</h3>
          <div className="raffle-details-tickets">
            <div className="price">
                {winner.raffle.price === 0
                  ? "FREE"
                  : winner.raffle.price.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
            </div>
            <div className="tickets-bar-container">
              <div className="bar">
                <div
                  className="bar-fill"
                  style={{'--width': (winner.ticketsPurchased.length / winner.raffle.maxEntries) * 100 + "%" }}
                ></div>
              </div>
              <div className="bar-indices">
                <div className="left">{winner.ticketsPurchased.length} sold</div>
                <div className="right tickets-total">{winner.raffle.maxEntries} total</div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Winner;