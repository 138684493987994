import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MyTickets from "./tabs/MyTickets";
import AccountSettings from "./tabs/AccountSettings";
import Charity from "./tabs/Charity";
import Referrals from "./tabs/Referrals";
import Support from "./tabs/Support";
import AccountSidebar from "./sidebar/AccountSidebar";
import ChangePassword from "./tabs/account-settings-pages/ChangePassword";
import AccountHistory from "./tabs/account-settings-pages/AccountHistory";
import EditDetails from "./tabs/account-settings-pages/EditDetails";
import TwoFactorAuth from "./tabs/account-settings-pages/TwoFactorAuth";
import useMediaQuery from "../../../utils/useMediaQuery";
import PaymentMethods from "./tabs/account-settings-pages/PaymentMethods";
import { Helmet } from "react-helmet";

const UserAccount = () => {
  const isMobile = useMediaQuery("(max-width: 1300px)");
  
  const section = (
    <div className="row">
      <Switch>
        <Route path="/account/tickets">
          <MyTickets />
        </Route>

        {/* Account */}
        <Route path="/account/settings" exact>
          <AccountSettings />
        </Route>
        <Route path="/account/settings/edit">
          <EditDetails />
        </Route>
        <Route path="/account/settings/cards">
          <PaymentMethods />
        </Route>
        <Route path="/account/settings/change-password">
          <ChangePassword />
        </Route>
        <Route path="/account/settings/2fa">
          <TwoFactorAuth />
        </Route>
        <Route path="/account/settings/history">
          <AccountHistory />
        </Route>
        {/* End Account */}

        <Route path="/account/charity">
          <Charity />
        </Route>

        <Route path="/account/support">
          <Support />
        </Route>
        
        <Route path="/account/referrals">
          <Referrals />
        </Route>

        <Route path="/account">
          <Redirect to="/account/tickets" />
        </Route>
      </Switch>
    </div>
  );

  return (
    <div className="cta-slab curve">
      <Helmet>
        <title>Pryze | My Account</title>
      </Helmet>
      
      <div className="row gutter">
        <div className="user-account">
          <div className="row no-gutter no-padding">
            <AccountSidebar />
          </div>
          {!isMobile && section}
        </div>
        {isMobile && (
          <div className="user-account">
            {section}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAccount;
