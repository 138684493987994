import React, { useState, useContext } from "react";
import Axios from "axios";
import { CheckCircle } from "react-feather";
import LoadingSpinner from "../includes/LoadingSpinner";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
  const { messages } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);

    Axios.post(`${process.env.REACT_APP_API}/api/auth/forgot-password`, { email })
      .then(() => {
        setComplete(true);
      })
      .catch(() => {
        setLoading(false);
        messages.dispatch({
          type: "send",
          payload: {
            type: "error",
            msg: "There was a problem with that request. Please try again later.",
          },
        });
      });
  };

  return (
    <div className="main-panel">
      <Helmet>
        <title>Pryze | Forgot Password</title>
      </Helmet>
      
      <div className="row center-cols">
        <div className="col-5">
          <div className="slate">
            {complete ? (
              <div>
                <div className="center">
                  <CheckCircle size={40} color="#2fde80" />
                  <h1>Check your email</h1>
                  <p className="forgot-password-complete-desc">
                    If that email is associated with a Pryze account we will have sent you an email
                    with instructions on how to reset your password. The email may take a few minutes
                    to arrive, and it may be in your spam folder.
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <h1 className="title">Forgot Password</h1>

                <p className="forgot-password-desc">
                  Not to worry! Enter the email associated with your Pryze account below and we will send
                  you an email to reset your password if an account with that email exists.
                </p>

                <form onSubmit={submit}>
                  <div className="form-row">
                    <input
                      id="email"
                      type="email"
                      className="input white"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>

                  <button type="submit" className="btn form-btn btn-blue" disabled={!email || loading}>
                    {loading ? <LoadingSpinner /> : "Send Email"}
                  </button>

                  <p className="login-or">
                    Know your password? Click <Link to="/auth/login">here</Link> to go back.
                  </p>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
