import { useEffect, useRef } from "react";

const TrustpilotBanner = () => {
  const trustBoxRef = useRef();

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true);
    }
  }, []);

  return (
    <div className="global-trustpilot-banner">
      <div className="row">
        <div className="col">
          {/* TrustBox widget - Micro Review Count */}
          <div
            ref={trustBoxRef}
            className="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="5419b6a8b0d04a076446a9ad"
            data-businessunit-id="62a871177d2c8bcd3910caee"
            data-style-height="22px"
            data-style-width="100%"
            data-theme="dark"
            data-without-reviews-preferred-string-id="3"
          >
            <a
              href="https://uk.trustpilot.com/review/pryze.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustpilotBanner;
