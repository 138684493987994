import React, { useContext } from "react";
import { Clock, CreditCard, Edit2, Key, Lock } from "react-feather";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";

const AccountSettings = () => {
  const { user } = useContext(AppContext);

  const settingsLinks = [
    {
      icon: <Edit2 />,
      link: "/account/settings/edit",
      title: "Edit details",
      subtitle: "Edit basic information such as your email, phone and address",
    },
    {
      icon: <Clock />,
      link: "/account/settings/history",
      title: "Account History",
      subtitle: "View your recent orders and promotional balance transactions",
    },
    {
      icon: <CreditCard />,
      link: "/account/settings/cards",
      title: "Payment Methods",
      subtitle: "View and manage your saved cards"
    },
    user.state.password && {
      icon: <Key />,
      link: "/account/settings/change-password",
      title: "Change password",
      subtitle: "Change your account password",
    },
    user.state.password && {
      icon: <Lock />,
      link: "/account/settings/2fa",
      title: (
        <>
          Two-factor authentication
          <span className={`link-tag ${user.state.twoFacAuth ? "on" : "off"}`}>
            {user.state.twoFacAuth ? "on" : "off"}
          </span>
        </>
      ),
      subtitle: "Setup or manage two-factor authentication",
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <div className="row no-padding user-account-main-header">
        <div className="col">
          <h1>Account</h1>
        </div>
      </div>

      <div className="account-settings">
        {settingsLinks.map(
          (link) =>
            link && (
              <Link key={link.title} to={link.link} className="settings-link">
                {link.icon}
                <span>
                  <h4>{link.title}</h4>
                  <p>{link.subtitle}</p>
                </span>
              </Link>
            )
        )}
      </div>
    </div>
  );
};

export default AccountSettings;
