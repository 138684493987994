import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../../../context/AppContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import Axios from "axios";
import LoadingSpinner from "../../../../includes/LoadingSpinner";

const EditDetails = () => {
  const { user, messages } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();

  const [firstName, setFirstName] = useState(user.state.firstName);
  const [lastName, setLastName] = useState(user.state.lastName);
  const [email, setEmail] = useState(user.state.email);
  const [phone, setPhone] = useState(user.state.phone);
  const [newsletter, setNewsletter] = useState(user.state.newsletter);
  const [addresses, setAddresses] = useState(user.state.addresses || {});
  const [duplicateShippingAddr, setDuplicateShippingAddr] = useState(user.state.duplicateShippingAddr);

  const [submitting, setSubmitting] = useState(false);

  const updateAddress = (key, addrType, val) => {
    const updatedAddresses = addresses;
    updatedAddresses[addrType][key] = val;

    setAddresses({ ...updatedAddresses });
  };

  useEffect(() => {
    setAddresses((addresses) => ({
      ...addresses,
      billing: {
        addressLine1: duplicateShippingAddr ? addresses.shipping.addressLine1 : "",
        addressLine2: duplicateShippingAddr ? addresses.shipping.addressLine2 : "",
        city: duplicateShippingAddr ? addresses.shipping.city : "",
        county: duplicateShippingAddr ? addresses.shipping.county : "",
        postCode: duplicateShippingAddr ? addresses.shipping.postCode : "",
      },
    }));
  }, [duplicateShippingAddr]);

  const submit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    Axios.post(`${process.env.REACT_APP_API}/api/auth`, {
      email,
      phone,
      addresses,
      newsletter,
      duplicateShippingAddr,
    })
      .then(() => {
        const queryParams = new URLSearchParams(location.search);

        user.dispatch({
          type: "update",
          payload: { email, phone, addresses, newsletter, duplicateShippingAddr },
        });
        messages.dispatch({
          type: "send",
          payload: { type: "success", msg: "Successfully updated" },
        });
        setSubmitting(false);

        // If "next" available in the URL params redirect to there
        if (queryParams.get("next")) {
          history.push(queryParams.get("next"));
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg },
          });
        }
        setSubmitting(false);
      });
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="user-account-main-header">
        <h1>
          <Link to="/account/settings" className="back-btn">
            <ArrowLeft />
          </Link>
          Edit Details
        </h1>
      </div>

      <form onSubmit={submit}>
        <div className="row no-padding gutter">
          <div className="col-6">
            <div className="form-row">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="input"
                disabled={true}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-row">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="input"
                disabled={true}
              />
            </div>
          </div>
        </div>

        <div className="row no-padding gutter">
          <div className="col-6">
            <div className="form-row">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-row">
              <label htmlFor="phone">Phone</label>
              <input
                type="text"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="input"
              />
            </div>
          </div>
        </div>

        {["shipping", "billing"].map((addrType) => (
          <div key={addrType}>
            <h3 className="edit-details-subtitle">{addrType} Address</h3>

            {addrType === "billing" && (
              <div className="row no-padding edit-details">
                <input
                  type="checkbox"
                  checked={duplicateShippingAddr}
                  onChange={(e) => setDuplicateShippingAddr(e.target.checked)}
                  id="same-as-shipping-addr"
                  name="same-as-shipping-addr"
                />
                <label htmlFor="same-as-shipping-addr">Same as shipping address</label>
              </div>
            )}

            {(addrType === "shipping" || (addrType === "billing" && !duplicateShippingAddr)) && (
              <>
                <div className="row no-padding gutter">
                  <div className="col-6">
                    <div className="form-row">
                      <label htmlFor={`${addrType}addressLine1`}>Address Line 1</label>
                      <input
                        type="text"
                        id={`${addrType}addressLine1`}
                        value={addresses[addrType].addressLine1}
                        onChange={(e) => updateAddress("addressLine1", addrType, e.target.value)}
                        className="input"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-row">
                      <label htmlFor={`${addrType}addressLine2`}>Address Line 2</label>
                      <input
                        type="text"
                        id={`${addrType}addressLine2`}
                        value={addresses[addrType].addressLine2}
                        onChange={(e) => updateAddress("addressLine2", addrType, e.target.value)}
                        className="input"
                      />
                    </div>
                  </div>
                </div>

                <div className="row no-padding gutter">
                  <div className="col-6">
                    <div className="form-row">
                      <label htmlFor={`${addrType}city`}>City</label>
                      <input
                        type="text"
                        id={`${addrType}city`}
                        value={addresses[addrType].city}
                        onChange={(e) => updateAddress("city", addrType, e.target.value)}
                        className="input"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-row">
                      <label htmlFor={`${addrType}county`}>County</label>
                      <input
                        type="text"
                        id={`${addrType}county`}
                        value={addresses[addrType].county}
                        onChange={(e) => updateAddress("county", addrType, e.target.value)}
                        className="input"
                      />
                    </div>
                  </div>
                </div>

                <div className="row no-padding gutter">
                  <div className="col-6">
                    <div className="form-row">
                      <label htmlFor={`${addrType}postCode`}>Post Code</label>
                      <input
                        type="text"
                        id={`${addrType}postCode`}
                        value={addresses[addrType].postCode}
                        onChange={(e) => updateAddress("postCode", addrType, e.target.value)}
                        className="input"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}

        <div>
          <h3 className="edit-details-subtitle">Newsletter</h3>
          <div className="row no-padding edit-details">
            <input
              type="checkbox"
              id="newsletter"
              checked={newsletter}
              onChange={(e) => setNewsletter(e.target.checked)}
            />
            <label htmlFor="newsletter">
              Receive offers, news and more by email
            </label>
          </div>
        </div>

        <div className="row no-padding gutter">
          <div className="col-6">
            <button className="btn btn-blue" disabled={submitting}>
              {submitting ? <LoadingSpinner /> : "Update Account"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditDetails;
