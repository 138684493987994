import React, { useContext } from "react";
import Axios from "axios";
import Dropzone from "react-dropzone";
import { AppContext } from "../../../../../context/AppContext";

const RaffleInstantCSVUpload = ({ closeModal, onUpload }) => {
  const { messages } = useContext(AppContext);

  const uploadFile = async (e) => {
    if (!e || !Array.isArray(e)) return false;

    try {
      const file = e[0];
      if (!file) throw new Error({ response: { data: { msg: "No file selected!" } } });

      const formData = new FormData();
      formData.append("file", file);

      const { data } = await Axios.post(
        `${process.env.REACT_APP_API}/api/fileUpload`,
        formData,
        { headers: { "content-type": "multipart/form-data" } }
      );

      onUpload(data.parsed);
    } catch (err) {
      if (err.response && err.response.data) {
        messages.dispatch({
          type: "send",
          payload: { type: "error", msg: err.response.data.msg }
        });
      }
    }
  };

  return (
    <div className="modal-container">
      <div className="modal-bg" onClick={closeModal}></div>
      <div className="modal">
        <h2>Upload</h2>

        <div>
          <div className="form-row">
            <Dropzone
              accept="text/csv"
              onDropAccepted={(e) => uploadFile(e)}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="input-dropzone">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p className="text">Drag 'n' drop some files here, or click to select files</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
    </div>
  )
};

export default RaffleInstantCSVUpload;