import React, { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";
import LoadingSpinner from "../../../../includes/LoadingSpinner";
import { Link } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import SortBySwitcher from "../includes/SortBySwitcher";
import OrderItem from "../includes/OrderItem";

const AccountHistory = () => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [sortBy, setSortBy] = useState("orders");

  useEffect(() => {
    Promise.all([
      Axios.get(`${process.env.REACT_APP_API}/api/orders/my`).then(({ data }) => {
        setOrders(data);
      }),
      Axios.get(`${process.env.REACT_APP_API}/api/auth/getPromotionalBalance?withHistory=1`).then(
        ({ data }) => {
          setTransactions((data.promotionalBalanceHistory || []).reverse());
        }
      ),
    ]).then(() => setLoading(false));
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <div className="row no-padding user-account-main-header">
        <div className="col">
          <h1>
            <Link to="/account/settings" className="back-btn">
              <ArrowLeft />
            </Link>
            Account History
          </h1>
        </div>
        <div className="col right">
          <SortBySwitcher
            className="account-history-sorter"
            sortOptions={[
              { sort: "orders", name: "Orders" },
              { sort: "balance", name: "Balance" },
            ]}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </div>
      </div>

      <div>
        {/* Orders */}
        {sortBy === "orders" &&
          (loading ? (
            <LoadingSpinner className="center" />
          ) : !orders.length ? (
            <p>You have not placed any orders.</p>
          ) : (
            <div>
              {orders.map((order) => (
                <OrderItem key={order._id} order={order} />
              ))}
            </div>
          ))}

        {/* Balance */}
        {sortBy === "balance" &&
          (loading ? (
            <LoadingSpinner className="center" />
          ) : !transactions.length ? (
            <p>There are no transactions to show.</p>
          ) : (
            <table className="promotional-balance-history-table">
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Transaction Note</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map(({ amount, date, notes }, i) => (
                  <tr key={i}>
                    <td>
                      <span className={amount < 0 ? "trans-negative" : "trans-positive"}>
                        {amount.toLocaleString("en-GB", {
                          style: "currency",
                          currency: "GBP",
                        })}
                      </span>
                    </td>
                    <td>{moment(date).format("DD MMM YYYY @ HH:mm")}</td>
                    <td>{notes}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
      </div>
    </div>
  );
};

export default AccountHistory;
