import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Star } from "react-feather";
//import { AppContext } from "../../../../../context/AppContext";

const MyPromotionalBalance = () => {
  //const { messages } = useContext(AppContext);

  //const [code, setCode] = useState("");
  //const [showCodeForm, setShowCodeForm] = useState(false);
  const [userPromotionalBalance, setUserPromotionalBalance] = useState(0);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/auth/getPromotionalBalance`).then(({ data }) => {
      setUserPromotionalBalance(data.promotionalBalance);
    });
  }, []);

  /*
  const onRedeemCode = async (e) => {
    e.preventDefault();
    
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API}/api/auth/redeemPromotionalBalance`,
        { code }
      );

      setUserPromotionalBalance(userPromotionalBalance + data.creditAmount);
      setShowCodeForm(false);

      messages.dispatch({
        type: "send",
        payload: {
          type: "success",
          msg: `Successfully redeemed promotional credit of £${data.creditAmount}`,
        },
      });
    } catch (error) {
      messages.dispatch({
        type: "send",
        payload: {
          type: "error",
          msg: error.response.data.msg,
        },
      });
    }
  };
  */

  return (
    <div>
      {!!userPromotionalBalance && (
        <div className="user-account-promobal">
          <div className="row gutter no-padding">
            <Star className="promobal-icon" />
              
            <div className="promobal-label">
              {"Credit: " + parseFloat(userPromotionalBalance).toLocaleString("en-GB", {
                style: "currency",
                currency: "GBP",
              })}
            </div>  
          </div>
          {/*
          <button
            className={`promobal-add ${showCodeForm ? "close" : ""}`}
            onClick={() => setShowCodeForm(!showCodeForm)}
          >
            +
          </button>

          {showCodeForm && (
            <form onSubmit={onRedeemCode}>
              <div className="form-row promobal-code-form">
                <label htmlFor="code">Promo Code</label>
                <input
                  type="text"
                  className="input"
                  name="code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Type code and press enter"
                />
              </div>
            </form>
          )}
          */}
        </div>
      )}
    </div>
  );
};

export default MyPromotionalBalance;
