import React from "react";

const LandingHeader = () => {
  return (
    <div className="row">
      <div className="col">
        <div className="landing-logo" />
      </div>
    </div>
  )
};

export default LandingHeader;