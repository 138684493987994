import React from "react";

const SortBySwitcher = ({ sortOptions = [], sortBy, setSortBy, className }) => {
  return (
    <div
      className={`btn-switcher ${className} ${
        sortBy === sortOptions[1].sort ? "active-two" : "active-one"
      }`}
    >
      {sortOptions.map(({ sort, name }) => (
        <button
          key={name}
          className={sortBy === sort ? "active" : ""}
          onClick={() => setSortBy(sort)}
        >
          {name}
        </button>
      ))}
    </div>
  );
};

export default SortBySwitcher;
