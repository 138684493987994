import React from "react";
import Competitions from "./Competitions";
import HowDoesItWork from "./HowDoesItWork";
import RaisingMoneyFor from "./RaisingMoneyFor";
import Winners from "./Winners";

const HomePage = () => {
  return (
    <div>
      <Competitions />

      <HowDoesItWork />

      <RaisingMoneyFor />

      <Winners />
    </div>
  );
};

export default HomePage;
