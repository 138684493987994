import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import RaffleImageCarousel from "./RaffleImageCarousel";
import ReactMarkdown from "react-markdown";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import useMediaQuery from "../../../../utils/useMediaQuery";

const RaffleInfo = ({ timeRemaining, raffle }) => {
  const {
    name,
    charity,
    charityPercentage,
    originalPrice,
    price,
    onSale,
    images,
    description,
    maxEntries,
    maxEntriesPerPerson,
    ticketsPurchased,
  } = raffle;

  const [progressBar, setProgressBar] = useState(0);

  const isMobile = useMediaQuery("(max-width: 1300px)");

  useEffect(() => {
    setProgressBar((ticketsPurchased / maxEntries));
  }, [ticketsPurchased, maxEntries]);

  const winSection = isMobile ? (
    <div className="row">
      <div className="col-12 raffle-specifics center">
        <div className="support-and-win">
          <h1 className="support">{price > 0 ? `Support ${charity?.name} and` : "Free Entry Competition"}</h1>
          <h1 className="win">Win a {name}</h1>
        </div>
      </div>
      <div className="col-12 raffle-specifics">
        {raffle.videoUrl && timeRemaining && timeRemaining <= 0 ? (
          <div className="live-draw-section">
            <iframe
              title="livestream"
              className="image-livestream"
              src={raffle.videoUrl}
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen={true}
              scrolling="no"
              style={{ border: "none", overflow: "hidden" }}
            />
          </div>
        ) : (
          <div className="tickets-overview">
            <div className="row gutter">
              <div className="col-6 no-margin">
                <div className="progress-bar">
                  <CircularProgressbarWithChildren
                    value={progressBar}
                    strokeWidth={16}
                    maxValue={1}
                  >
                    <div className="tickets">
                      <p className="sold">{ticketsPurchased} sold</p>
                      <p className="total">out of {maxEntries}</p>
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              </div>
              <div className="col-6 no-margin" style={price <= 0 ? { display: "none" } : {}}>
                <div className="percentage-sales">
                  <div className="charities">
                    <span className="donation">
                      {charityPercentage}% of ticket sales will be donated to
                    </span>
                    <span className="charity-name">{charity.name}</span>
                    <span className="work-for-good">
                      <small>via</small>
                    </span>
                    <div
                      className="charity-image"
                      onClick={() => window.open(charity.url, "_blank")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="col-12 raffle-specifics">
        <div className="tickets-overview">
          <a href="#ticket-selector" className={"btn price-btn" + (onSale ? " sale" : "")}>
            <span className={"quantity" + (onSale ? " sale" : "")}>
              {maxEntries === ticketsPurchased ? "Sold Out" : "1x ticket"}
            </span>
            <div className="ticket-prices">
              <p>
                {onSale && (
                <small>
                  <s>
                    {originalPrice.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
                  </s>
                </small>
              )}{" "}
              {price === 0
                ? "FREE"
                : price.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
              </p>
            </div>
          </a>
          <div className="ticket-stats">
            <p className="first">Max entries p/p: {maxEntriesPerPerson}</p>
              <p>Total tickets: {maxEntries}</p>
              <p>Max Odds: {maxEntries}/1</p>
          </div>
          {price > 0 && (
            <div className="free-entry">
              <span className="free-entry">See our <Link to="/p/terms-and-conditions#free-entry">terms</Link> for free postal entry</span>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="row">
      <div className="col-1 raffle specifics" style={price > 0 || (raffle.videoUrl && timeRemaining && timeRemaining <= 0) ? { display: "none" } : {}} />
      <div className="col-4 raffle-specifics">
        <div className="support-and-win">
          <h1 className="support">{price > 0 ? `Support ${charity?.name} and` : "Free Entry Competition"}</h1>
          <h1 className="win">Win a {name}</h1>
        </div>
      </div>
      <div className="col-2 raffle specifics" style={price > 0 || (raffle.videoUrl && timeRemaining && timeRemaining <= 0) ? { display: "none" } : {}} />
      {price <= 0 && raffle.videoUrl && timeRemaining && timeRemaining <= 0 && (
        <div className="col-4 raffle-specifics">
          <div className="live-draw-section">
            <iframe
              title="livestream"
              className="image-livestream"
              src={raffle.videoUrl}
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen={true}
              scrolling="no"
              style={{ border: "none", overflow: "hidden" }}
            />
          </div>
        </div>
      )}
      <div className="col-4 raffle-specifics">
        {price > 0 && raffle.videoUrl && timeRemaining && timeRemaining <= 0 ? (
          <div className="live-draw-section">
            <iframe
              title="livestream"
              className="image-livestream"
              src={raffle.videoUrl}
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen={true}
              scrolling="no"
              style={{ border: "none", overflow: "hidden" }}
            />
          </div>
        ) : (
          <div className="tickets-overview">
            <div className="row gutter">
              <div className="col-5 no-margin">
                <div className="progress-bar">
                  <CircularProgressbarWithChildren
                    value={progressBar}
                    strokeWidth={16}
                    maxValue={1}
                  >
                    <div className="tickets">
                      <p className="sold">{ticketsPurchased} sold</p>
                      <p className="total">out of {maxEntries}</p>
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              </div>
              <div className="col-7 center no-margin">
                <a href="#ticket-selector" className={"btn price-btn" + (onSale ? " sale" : "")}>
                  {onSale && (
                    <small>
                      <s>
                        {originalPrice.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
                      </s>
                    </small>
                  )}{" "}
                  {price === 0
                    ? "FREE"
                    : price.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}
                  <span className={"quantity" + (onSale ? " sale" : "")}>{maxEntries === ticketsPurchased ? "Sold Out" : "1x ticket"}</span>
                </a>
                <div className="ticket-stats">
                  <p className="first">Max entries p/p: {maxEntriesPerPerson}</p>
                  <p>Total tickets: {maxEntries}</p>
                  <p>Max Odds: {maxEntries}/1</p>
                </div>
              </div>
            </div>
            {price > 0 && (
              <div className="row gutter no-padding">
                <div className="col no-margin" style={{ textAlign: "center", paddingBottom: "10px" }}>
                  <span className="free-entry">See our <Link to="/p/terms-and-conditions#free-entry">terms</Link> for free postal entry</span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="col-4 raffle-specifics" style={price <= 0 ? { display: "none" } : {}}>
        <div className="tickets-overview">
          <div className="percentage-sales">
            <div className="charities">
              <span className="donation">
                {charityPercentage}% of ticket sales will be donated to
              </span>
              <span className="charity-name">{charity.name}</span>
              <span className="work-for-good">
                <small>via</small>
              </span>
              <div
                className="charity-image"
                onClick={() => window.open(charity.url, "_blank")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="raffle-info">
      <div className="cta-slab carousel curve">
        <div className="row carousel">
          <div className="col no-margin">
            <RaffleImageCarousel onSale={onSale} images={images} />
          </div>
        </div>
      </div>

      <div className="row no-padding">
        <div className="col">
          <h1 className="draw-time">
            {!timeRemaining ? (
              <LoadingSpinner style={{ margin: "20px 0" }} />
            ) : (
              <div className="countdown-numbers">
                <div className="countdown-number">
                  <span>
                    {timeRemaining > 0 ? (
                      `Entries Close in ${timeRemaining > 0 ? Math.floor(moment.duration(timeRemaining).asDays()) : 0} days, 
                      ${timeRemaining > 0 ? moment.duration(timeRemaining).hours() : 0} hours, 
                      ${timeRemaining > 0 ? moment.duration(timeRemaining).minutes() : 0} mins and 
                      ${timeRemaining > 0 ? moment.duration(timeRemaining).seconds() : 0} secs`
                    ) : (
                      "TICKET SALES CLOSED"
                    )}
                  </span>
                </div>
              </div>
            )}
          </h1>
        </div>
      </div>

      <div className="cta-slab carousel">{winSection}</div>

      <div className="row raffle-description no-bottom">
        <div className="col">
          <ReactMarkdown
            className="md-text white"
            linkTarget="_blank"
            children={description || <em>No description available</em>}
          />
        </div>
      </div>
    </div>
  );
};

export default RaffleInfo;
