import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Axios from "axios";

import OrderCartSummary from "../cart-summary/OrderCartSummary";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import { AppContext } from "../../../../context/AppContext";

const VerifyPayment = ({ checkoutId, resourcePath }) => {
  const { messages } = useContext(AppContext);
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(false);
  const [orderId, setOrderId] = useState(false);

  const [tickets, setTickets] = useState([]);
  const [charityTotal, setCharityTotal] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(false);
  const [promoBalDiscount, setPromoBalDiscount] = useState(false);
  const [orderSubtotal, setOrderSubtotal] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);

  const dispatchMessage = messages.dispatch;

  const usePromotionalBalance = localStorage.getItem("usePromoBal") === "true";
  const couponCode = localStorage.getItem("appliedCouponCode");
  const newsletter = localStorage.getItem("cartSignupNewsletter") === "true";

  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_API}/api/payments/setup`, {
      usePromotionalBalance,
      couponCode,
      newsletter,
    }).then(({ data }) => {
      if (!data.tickets.length) {
        return history.push("basket");
      }

      setTickets(data.tickets);
      setCharityTotal(data.charityTotal);
      setOrderSubtotal(data.subtotal);
      setCouponDiscount(data.couponDiscount);
      setPromoBalDiscount(data.promoBalDiscount);
      setOrderTotal(data.totalPrice);

      setLoading(false);
      
      Axios.get(`${process.env.REACT_APP_API}/api/payments/callback?checkoutId=${checkoutId}&couponCode=${localStorage.appliedCouponCode}&resourcePath=${resourcePath}&usePromoBal=${localStorage.usePromoBal}`)
        .then(({ data }) => {
          if (data.orderId) return setOrderId(data.orderId);
        }).catch((err) => {
          if (err.response.data) {
            if (err.response.data.orderId) {
              history.push(`order-confirmation/${err.response.data.orderId}?ref=orderComplete`);
              return;
            }

            if (err.response.data.msg) {
              setError(err.response.data.msg);
            }
          }
        });
    }).catch(() => {
      setLoading(false);
      history.push("basket");

      dispatchMessage({
        type: "send",
        payload: {
          type: "error",
          msg: "There was a problem retrieving your cart. Please try again later.",
        },
      });
    });
  }, [checkoutId, couponCode, dispatchMessage, history, newsletter, resourcePath, usePromotionalBalance]);

  useEffect(() => {
    if (!orderId) return;

    const timeoutID = setTimeout(() => {
      history.push(`/order-confirmation/${orderId}`);
    }, 5000);

    return () => clearTimeout(timeoutID);
  }, [history, orderId]);

  return (
    <div>
      {loading ? (
        <div className="center">
          <LoadingSpinner dark={true} />
        </div>
      ) : (
        <div className="row gutter no-padding center-cols">
          <div className="col-4">
            <OrderCartSummary
              tickets={tickets}
              charityTotal={charityTotal}
              subtotal={orderSubtotal}
              couponDiscount={couponDiscount}
              promoBalDiscount={promoBalDiscount}
              orderTotal={orderTotal}
            />
          </div>
          
          <div className="col-6">
            <div className="cart-main cart-items">
              <div className="row no-padding">
                <div className="col-12 no-margin">
                  {!error && !orderId ? (
                    <>
                      <h3 className="processing-header">Your payment is currently processing...</h3>
                      <LoadingSpinner className="center" dark />
                    </>
                  ) : error ? (
                    <>
                      <div className="checkmark error">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-x"
                        >
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                      </div>

                      <h3 className="processing-header">There has been an error processing your payment!</h3>
                      <p className="payment-message error">{error}</p>

                      <Link className="redirect-message" to="/order-payment">Try again?</Link>
                    </>
                  ) : (
                    <>
                      <div className="checkmark success">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-check"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </div>

                      <h3 className="processing-header">Payment Successful!</h3>
                      <p className="payment-message">You will be automatically redirected in 5 seconds...</p>

                      <Link className="redirect-message" to={`/order-confirmation/${orderId}?ref=orderComplete`}>Not redirecting?</Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerifyPayment;