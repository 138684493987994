import React, { useState, useContext, useEffect } from "react";
import { Edit2, Trash2 } from "react-feather";
import Dropzone from "react-dropzone";
import Axios from "axios";
import { AppContext } from "../../../../context/AppContext";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import RafflesRestriction from "../../includes/RafflesRestriction";
import UsersRestriction from "../../includes/UsersRestriction";

const ConfigWinners = () => {
  const { messages } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  const [winners, setWinners] = useState([]);
  const [editingItem, setEditingItem] = useState(-1);
  const [showAddModal, setShowAddModal] = useState(false);

  const [appliesToRaffles, setAppliesToRaffles] = useState([]);
  const [appliesToUsers, setAppliesToUsers] = useState([]);
  const [image, setImage] = useState("");
  const [ticketNumber, setTicketNumber] = useState("");
  const [liveDrawUrl, setLiveDrawUrl] = useState("");
  const [onlineRaffleUrl, setOnlineRaffleUrl] = useState("");

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/winners/all/1`).then(({ data }) => {
      setWinners(data.winners);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setAppliesToRaffles([]);
    setAppliesToUsers([]);
    setImage("");
    setTicketNumber("");
    setLiveDrawUrl("");
    setOnlineRaffleUrl("");
  }, [showAddModal]);

  useEffect(() => {
    if (editingItem < 0) {
      return;
    } else if (editingItem >= winners.length) {
      setEditingItem(-1);
      return;
    }
    
    const winner = winners[editingItem];

    setAppliesToRaffles([winner.raffle]);
    setAppliesToUsers([winner.user]);
    setImage(winner.image);
    setTicketNumber(winner.ticketNumber);
    setLiveDrawUrl(winner.liveDrawUrl);
    setOnlineRaffleUrl(winner.onlineRaffleUrl);
  }, [editingItem, winners]);

  const addItem = (e) => {
    e.preventDefault();

    if (!appliesToRaffles.length || !appliesToUsers.length || !image || !ticketNumber || !onlineRaffleUrl)
      return messages.dispatch({
        type: "send",
        payload: { type: "error", msg: "All fields required" },
      });

    const winner = { raffleId: appliesToRaffles[0]._id, userId: appliesToUsers[0]._id, image, ticketNumber, liveDrawUrl, onlineRaffleUrl };

    if (editingItem > -1) {
      setLoading(true);

      Axios.patch(`${process.env.REACT_APP_API}/api/winners/${winners[editingItem]._id}`, winner)
        .then(({ data }) => {
          winners[editingItem] = data.winner;

          messages.dispatch({
            type: "send",
            payload: { type: "success", msg: "Successfully edited winner" }
          });

          setEditingItem(-1);
          setWinners(winners);
          setLoading(false);
        });
    } else {
      Axios.post(`${process.env.REACT_APP_API}/api/winners`, winner)
        .then(({ data }) => {
          const newWinners = [data.winner, ...winners];

          messages.dispatch({
            type: "send",
            payload: { type: "success", msg: "Successfully added winner" }
          });

          setShowAddModal(false);
          setWinners(newWinners);
        }).catch((err) => {
          if (err.response && err.response.data)
            messages.dispatch({
              type: "send",
              payload: { type: "error", msg: err.response.data.msg }
            });
        });
      }
  };

  const deleteItem = (index) => {
    if (window.confirm("Are you sure you want to delete this winner?")) {
      const winner = winners[index];
      const newWinners = winners.filter((_, i) => i !== index);

      setLoading(true);

      Axios.delete(`${process.env.REACT_APP_API}/api/winners/${winner._id}`)
        .then(() => {
          messages.dispatch({
            type: "send",
            payload: { type: "success", msg: "Successfully deleted winner" }
          });

          setWinners(newWinners);
          setLoading(false);
        }).catch((err) => {
          if (err.response && err.response.data)
            messages.dispatch({
              type: "send",
              payload: { type: "error", msg: err.response.data.msg }
            });

          setLoading(false);
        });
    }
  };

  const uploadImage = (e) => {
    if (!e[0]) return false;

    const formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("image", e[0]);

    Axios.post(`${process.env.REACT_APP_API}/api/imageUpload`, formData, config)
      .then(( { data } ) => {
        if (data.file) setImage(data.file);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          messages.dispatch({
            type: "send",
            payload: { type: "error", msg: err.response.data.msg }
          });
        }
      });
  };

  return (
    <div>
      {showAddModal && (
        <div className="modal-container">
        <div className="modal-bg" onClick={() => setShowAddModal(false)}></div>
        <div className="modal">
          <h2>Add Winner</h2>

          <form onSubmit={addItem}>
            <div className="form-row">
              <label htmlFor="image">Image</label>
              <Dropzone
                multiple={false}
                accept={["image/webp", "image/jpeg", "image/jpg", "image/png", "image/gif"]}
                onDropAccepted={(e) => uploadImage(e)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="input-dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>

              {image && (
                <div
                  className="form-image"
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                />
              )}
            </div>

            <div className="form-row">
              <label>Raffle Selector</label>
              <RafflesRestriction
                all={true}
                appliesToRaffles={appliesToRaffles}
                multiple={false}
                setAppliesToRaffles={setAppliesToRaffles}
              />
            </div>

            <div className="form-row">
              <label>User Selector</label>
              <UsersRestriction
                appliesToUsers={appliesToUsers}
                multiple={false}
                setAppliesToUsers={setAppliesToUsers}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Ticket Number">Ticket Number</label>
              <input
                type="text"
                id="ticketNumber"
                className="admin-input"
                value={ticketNumber}
                onChange={(e) => setTicketNumber(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Live Draw URL">Live Draw URL</label>
              <input
                type="text"
                id="liveDrawUrl"
                className="admin-input"
                value={liveDrawUrl}
                onChange={(e) => setLiveDrawUrl(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Online Raffle URL">Online Raffle URL</label>
              <input
                type="text"
                id="onlineRaffleUrl"
                className="admin-input"
                value={onlineRaffleUrl}
                onChange={(e) => setOnlineRaffleUrl(e.target.value)}
              />
            </div>

            <button type="submit" className="btn btn-admin">
              Add
            </button>
          </form>
        </div>
      </div>
      )}

      {editingItem > -1 && (
        <div className="modal-container">
        <div className="modal-bg" onClick={() => setEditingItem(-1)}></div>
        <div className="modal">
          <h2>Edit Winner</h2>

          <form onSubmit={addItem}>
            <div className="form-row">
              <label htmlFor="image">Image</label>
              <Dropzone
                multiple={false}
                accept={["image/webp", "image/jpeg", "image/jpg", "image/png", "image/gif"]}
                onDropAccepted={(e) => uploadImage(e)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="input-dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>

              {image && (
                <div
                  className="form-image"
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                />
              )}
            </div>

            <div className="form-row">
              <label>Raffle Selector</label>
              <RafflesRestriction
                all={true}
                appliesToRaffles={appliesToRaffles}
                multiple={false}
                setAppliesToRaffles={setAppliesToRaffles}
              />
            </div>

            <div className="form-row">
              <label>User Selector</label>
              <UsersRestriction
                appliesToUsers={appliesToUsers}
                multiple={false}
                setAppliesToUsers={setAppliesToUsers}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Ticket Number">Ticket Number</label>
              <input
                type="text"
                id="ticketNumber"
                className="admin-input"
                value={ticketNumber}
                onChange={(e) => setTicketNumber(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Live Draw URL">Live Draw URL</label>
              <input
                type="text"
                id="liveDrawUrl"
                className="admin-input"
                value={liveDrawUrl}
                onChange={(e) => setLiveDrawUrl(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="Online Raffle URL">Online Raffle URL</label>
              <input
                type="text"
                id="onlineRaffleUrl"
                className="admin-input"
                value={onlineRaffleUrl}
                onChange={(e) => setOnlineRaffleUrl(e.target.value)}
              />
            </div>

            <button type="submit" className="btn btn-admin">
              Edit
            </button>
          </form>
        </div>
      </div>
      )}

      <div className="config-section">
        <h2 className="admin-subtitle">Winners</h2>

        {/* Table */}
        <table className="config-table">
          <thead>
            <tr>
              <th style={{ width: "250px" }}>Image</th>
              <th>Raffle</th>
              <th>User</th>
              <th>Ticket Number</th>
              <th>Live Draw</th>
              <th>Online Raffle URL</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="6">
                  <LoadingSpinner dark={true} className="center" />
                </td>
              </tr>
            ) : !winners.length ? (
              <tr>
                <td colSpan="7" className="no-raffles">
                  No winners to show
                </td>
              </tr>
            ) : (
              winners.map(({ image, liveDrawUrl, onlineRaffleUrl, ticketNumber, raffle, user }, i) => (
                <tr key={i}>
                  <td>
                    <img src={`${image}`} alt="Raffle" className="raffle-table-image" />
                  </td>
                  <td>
                    <a rel="noopener noreferrer" target="_blank" href={`/c/${raffle.slug}`}>
                      {raffle.name}
                    </a>
                  </td>
                  <td>{(user.firstName + " " + user.lastName)}</td>
                  <td>{ticketNumber}</td>
                  <td>
                    <a rel="noopener noreferrer" target="_blank" href={liveDrawUrl}>
                      {liveDrawUrl}
                    </a>
                  </td>
                  <td>
                    <a rel="noopener noreferrer" target="_blank" href={onlineRaffleUrl}>
                      {onlineRaffleUrl}
                    </a>
                  </td>
                  <td className="right">
                    <button className="delete-btn" onClick={() => setEditingItem(i)}>
                      <Edit2 />
                    </button>
                    <button className="delete-btn" onClick={() => deleteItem(i)}>
                      <Trash2 />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        <button
          onClick={() => setShowAddModal(true)}
          className="btn btn-admin btn-small"
          style={{ marginTop: 10 }}
        >
          Add New
        </button>
      </div>
    </div>
  );
};

export default ConfigWinners;
