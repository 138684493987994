import React, { useState, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { useHistory, useParams } from "react-router-dom";
import Axios from "axios";
import RaffleModifyHeader from "./includes/RaffleModifyHeader";
import RaffleModifyInfo from "./includes/RaffleModifyInfo";
import RaffleModifyDesc from "./includes/RaffleModifyDesc";
import RaffleModifyCategorySearch from "./includes/RaffleModifyCategorySearch";
import RaffleModifyCharitySearch from "./includes/RaffleModifyCharitySearch";
import RaffleModifyTicketGen from "./includes/RaffleModifyTicketsGen";
import RaffleModifyVideoLink from "./includes/RaffleModifyVideoLink";
import RaffleImageUpload from "./includes/image-upload/RaffleImageUpload";
import RaffleModifyQuestions from "./includes/RaffleModifyQuestions";
import RaffleModifyInstantWins from "./includes/RaffleModifyInstantWins";

const RaffleModifyView = ({ type, raffleData }) => {
  const { id } = useParams();
  const { messages } = useContext(AppContext);
  const history = useHistory();
  const [formData, setFormData] = useState(raffleData);
  const [showImagesModal, setShowImagesModal] = useState(false);

  const update = (key, value) => {
    setFormData((data) => ({ ...data, [key]: value }));
  };

  const publish = (e) => {
    e.preventDefault();

    if (type === "edit") {
      Axios.patch(`${process.env.REACT_APP_API}/api/raffles/${id}`, { formData })
        .then(() => {
          messages.dispatch({
            type: "send",
            payload: { type: "success", msg: "Raffle was successfully updated" },
          });
          history.push(`/admin/raffles/${id}`);
        })
        .catch(() => {
          messages.dispatch({
            type: "send",
            payload: {
              type: "error",
              msg: "There was a problem updating this raffle. Please try again later.",
            },
          });
        });
    } else if (type === "create") {
      Axios.post(`${process.env.REACT_APP_API}/api/raffles`, { formData })
        .then(() => {
          messages.dispatch({
            type: "send",
            payload: { type: "success", msg: "Raffle was successfully created" },
          });
          history.push("/admin/raffles");
        })
        .catch(() => {
          messages.dispatch({
            type: "send",
            payload: {
              type: "send",
              msg: "There was a problem creating this raffle. Please try again later.",
            },
          });
        });
    }
  };

  return (
    <div className="row admin-raffle-page">
      <div className="col">
        {showImagesModal && (
          <RaffleImageUpload
            images={formData.images}
            update={update}
            closeModal={() => setShowImagesModal(false)}
          />
        )}

        <form onSubmit={publish}>
          <RaffleModifyHeader
            type={type}
            name={formData.name}
            live={formData.live}
            update={update}
          />

          <RaffleModifyInfo
            image={formData.images.find(({ keyImage }) => keyImage === true)}
            price={formData.price}
            originalPrice={formData.originalPrice}
            onSale={formData.onSale}
            startDate={formData.startDate}
            endDate={formData.endDate}
            update={update}
            openModal={() => setShowImagesModal(true)}
          />

          <RaffleModifyDesc description={formData.description} update={update} />

          <RaffleModifyInstantWins description={formData.instantWinsDescription} prizes={formData.instantWins} update={update} />

          <RaffleModifyCategorySearch
            categories={formData.categories}
            searchTags={formData.searchTags}
            update={update}
          />

          <RaffleModifyCharitySearch
            charity={formData.charity}
            charityPercentage={formData.charityPercentage}
            update={update}
          />

          <div className="row no-padding gutter">
            <div className="col-6">
              <RaffleModifyTicketGen
                couponCodePercentage={formData.couponCodePercentage}
                luckyDipDefault={formData.luckyDipDefault}
                maxEntries={formData.maxEntries}
                maxEntriesPerPerson={formData.maxEntriesPerPerson}
                ticketMatrix={formData.ticketMatrix}
                guaranteedWinnerInfoBox={formData.guaranteedWinnerInfoBox}
                update={update}
              />
            </div>
            <div className="col-6">
              <RaffleModifyVideoLink url={formData.videoUrl} update={update} />
            </div>
          </div>
          
          <div className="row no-padding gutter">
            <div className="col-6">
              <RaffleModifyQuestions
                question={formData.question && formData.question.question}
                answers={formData.question && formData.question.answers}
                update={update}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RaffleModifyView;
