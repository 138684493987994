import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import { useHistory, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import LoadingSpinner from "../../includes/LoadingSpinner";

const DynamicPage = () => {
  const { slug } = useParams();
  const history = useHistory();
  const { messages } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    const ref = window.location.href;

    if (ref.includes("#")) {
      const elem = document.getElementById(ref.split("#")[1]);

      if (elem)
        elem.scrollIntoView();
    }
  }, [loading]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/dynamicPages/${slug}`).then(({ data }) => {
      if (!data.content) {
        return history.push("/error");
      }

      setPageData(data);
      setLoading(false);
    });
  }, [slug, messages, history]);

  return (
    <div className="cta-slab">
      {loading ? (
        <LoadingSpinner className="center" dark />
      ) : (
        <div className="row">
          {pageData && (
            <Helmet>
              <title>Pryze | {pageData.name}</title>
            </Helmet>
          )}
          
          <div className="col">
            <div className="row no-padding">
              <div className="col">
                <h1 className="center">{pageData.name}</h1>
                <ReactMarkdown
                  className="md-text"
                  linkTarget="_blank"
                  allowDangerousHtml={true}
                  children={pageData.content}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DynamicPage;
