import React, { useState, useEffect } from "react";
import Axios from "axios";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import RaffleCard from "./RaffleCard";
import Slider from "react-slick";
import useMediaQuery from "../../../../utils/useMediaQuery";

const OtherRaffles = ({ className, exclude }) => {
  const [loading, setLoading] = useState(false);
  const [raffles, setRaffles] = useState([]);

  const isMobile = useMediaQuery("(max-width: 1300px)");

  useEffect(() => {
    const excluded = new Set();

    if (exclude)
      exclude.forEach((exclude) => excluded.add(exclude));

    setLoading(true);

    Axios.get(`${process.env.REACT_APP_API}/api/raffles/all`).then(
      ({ data }) => {
        const raffles = [];

        for (var i = 0; i < data.raffles.length; i++) {
          if (!excluded.has(data.raffles[i]._id)) {
            raffles.push(data.raffles[i]);
          }
        }

        setRaffles(raffles);
        setLoading(false);
      }
    );
  }, [exclude]);

  return raffles.length > 0 ? (
    <div className={className ? className : "cta-slab no-margin"}>
      <div className="other-raffles-header">
        <h1>You may also be interested in...</h1>
      </div>
      <div className="row gutter" style={{ maxWidth: "1300px" }}>
        <div className="col">
          {loading ? (
            <LoadingSpinner className="center" dark />
          ) : (
            <Slider
              arrows={false}
              className="testimonial-slider"
              dots={true}
              slidesToShow={isMobile ? 1 : 3}
            >
              {raffles && raffles.map((raffle, i) => (
                <div className="carousel-slide" key={i}>
                  <RaffleCard
                    className="also-interested"
                    {...raffle}
                  />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default OtherRaffles;