import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { AppContext } from "../context/AppContext";

import PageNotFound from "./pages/error/PageNotFound";
import MainLayout from "./layouts/MainLayout";
import GuestRoute from "./routes/GuestRoute";
import Login from "./auth/Login";
import Register from "./auth/Register";
import Logout from "./auth/Logout";
import ForgotPassword from "./auth/ForgotPassword";
import VerificationResult from "./auth/VerificationResult";
import VerifyEmail from "./auth/VerifyEmail";
import ResetPassword from "./auth/ResetPassword";

const AuthRouter = ({ landing }) => {
  const { user } = useContext(AppContext);

  return landing && (!user.state || !user.state.isAuthenticated || !user.state.admin) ? (
    <MainLayout>
      <Switch>
        <GuestRoute path="/auth/login">
          <Login />
        </GuestRoute>
        <Route path="/auth/logout">
          <Logout />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </MainLayout>
  ) : (
    <MainLayout>
      <Switch>
        <GuestRoute path="/auth/login">
          <Login />
        </GuestRoute>
        <GuestRoute path="/auth/register">
          <Register />
        </GuestRoute>
        <GuestRoute path="/auth/forgot-password">
          <ForgotPassword />
        </GuestRoute>
        <GuestRoute path="/auth/reset-password/:email/:token">
          <ResetPassword />
        </GuestRoute>
        <Route path="/auth/logout">
          <Logout />
        </Route>
        <Route path="/auth/verify-email/success">
          <VerificationResult success={true} />
        </Route>
        <Route path="/auth/verify-email/error">
          <VerificationResult success={false} />
        </Route>
        <Route path="/auth/verify-email/:email/:code">
          <VerifyEmail />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </MainLayout>
  );
};

export default AuthRouter;
