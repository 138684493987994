import React, { useState, useEffect, useContext } from "react";
import LoadingSpinner from "../../../includes/LoadingSpinner";
import Axios from "axios";
import { AppContext } from "../../../../context/AppContext";

const Charity = () => {
  const { user } = useContext(AppContext);

  const [contributions, setContributions] = useState([]);
  const [total, setTotal] = useState("0");
  const [userTotal, setUserTotal] = useState("0");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      Axios.get(`${process.env.REACT_APP_API}/api/contributions`).then(({ data }) => {
        let sum = 0;

        data.contributions.map((contribution) => sum += contribution.total);
        setContributions(data.contributions);

        if (sum > 1000) setUserTotal(sum.toLocaleString("en-GB", { style: "currency", currency: "GBP", minimumFractionDigits: 0, maximumFractionDigits: 0 }));
        else setUserTotal(sum.toLocaleString("en-GB", { style: "currency", currency: "GBP" }));
      }),
      Axios.get(`${process.env.REACT_APP_API}/api/contributions/total`).then(({ data }) => {
        if (data.total > 1000) setTotal(data.total.toLocaleString("en-GB", { style: "currency", currency: "GBP", minimumFractionDigits: 0, maximumFractionDigits: 0 }));
        else setTotal(data.total.toLocaleString("en-GB", { style: "currency", currency: "GBP" }));
      })
    ]).then(() => setLoading(false));
  }, [user.state._id]);

  return (
    <div style={{ width: "100%" }}>
      <div className="row no-padding user-account-main-header">
        <div className="col">
          <h1>Charitable Contributions</h1>
        </div>
      </div>

      {loading ? (
        <div className="row gutter">
          <div className="col">
            <LoadingSpinner className="center" dark={true} />
          </div>
        </div>
      ) : (
        <>
          <div className="row gutter no-padding">
            <div className="col-6">
              <div className="contribution">
                <div className="contribution-inner overview">
                  <p className="contribution-amount">{total}</p>
                  <p className={"contribution-text main" + (total.includes(",") ? " comma" : "")}>
                    Total charity contributions made from ticket sales
                  </p>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="contribution">
                <div className="contribution-inner overview">
                  <p className="contribution-amount">{userTotal}</p>
                  <p className={"contribution-text main" + (userTotal.includes(",") ? " comma" : "")}>
                    Your charity contributions from ticket purchases
                  </p>
                </div>
              </div>
            </div>
          </div>

          {contributions && contributions.length > 0 && (
            <>
              <div className="row no-padding user-account-main-header">
                <div className="col">
                  <h1>You have helped...</h1>
                </div>
              </div>

              <div className="row gutter no-padding">
                {contributions.map((contribution, i) => (
                  <div className="col-4" key={i}>
                    <div className="contribution">
                      <div className="contribution-inner">
                        <div
                          className="contribution-logo"
                          onClick={() => window.open(contribution.charity.url, "_blank")}
                          style={{ backgroundImage: `url("${contribution.charity.logo}")` }}
                        />
                        <p className="contribution-amount helped">
                          {contribution.total.toLocaleString("en-GB", { style: "currency", currency: "GBP", minimumFractionDigits: (contribution.total > 1000 ? 0 : 2), maximumFractionDigits: (contribution.total > 1000 ? 0 : 2) })}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                {[contributions % 3].fill().map((_, i) => (
                  <div className="col-4" key={i} />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Charity;
