import { AppContext } from "../../../context/AppContext";
import { ChevronDown, ChevronUp } from "react-feather";
import React, { useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

const Faq = () => {
  const { config } = useContext(AppContext);
  const [faq, setFaq] = useState(false);
  const [tabOpen, setTabOpen] = useState(false);

  useEffect(() => {
    if (!config || !config.state) {
      return;
    }

    setFaq(config.state.find(({ type }) => type === "faq"));
  }, [config, config.state]);

  const openTab = (index) => {
    setTabOpen(tabOpen !== index ? index : false);
  };

  return (
    <div className="cta-slab">
      <div className="row">
        <div className="col">
          <h1 className="title">Frequently Asked Questions</h1>

          <div className="faqs row no-padding gutter">
            {faq &&
              faq.faq.map(({ question, answer }, i) => (
                <div key={i} className="col-6">
                  <div className={`faq ${i % 2 === 0 ? "right" : "left"} ${tabOpen === i ? " open" : ""}`}>
                    <div onClick={() => openTab(i)} className="faq-question">
                      {question}
                      {tabOpen === i ? <ChevronUp style={{ color: "white" }} /> : <ChevronDown />}
                    </div>
                    <div className="faq-answer">
                      <ReactMarkdown className="md-text" linkTarget="_blank" children={answer} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;