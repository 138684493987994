import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Axios from "axios";
import LoadingSpinner from "../../includes/LoadingSpinner";
import RaffleCard from "../raffles/includes/RaffleCard";
import useMediaQuery from "../../../utils/useMediaQuery";
import moment from "moment";
import { AppContext } from "../../../context/AppContext"
import { Helmet } from "react-helmet";

const Competitions = ({ home = true, viewMore = true }) => {
  const { config } = useContext(AppContext);
  const location = useLocation();

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(false);
  const [loading, setLoading] = useState(true);
  const [liveDraw, setLiveDraw] = useState(false);
  const [nextDrawTime, setNextDrawTime] = useState(false);
  const [raffles, setRaffles] = useState([]);
  const [viewingMore, setViewingMore] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/categories`).then(
      ({ data }) => {
        const fetchedCategories = data.categories.sort((a, b) => a.name.localeCompare(b.name));

        Axios.get(`${process.env.REACT_APP_API}/api/raffles/byCategory`).then(
          ({ data }) => {
            const usedCategories = new Set();
            
            data.raffles.map((raffle, _) => usedCategories.add(raffle._id));

            setCategories(fetchedCategories.filter(cat => usedCategories.has(cat.name)));
            setViewingMore(false);
          }
        );
      }
    );

    Axios.get(`${process.env.REACT_APP_API}/api/liveDraws/live`).then(({ data }) => {
      const liveDraw = data.liveDraw;

      if (liveDraw)
        setLiveDraw(liveDraw);
    });
  }, []);

  useEffect(() => {
    if (categories && categories.length) {
      setLoading(true);

      Axios.get(`${process.env.REACT_APP_API}/api/raffles/${category ? "byCategory?category=" + category : "all"}`).then(
        ({ data }) => {
          setRaffles(data.raffles);
          setLoading(false);
          setViewingMore(false);
        }
      );
    }
  }, [category, categories]);

  useEffect(() => {
    if (!config || !config.state) {
      return;
    }

    let nextDrawDate = config.state.find(({ type }) => type === "next_draw") || {};
    
    if (nextDrawDate && nextDrawDate.date) {
      setNextDrawTime(moment.duration((moment(nextDrawDate.date).unix() - moment().unix()) * 1000, "milliseconds")._milliseconds);

      const intervalID = setInterval(() => {
        setNextDrawTime(moment.duration((moment(nextDrawDate.date).unix() - moment().unix()) * 1000, "milliseconds")._milliseconds);
      }, 1000);

      return () => clearInterval(intervalID);
    }
  }, [config, config.state]);
  
  const isActive = (catName) => {
    return (catName === "All" && !category) || (catName === category);
  };

  const isMobile = useMediaQuery("(max-width: 1300px)");

  const getTimePart = (part, time) => {
    let duration = moment.duration(time);

    switch (part) {
      case 1:
        return Math.floor(duration.asDays());
      case 2:
        return Math.floor(duration.hours());
      case 3:
        return Math.floor(duration.minutes());
      case 4:
        return Math.floor(duration.seconds());
      default:
        return 0;
    }
  };

  const setCat = (cat, catName) => {
    if (cat && !isActive(catName)) {
      setCategory(catName);
    } else if (!cat && !isActive(catName)) {
      setCategory(false);
    }
  };

  return (
    <div className={`cta-slab${!home ? " curve" : ""}`}>
      {location.pathname !== "/" && (
        <Helmet>
          <title>Pryze | Competitions</title>
        </Helmet>
      )}

      <div className="col">
        <div className="top-raffles">
          <h1 className="title">Live Competitions</h1>
          
          {liveDraw ? (
            <div className="subtitle bottom">
              <Link className="btn btn-blue live" to="/draws">
                <div className="live-dot"></div>
                <div><strong>Live Now</strong> - Watch Here</div>
              </Link>
            </div>
          ) : (
            <h2 className="subtitle bottom">
              Next draw in:{" "}
              {nextDrawTime ?
                nextDrawTime > 0 ?
                  `${getTimePart(1, nextDrawTime)} Days, ${getTimePart(2, nextDrawTime)} Hours, ${getTimePart(3, nextDrawTime)} Minutes, ${getTimePart(4, nextDrawTime)} Seconds`
                  : "Now"
                : "Loading..."
              }
            </h2>
          )}

          <div className="row gutter">
            <div className="category-buttons">
              <button
                className={"btn btn-" + (isActive("All") ? "blue" : "white") + " margin-bottom no-outline padded round spaced"}
                onClick={() => setCat(false, "All")}
              >
                All
              </button>
              {categories.map((cat, i) => (
                <button
                  className={"btn btn-" + (isActive(cat.name) ? "blue" : "white") + " margin-bottom no-outline padded round spaced"}
                  onClick={() => setCat(cat, cat.name)}
                  key={i}
                >
                  {cat.name}
                </button>
              ))}
            </div>
          </div>

          <div id="raffle-cards">
            {loading ? (
              <LoadingSpinner className="center" dark />
            ) : (
              <>
                <div className="row no-padding gutter">
                  {raffles && raffles.map((raffle, i) => {
                      if (viewMore && !viewingMore && i > 5) {
                        return null;
                      }
                      
                      return (
                        <div className="col-4" key={i}>
                          <RaffleCard className={(!isMobile && (i + 4) % 3 === 1) ? "float-right" : (!isMobile && (i + 4) % 3 === 0) ? "float-left" : ""} {...raffle} />
                        </div>
                      );
                    })}
                  {raffles && raffles.length > 0 && [raffles % 3].fill().map((_, i) => (
                    <div className="col-4" key={i} />
                  ))}
                  {(!raffles || raffles.length === 0) && (
                    <p className="no-raffles center">
                      👀 You're not missing anything right now. Check back later, or follow us on our social media channels @pryze to receive updates when a new competition is live in this category. Keep your eyes on the Pryze! 🌟
                    </p>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="row gutter">
            {viewMore && !viewingMore && raffles.length > 6 && (
              <div className="col center">
                <button
                  className="btn btn-white bottom no-outline padded round"
                  onClick={() => setViewingMore(!viewingMore)}
                >
                  View More
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Competitions;
