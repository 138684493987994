import React from "react";

import Faq from "./Faq";
import HowDoesItWork from "../home/HowDoesItWork";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>Pryze | About</title>
      </Helmet>

      <HowDoesItWork />
      <Faq />
    </>
  );
};

export default AboutPage;