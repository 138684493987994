import React from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import AdminRoute from "./components/routes/AdminRoute";
import MainRouter from "./components/MainRouter";
import AuthRouter from "./components/AuthRouter";
import AdminRouter from "./components/AdminRouter";
import { useEffect } from "react";
import { tokenValid } from "./utils/auth";
import { useContext } from "react";
import { AppContext } from "./context/AppContext";
import { useState } from "react";
import LoadingSpinner from "./components/includes/LoadingSpinner";
import Axios from "axios";
import MainLayout from "./components/layouts/MainLayout";
import CompleteRegistration from "./components/auth/CompleteRegistration";
import DynamicPage from "./components/pages/dynamic-page/DynamicPage";

const AppRouter = () => {
  const history = useHistory();
  const location = useLocation();
  const { user, config } = useContext(AppContext);

  const [loadingUser, setLoadingUser] = useState(true);
  const [showLandingPage, setShowLandingPage] = useState(false);

  const isAuthenticated = user.state.isAuthenticated;

  // When route changes, scroll to top
  useEffect(() => {
    const fbInit = async () => {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: process.env.REACT_APP_FACEBOOK_APP_ID,
          autoLogAppEvents: true,
          xfbml: true,
          version: "v13.0"
        });
      };
      
      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    };

    fbInit().then(() => window.scrollTo(0, 0));
  }, [location.pathname]);

  // Load in user data
  useEffect(() => {
    const validTokenData = tokenValid();

    if (validTokenData) {
      if (!isAuthenticated) {
        Axios.get(`${process.env.REACT_APP_API}/api/auth`)
          .then(({ data }) => {
            user.dispatch({
              type: "load",
              payload: { isAuthenticated: true, ...validTokenData, ...data.user },
            });
            setLoadingUser(false);
          })
          .catch(() => {
            localStorage.removeItem("sessionToken");
            history.push("/");
          });
      } else {
        setLoadingUser(false);
      }
    } else {
      user.dispatch({ type: "load", payload: { isAuthenticated: false } });
      setLoadingUser(false);
    }
  }, [history, location, isAuthenticated, user.dispatch]); // eslint-disable-line

  // Load in app config data
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API}/api/config`).then(({ data }) => {
      let landing = data.config.find(({ type }) => type === "landing");

      if (landing && landing.options)
        setShowLandingPage(landing.options.show);

      config.dispatch({ type: "load", payload: data.config });
    });
  }, [config.dispatch]); // eslint-disable-line

  return loadingUser ? (
    <LoadingSpinner className="absolute-center" />
  )
  :
  user.state.confirmationRequired ? (
    <MainLayout>
      <Switch>
        <Route path="/p/:slug">
          <DynamicPage />
        </Route>
        <Route path="*">
          <CompleteRegistration />
        </Route>
      </Switch>
    </MainLayout>
  ) : (
    <Switch>
      {user.state.admin && (
        <AdminRoute path="/admin">
          <AdminRouter />
        </AdminRoute>
      )}
      <Route path="/auth">
        <AuthRouter landing={showLandingPage} />
      </Route>
      <Route path="/">
        <MainRouter landing={showLandingPage} />
      </Route>
    </Switch>
  );
};

export default AppRouter;
