import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import LoadingSpinner from "../../includes/LoadingSpinner";
import PageNotFound from "../error/PageNotFound";
import OtherRaffles from "./includes/OtherRaffles";
import RaffleInfo from "./includes/RaffleInfo";
import TicketSelector from "./includes/TicketSelector";
import { Helmet } from "react-helmet";
import RaffleInstantWins from "./includes/RaffleInstantWins";

const RafflePage = () => {
  const { slug } = useParams();

  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(false);
  const [excludeRaffles, setExcludeRaffles] = useState([]);
  const [raffle, setRaffle] = useState({});
  const [timeRemaining, setTimeRemaining] = useState(0);

  useEffect(() => {
    setLoading(true);

    Axios.get(`${process.env.REACT_APP_API}/api/raffles/${slug}/live`)
      .then(({ data }) => {
        setExcludeRaffles([data.raffle._id]);
        setRaffle(data.raffle);
        setLoading(false);
      })
      .catch(() => {
        setErr(true);
        setLoading(false);
      });
  }, [slug]);

  // Keep a track of current time for countdown timers
  useEffect(() => {
    if (!loading && !err) {
      setTimeRemaining(
        moment.duration((moment(raffle.endDate).unix() - moment().unix()) * 1000, "milliseconds")
          ._milliseconds
      );

      const intervalID = setInterval(() => {
        setTimeRemaining(
          moment.duration((moment(raffle.endDate).unix() - moment().unix()) * 1000, "milliseconds")
            ._milliseconds
        );
      }, 1000);

      return () => clearInterval(intervalID);
    }
  }, [loading, err, raffle.endDate]);

  return err ? (
    <PageNotFound />
  ) : (
    <div>
      {loading ? (
        <div className="row">
          <div className="col center">
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <>
          {raffle && (
            <Helmet>
              <title>Pryze | Win a {raffle.name}</title>
            </Helmet>
          )}

          <RaffleInfo timeRemaining={timeRemaining} raffle={raffle} />
          
          {timeRemaining > 0 && <TicketSelector raffle={raffle} />}

          <RaffleInstantWins raffle={raffle} />

          <OtherRaffles exclude={excludeRaffles} />
        </>
      )}
    </div>
  );
};

export default RafflePage;
